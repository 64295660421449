import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SystemadminService } from '../services/systemadmin.service';
import { DynamicInstance, MyLicense } from '../model/systemadmin';
import { GeneralMaster } from '../model/common';
import { MastersService } from '../services/masters.service';
declare let $: any;
import Swal from 'sweetalert2';
/*import { data } from 'jquery';*/

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
    panelOpenState = false;
    
    constructor(private router: Router, private route: ActivatedRoute, private service: MastersService, private sa: SystemadminService, private fb: FormBuilder,private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer) {
        this.matIconRegistry.addSvgIcon(
            "freight",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/freight.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "profit",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/profit.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "user",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/administration.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "database",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/database.svg")
        );
    }
    salink: string;
    fflink: string;
    lalink: string;
    fnlink: string;
    SideNavForm: FormGroup;
    activeid: string;
    ngOnInit(): void {
        this.createForm();
       
        //var btnContainer = document.getElementById("sidebar-main");

     
        //var btns = btnContainer.getElementsByClassName("dropbtn1");

     
        //for (var i = 0; i < btns.length; i++) {
        //    btns[i].addEventListener("click", function () {
        //        var current = document.getElementsByClassName("active");

              
        //        if (current.length > 0) {
        //            current[0].className = current[0].className.replace(" active", "");
        //        }

              
        //        this.className += " active";
        //    });
        //}
        this.BindInstanceDtls();
    }
    modal() {
        $('#UserProfile').modal('show');
    }

    createForm() {

        this.SideNavForm = this.fb.group({
          
        });


    }

    BindInstanceDtls() {
        this.sa.GetInstanceLinks(this.SideNavForm.value).subscribe(data => {
            this.SideNavForm.patchValue(data[0]);

            for (var i = 0; i < data.length; i++) {
                if (data[i].TypeID == 531) {
                    this.salink = data[i].Url;
                }
                if (data[i].TypeID == 532) {
                    this.fflink = data[i].Url;
                }
                if (data[i].TypeID == 533) {
                    this.lalink = data[i].Url;
                }
                if (data[i].TypeID == 534) {
                    this.fnlink = data[i].Url;
                }
               
                   /* this.activeid = data[i].ActiveID;*/
                    /*alert(this.activeid);*/
               
            }
        });
    }

    OnClickLink(val) {

        if (val == 1) {

            if (this.lalink == "odaklaqa.odaksolutions.in") {
                /*window.location.href = "https://" + this.lalink + "/login";*/
                window.open("https://" + this.lalink + "/login", '_blank');
            }
            else {
                Swal.fire("Please Contact Your Administrator");
            }
            //if (this.activeid == "2") {
            //    Swal.fire("Please Contact Your Administrator");
            //}
            
        }
        if (val == 2) {
            if (this.fflink == "odakffqa.odaksolutions.in") {
                /*window.location.href = "https://" + this.fflink + "/ui";*/
                window.open("https://" + this.fflink + "/ui", '_blank');
            }
            else {
                Swal.fire("Please Contact Your Administrator");
            }
        }
        if (val == 3) {
            if (this.fnlink == "odakfnqa.odaksolutions.in") {
                /*window.location.href = "https://" + this.fnlink + "/login";*/
                window.open("https://" + this.fnlink + "/login", '_blank');
            }
            else {
                Swal.fire("Please Contact Your Administrator");
            }
        }
        if (val == 4) {
            if (this.salink == "odaksaqa.odaksolutions.in") {
                /*window.location.href = "https://" + this.salink + "/login";*/
                window.open("https://" + this.salink + "/login", '_blank');
            }
            else {
                Swal.fire("Please Contact Your Administrator");
            }
        }
        
    }
}
