import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../services/encr-decr-service.service';
import Swal from 'sweetalert2';
import { SystemadminService } from '../../../services/systemadmin.service';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute, private ES: EncrDecrServiceService, private sa: SystemadminService, private fb: FormBuilder) { }
    ConfigForm: FormGroup;
    CompanyIDv = 0;

    ngOnInit(): void {
        var queryString = new Array();

        this.route.queryParams.subscribe(params => {
            var Parameter = this.ES.get(localStorage.getItem("EncKey"), params['encrypted']);
            var KeyPara = Parameter.split(',');
            for (var i = 0; i < KeyPara.length; i++) {
                var key = KeyPara[i].split(':')[0];
                var value = KeyPara[i].split(':')[1];
                queryString[key] = value;
            }
            if (queryString["ID"] != null) {
                this.CompanyIDv = queryString["ID"];

            }
        });
  }

    btntabclick(tab) {


        var values = "ID: " + this.CompanyIDv;
        //var values = "ID: 8";
        var encrypted = this.ES.set(localStorage.getItem("EncKey"), values);
        if (tab == 1) {

            this.router.navigate(['/views/masters/instanceprofile/companydetails/companydetails'], { queryParams: { encrypted } });
        }
        else if (tab == 2) {

            this.router.navigate(['/views/masters/instanceprofile/clientmanagement/clientmanagement'], { queryParams: { encrypted } });

        }
        else if (tab == 3) {

            this.router.navigate(['/views/masters/instanceprofile/configuration/configuration'], { queryParams: { encrypted } });

        }
        else if (tab == 4) {

            this.router.navigate(['/views/instanceprofile/security/security'], { queryParams: { encrypted } });

        }

    }

}
