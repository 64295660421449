import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../../services/encr-decr-service.service';
import { useraccess, Division, DivisionLable, DivisionTypes, Customer } from '../../../../model/useraccessdata';
import { UseraccessService } from '../../../../services/useraccess.service';
import { DynamicNotificationEmail, MyOffice } from '../../../../model/systemadmin';
import { SystemadminService } from '../../../../services/systemadmin.service';
import { PaginationService } from 'src/app/pagination.service';
declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';


@Component({
  selector: 'app-userview',
  templateUrl: './userview.component.html',
  styleUrls: ['./userview.component.css']
})
export class UserviewComponent implements OnInit {
    UserValues = [];
    ProfileForm: FormGroup;
    UserTypeValues = [];
    OfficeValues: MyOffice[];
    ActiveValues: UserActiveType[] = [
        { value: '1', viewValue: 'YES' },
        { value: '2', viewValue: 'NO' },
    ];

    EmpValues: EmpActiveType[] = [
        { value: '1', viewValue: 'YES' },
        { value: '2', viewValue: 'NO' },
    ];

    LockValues: LockedType[] = [
        { value: '2', viewValue: 'YES' },
        { value: '1', viewValue: 'NO' },
    ];
    getValue: any = "";
    private allItems: any[];

    // pager object
    pager: any = {};

    // paged items
    pagedItems: any[];
    sampleData = [];
    public Locked: string = "YES";
    public UnLocked: string = "NO";
    public Active: string = "YES";
    public InActive: string = "NO";
    public UserType133: string = "EMPLOYEE(NO MASTER)";
    public UserType134: string = "VENDOR";
    FillDivisionMaster: DivisionLable[] = [];
    DynamicUserPerObject = [];
    public chkaction: string = "Yes";
    public unchkaction: string = "No";
    public disableaction: string = "Disabled";
    integerValue: number = 0;
    disablegenerated = false;
    createdDate = "";
    useridd = 0;
    constructor(private router: Router, private ES: EncrDecrServiceService, private route: ActivatedRoute, public ps: PaginationService, private sa: SystemadminService, private us: UseraccessService, private fb: FormBuilder) { }

    ngOnInit(): void {
        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id

            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        });
        
        this.createForm();
        this.clearSearch();
        this.BindProfileList();
        this.BindDropDown();
        $('#ddlUserTypeU').val(0).trigger("change");
        $('#ddlDivisionU').val(0).trigger("change");
        $('#ddlOfficeU').val(0).trigger("change");
        $('#ddlActiveU').val(0).trigger("change");
        $('#ddlEmpActiveU').val(0).trigger("change");
        $('#ddlLockedU').val(0).trigger("change");
    }

    createForm() {
        this.ProfileForm = this.fb.group({
            User_Idv:'',
            UserName: '',
            UserType: 0,
            Division_Id: 0,
            Office_Id: 0,
            User_Active: 0,
            Locked:0

        });
    }

    BindDropDown() {
        this.us.GetUserType().subscribe(res => {

            this.UserTypeValues = res.data.UserType;
           

        });


        this.us.GetOffice().subscribe(res => {
            this.OfficeValues = res.data.Office;
        });
        let payloaddivision = {

            "Id": 0

        }
        this.us.GetDivisionDropDown(payloaddivision).subscribe(res => {
            this.FillDivisionMaster = res.data.Division;
            console.log("UserRoles", this.FillDivisionMaster);
        });


    }
    //BindProfileList() {
    //    var division = "";
    //    var office = "";
    //    var useractive = "";
    //    var empactive = "";
    //    var usertype = 0;
    //    var locked = 0;
    //    if ($('#ddlUserType').val() != 0) {
    //        if ($('#ddlUserType').val() == 0) {
    //            usertype = 0;
    //        }
    //        else {
    //            usertype = $('#ddlUserType').val();
    //        }

    //    }
    //    else {
    //        usertype = 0;
    //    }
    //    if ($('#ddlDivision').val() != 0) {
    //        if ($('#ddlDivision').val() == 0) {
    //            division = "";
    //        }
    //        else {
    //            division = $('#ddlDivision').val();
    //        }

    //    }
    //    else {
    //        division = "";
    //    }
    //    if ($('#ddlOffice').val() != 0) {
    //        if ($('#ddlOffice').val() == 0) {
    //            office = "";
    //        }
    //        else {
    //            office = $('#ddlOffice').val();
    //        }

    //    }
    //    else {
    //        office = "";
    //    }
    //    if ($('#ddlActive').val() != 0) {
    //        if ($('#ddlActive').val() == 0 || $('#ddlActive').val() == 2) {
    //            useractive = "";
    //        }
    //        else {
    //            useractive = $('#ddlActive').val();
    //        }

    //    }
    //    else {
    //        useractive = "";
    //    }
    //    if ($('#ddlLocked').val() != 0) {
    //        if ($('#ddlLocked').val() == 0) {
    //            locked = 1;
    //        }
    //        else {
    //            locked = $('#ddlLocked').val();
    //        }



    //    }
    //    else {
    //        locked = 0;
    //    }

    //    let payload = {
    //        "user_Id": $('#txtUserID').val(),
    //        "userName": $('#txtUserName').val(),
    //        "division_Id": division,
    //        "office_Id": office,
    //        "user_Active": useractive,
    //        "emp_Actvie_Status": empactive,
    //        "userType": usertype,
    //        "is_Active": 1
    //    }
    //    var Is_ActiveV = "";
    //    console.log("payload", payload);
    //    this.us.GetProfileList(payload).subscribe(res => {

    //        this.UserValues = res.data.TrasnportType_list;

    //    });


    //}

    BindProfileList() {
        $('#txtUserID').val("");
        this.us.GetEmployeeMasterView(this.ProfileForm.value).subscribe(data => {
            this.allItems = data;
            this.setPage(1);
            });
    }

    onSubmit() {
        this.ProfileForm.value.User_Idv = $('#txtUserID').val();
        this.ProfileForm.value.UserName = $('#txtUserName').val();
        this.ProfileForm.value.UserType = $('#ddlUserTypeU').val();
        this.ProfileForm.value.Divison_Id = $('#ddlDivisionU').val();
        this.ProfileForm.value.Office_Id = $('#ddlOfficeU').val()
        this.ProfileForm.value.User_Active = $('#ddlActiveU').val()
        this.ProfileForm.value.Locked = $('#ddlLockedU').val();
      
        this.BindProfileList();
    }
    clearSearch() {
        this.createForm();
        $('#txtUserID').val('')
        $('#txtUserName').val('');
        $('#ddlUserTypeU').val(0).trigger("change");
        $('#ddlDivisionU').val(0).trigger("change");
        $('#ddlOfficeU').val(0).trigger("change");
        $('#ddlActiveU').val(0).trigger("change");
        $('#ddlEmpActiveU').val(0).trigger("change");
        $('#ddlLockedU').val(0).trigger("change");
        this.BindProfileList();
    }

    //OnClickEdit(dataItem: any) {
    //    let value = this.ES.set(localStorage.getItem("EncKey"), dataItem);
    //    alert(value);
    //    //var encrypted = this.ES.set(localStorage.getItem("EncKey"), value);
    //    this.router.navigate(['/views/systemadmin/user/profile/profile'], { queryParams: { value } });
    //    /*localStorage.setItem("screen", JSON.stringify(false));*/
    //}

    OnSelectRow(dataItem: any) {
        console.log(dataItem);
        let value = encodeQueryParam(dataItem);
        this.router.navigate(['/views/systemadmin/user/profile/profile'], { queryParams: { value } });
        localStorage.setItem("screen", JSON.stringify(false));
    }

    //OnSelectRow(UserIDV, TypeV, UserNameV) {
       

    //    var values = "UserID: " + UserIDV + "&UserType:" + TypeV + "&UserName:" + UserNameV;
    //    /*var values = "UserID: " + IDv + "&UserType:" + TypeV;*/
    //    var encrypted = this.ES.set(localStorage.getItem("EncKey"), values);
    //    this.router.navigate(['/views/systemadmin/user/profile/profile'], { queryParams: { encrypted } });
      
    //}

    OnSelctObject(userid) {
        this.useridd = userid;
        this.router.navigate(['/views/systemadmin/userroles/userview/userview']);
     
        let payload3 = {
            "isdata": "G",
            "ref_RoleId": "",
            "UserId": userid.toString(),
            "Created_by": 0
        }
        console.log("GeneratePayload", payload3);
        this.us.GenerateUserPermissionObject(payload3).subscribe(res => {
            let payload = {

                "userId": userid.toString(),

            }
            console.log("GeneratePayload", payload);
            this.us.GenerateUpdateUserPermissionObject(payload).subscribe(res => {
                //let numberValue = Number(userid);

               // this.integerValue = parseInt(userid, 10);
                let payload2 = {

                    "userId": userid

                }
                this.us.GenerateUserPermissionObjectViewAll(payload2).subscribe(res => {

                    if (res.statuscode == 200) {
                        $('#PrintModal').modal('show');
                        this.DynamicUserPerObject = res.data.MainMenuList;
                        console.log("CreateDate", res.data.MainMenuList[0].Created_date_time);
                        this.createdDate = res.data.MainMenuList[0].Created_date_time;
                        console.log("CreatedDateValue", this.createdDate);
                    }
                    if (res.statuscode == 404) {
                        Swal.fire("Record Not Found");

                    }
                },

                    (error: HttpErrorResponse) => {
                        Swal.fire(error.message)
                    });
            },

                (error: HttpErrorResponse) => {
                    Swal.fire(error.message)
                });
            console.log("permissionobject", res.data);
        },

            (error: HttpErrorResponse) => {
                Swal.fire(error.message)
            });
        console.log(payload3);
       
     

       
      
    }
    DownloadReport() {
       
        var sealServer = "";
        //sealServer = "https://localhost:44398/seal/";
        sealServer = "https://sealreport.odaksolutions.in/";
        var connected = true;
        var name = "";
        $("#execute_label").text("Executing: 'Permission Object'");
        var form1 = $('<form method="post" target="Pdfiframe" />').appendTo('body');
        form1.attr('action', sealServer + "SWExecuteReport");
        //form1.append($('<input / > ').attr('type', 'hidden').attr('name', 'path').attr('value', "\\" + this.designName));
        form1.append($('<input />').attr('type', 'hidden').attr('name', 'path').attr('value', "\\PermissionObject.srex"));

      
            form1.append($('<input />').attr('name', 'r0_name').attr('value', "UserId"));
        form1.append($('<input />').attr('name', 'r0_operator').attr('value', "Equals"));
        form1.append($('<input />').attr('name', 'r0_value_1').attr('value', this.useridd));
      
        form1.submit();
    }
    public selectedName: any;
    public highlightRow(dataItem) {
        this.selectedName = dataItem.CountryCode;
    }
    setPage(page: number) {
        //if (page < 1 || page > this.pager.totalPages) {
        //    return;
        //}

        // get pager object from service
        this.pager = this.ps.getPager(this.allItems.length, page);

        // get current page of items
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
    isDesc: boolean = false;
    column: string = '';
    sort(property) {
        this.isDesc = !this.isDesc;
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.pagedItems.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    };

}
interface UserActiveType {
    value: string;
    viewValue: string;
}
interface EmpActiveType {
    value: string;
    viewValue: string;
}
interface LockedType {
    value: string;
    viewValue: string;
}
export function encodeQueryParam(value: any): string {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(value));
    let binary = '';
    const len = data.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(data[i]);
        
    }
    console.log(btoa(binary));
    return btoa(binary);
    
}
export const ERROR_CODE = {
    RECROD_NOT_FOUND: {
        Text: "Record Not Found",
        Value: 404
    },
    SUCCESS: {
        Text: "Success",
        Value: 200
    }
}
