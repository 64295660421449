import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../services/encr-decr-service.service';
import { useraccess } from '../../model/useraccessdata';
import { UseraccessService } from '../../services/useraccess.service';
import { DynamicNotificationEmail, MyOffice } from '../../model/systemadmin';
import { SystemadminService } from '../../services/systemadmin.service';
import { PaginationService } from '../../pagination.service';
declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';
import { isDataSource } from '@angular/cdk/collections';
import { Key } from 'protractor';
import { NumberGeneratorService } from '../../services/number-generator.service';
import { ReportpermissionService } from '../../services/reportpermission.service';

@Component({
  selector: 'app-reportpermission',
  templateUrl: './reportpermission.component.html',
  styleUrls: ['./reportpermission.component.css']
})
export class ReportpermissionComponent implements OnInit {
    PermissionForm: FormGroup;
    getValue: any = "";
    public disablePermission: boolean = true;
    
    constructor(private router: Router, private ES: EncrDecrServiceService, public ps: PaginationService, private route: ActivatedRoute, private sa: SystemadminService, private rp: ReportpermissionService, private fb: FormBuilder, private numberGeneratorService: NumberGeneratorService) {

        this.route.queryParams.subscribe(param => {
            if (param['value']) {
                this.getValue = decodeQueryParam(param['value']);
                console.log("getvalue", this.getValue);

               
            }
        })

    }
    MenuTypeValues: MenuTypeValues[] = [
        { value: '9', viewValue: 'REPORTS' },
        { value: '10', viewValue: 'DASHBOARDS' },
    ];
    LandingAppValues: LandingAppValues[] = [
        { value: '1', viewValue: 'MANAGEMENT DASHBOARD' },
        { value: '2', viewValue: 'LINER AGENCY' },
        { value: '3', viewValue: 'FREIGHT FORWARDING' },
        { value: '4', viewValue: 'FINANCE' },
        { value: '5', viewValue: 'STYSTEM ADMIN' },
    ];
    ngOnInit(): void {
        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id

            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        });
        this.createForm();
        this.BindExistingRepPermission(this.getValue.SubFuncID);
       
        if (this.getValue.PermissionID > "1000") {
           
            this.disablePermission = true;
        }
        else {
            this.disablePermission = false;
        }
        
    }
    createForm() {

        this.PermissionForm = this.fb.group({
            SubMenuID: 0,
            FuncID: 0,
            SubFuncID:0,
            MenuID: 0,
            SubmenuName: '',
            FunctionName: '',
            SubFunction: '',
            PermissionID: '',
            LandingAppID: 0
        });

    }
    BindExistingRepPermission(subfunctionid) {
       
        this.PermissionForm.value.SubFuncID = subfunctionid;
        this.rp.GetReportPermssionExistingView(this.PermissionForm.value).subscribe(data => {
            this.PermissionForm.patchValue(data[0]);
          
    /*        this.PermissionForm.value.MenuID = data[0].MenuID;*/
            this.PermissionForm.value.SubMenuID = data[0].SubMenuID;
            this.PermissionForm.value.FuncID = data[0].FuncID;
            this.PermissionForm.value.SubFuncID = data[0].SubFuncID;
            //this.PermissionForm.get('LandingAppID').patchValue(data[0].LandingAppID);
            //this.PermissionForm.get('SubmenuName').patchValue(data[0].SubMenuName);
            //this.PermissionForm.get('FunctionName').patchValue(data[0].FunctionName);
            //this.PermissionForm.get('SubFunction').patchValue(data[0].SubFunctionName);
            //this.PermissionForm.get('PermissionID').patchValue(data[0].PermissionID);
            $("#txtSubmenu").val(data[0].SubMenuName);
            $("#txtFunction").val(data[0].FunctionName);
            $("#txtSubFunction").val(data[0].SubFunctionName);
            $("#ddlLandingApp").val(data[0].LandingAppID).trigger("change");
            $("#ddlMenu").val(data[0].MenuID).trigger("change");
            $("#txtPermissionID").val(data[0].PermissionID);
        });
    }
    OnSubmit() {
      
        var validation = "";

        if ($('#ddlMenu').val() == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Menu</span></br>"
        }
        if ($('#txtSubmenu').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Sub Menu</span></br>"
        }
        if ($('#txtFunction').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Function</span></br>"
        }
        if ($('#txtSubFunction').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Sub Function</span></br>"
        }
        //if ($('#txtPermissionID').val() == "") {
        //    validation += "<span style='color:red;'>*</span> <span>Please Enter Permission ID</span></br>"
        //}
        if (validation != "") {
            Swal.fire(validation);
            return false;
        }
        
        this.PermissionForm.value.MenuID = $('#ddlMenu').val();
        this.PermissionForm.value.SubMenuName = $('#txtSubmenu').val();
        this.PermissionForm.value.FunctionName = $('#txtFunction').val();
        this.PermissionForm.value.SubFunctionName = $('#txtSubFunction').val();
        this.PermissionForm.value.LandingAppID = $('#ddlLandingApp').val();
        this.PermissionForm.value.PermissionID = $('#txtPermissionID').val();
        //if ($('#txtPermissionID').val() == "500") { 
        //    this.PermissionForm.value.PermissionID = $('#txtPermissionID').val();
        //}

        this.rp.SaveReportPermission(this.PermissionForm.value).subscribe(data => {
            Swal.fire('Record Saved Successfully');
            setTimeout(() => {
                this.router.navigate(['/views/reportpermission/reportpermissionview/reportpermissionview']);
            }, 2000);  
        });


       
    }
}
interface MenuTypeValues {
    value: string;
    viewValue: string;
}
interface LandingAppValues {
    value: string;
    viewValue: string;
}

export function decodeQueryParam(encodedValue: string): any {
    const decodedBytes = Uint8Array.from(atob(encodedValue), c => c.charCodeAt(0));
    const decoder = new TextDecoder('utf-8');
    const decodedString = decoder.decode(decodedBytes);
    /*console.log("decode", decodedString);*/
    return JSON.parse(decodedString);
}
export function encodeQueryParam(value: any): string {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(value));
    let binary = '';
    const len = data.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(data[i]);

    }
    console.log(btoa(binary));
    return btoa(binary);

}