import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../../services/encr-decr-service.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationBasic } from '../../../../model/Notification';
import { SystemadminService } from '../../../../services/systemadmin.service';
import { GeneralMaster } from '../../../../model/common';
import { DynamicNotificationEmail, MyOffice } from '../../../../model/systemadmin';


declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';

@Component({
  selector: 'app-notifymail',
  templateUrl: './notifymail.component.html',
  styleUrls: ['./notifymail.component.css']
})
export class NotifymailComponent implements OnInit {
    RecValues: RecType[] = [
        { value: '1', viewValue: 'TO' },
        { value: '2', viewValue: 'CC' },
        { value: '3', viewValue: 'BCC' },
    ];
    ActiveValues: ActionType[] = [
        { value: '1', viewValue: 'Include' },
        { value: '2', viewValue: 'Exclude' },
    ];
    BasicID = 0;
    NotifyForm: FormGroup;
    OfficeValues: MyOffice[];
    DynamicNumberRange: Array<DynamicNotificationEmail > = [];
    val: any = {};
    NID = null;
    HDArrayIndex = '';
    //UpdateID = 0;
    EdiNID = 0;
    chkindex = "0";
    RangeID = 1;
    ValidID = 0;
    updateID = 0;
    EditID = 0;
    edID = 0;
    constructor(private router: Router, private route: ActivatedRoute, private sa: SystemadminService, private ns: NotificationService, private ES: EncrDecrServiceService, private fb: FormBuilder) { }

    ngOnInit(): void {
        $('.my-select').select2();
        this.createForm();

        var queryString = new Array();
        this.route.queryParams.subscribe(params => {
            var Parameter = this.ES.get(localStorage.getItem("EncKey"), params['encrypted']);
            var KeyPara = Parameter.split(',');
            for (var i = 0; i < KeyPara.length; i++) {
                var key = KeyPara[i].split(':')[0];
                var value = KeyPara[i].split(':')[1];
                queryString[key] = value;
            }

            if (queryString["ID"] != null) {

                this.BasicID = queryString["ID"].toString();

            }
            if (this.BasicID == 0) {
                Swal.fire({
                    title: 'Fill the Basic Details.',
                    showDenyButton: false,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfig']);
                    } else {

                    }
                });
                return false;
            }

            this.BindDropDown();
            this.BindExistingRecords(this.BasicID);
        });

       
    }
    createForm() {
        this.NotifyForm = this.fb.group({
            OfficeID: 0,
            EmailID: '',
            RecTypeID: 0,
            ActionID:0
        });
    }
    BindDropDown() {
        this.sa.GetOffice(this.NotifyForm.value).subscribe(data => {
            this.OfficeValues = data;
        });
    }

    AddRecord() {
        var validation = "";

        var ddlOffice = $('#ddlOffice').val();
        if (ddlOffice == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Office</span></br>"
        }
        var txtEmailID = $('#txtEmailID').val().toLowerCase();
        if (txtEmailID == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Email ID</span></br>"
        }
        else if (txtEmailID != "") {
            this.isValidEmail(txtEmailID) ? validation == "" : validation += "<span style='color:red;'>*</span> <span>Please Enter a Valid Email</span></br>";
        }
        //if ($('#txtEmailID').val() == "") {
        //    validation += "<span style='color:red;'>*</span> <span>Please Enter EmailId</span></br>"
        //}
        var ddlRecType = $('#ddlRecType').val();
        if (ddlRecType == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select RecType</span></br>"
        }
        var ddlAction = $('#ddlAction').val();
        if (ddlAction == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Action</span></br>"
        }
       
       

        
        for (var i = 0; i < this.DynamicNumberRange.length; i++) {

            if (this.DynamicNumberRange[i].NID != this.NID) { 
            if (this.DynamicNumberRange[i].OfficeID == $('#ddlOffice').val()
                && (this.NID == null || this.NID != null)) {
                validation += "<span style='color:red;'>*</span> <span>Office Already Exists</span></br>"
            }
            }
        }

       

        if (validation != "") {
            this.edID = 1;
            Swal.fire(validation);
            return false;
        }
        var NIDValue;

        this.updateID = 1;
        this.edID = 0;

        NIDValue = (this.NID == null) ? 0 : this.NID;
        this.val = {
            NID: NIDValue,
            OfficeID: $("#ddlOffice").val(),
            Office: $("#ddlOffice option:selected").text(),
            EmailID: $("#txtEmailID").val(),
            RecTypeID: $("#ddlRecType").val(),
            RecType: $("#ddlRecType option:selected").text(),
            ActionID: $("#ddlAction").val(),
            Action: $("#ddlAction option:selected").text(),

        };

        if (this.HDArrayIndex != null && this.HDArrayIndex.length != 0) {
            this.DynamicNumberRange[this.HDArrayIndex] = this.val;
        } else {
            this.DynamicNumberRange.push(this.val);
        }

        $('#ddlOffice').val(0).trigger("change");
        $("#txtEmailID").val("").trigger("change");
        $('#ddlRecType').val(0).trigger("change");
        $('#ddlAction').val(0).trigger("change");

        this.NID = 0;
        this.HDArrayIndex = '';
    } 
    public isValidEmail(emailString): boolean {

        try {
            let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
            let valid = pattern.test(emailString.trim());
            return valid;
        } catch (TypeError) {
            return false;
        }

    }
    OnClickRadio(index, NID) {

        this.EdiNID = NID;
        this.chkindex = index;
    }
    EditRecord() {
        var checked = $("#datatable input[type=radio]:checked").length;
        if (this.DynamicNumberRange.length != 0) {
            if (checked == 0) {
                Swal.fire("Please Select Any one");
            }
            else {

                var NIDV = 0;
                NIDV = this.EdiNID;

                this.DynamicNumberRange[this.chkindex].NID = NIDV;
                this.HDArrayIndex = this.chkindex;
                this.NID = this.DynamicNumberRange[this.chkindex].NID;
                this.EdiNID = this.DynamicNumberRange[this.chkindex].NID;


                $("#ddlOffice").select2().val(this.DynamicNumberRange[this.chkindex].OfficeID).trigger("change");
                $("#txtEmailID").val(this.DynamicNumberRange[this.chkindex].EmailID);
                $("#ddlRecType").val(this.DynamicNumberRange[this.chkindex].RecTypeID).trigger("change");
                $("#ddlAction").val(this.DynamicNumberRange[this.chkindex].ActionID).trigger("change");
              
            }
        }
        else {
            Swal.fire("No Records Found");
        }
    }


    DeleteRecord(chkind) {

        chkind = this.chkindex;
        var checked = $("#datatable input[type=radio]:checked").length;

        if (checked == 0) {
            Swal.fire("Please check atleast one ");
        }
        else {

            if (this.ValidID == 1) {
                Swal.fire({
                    title: 'Records Entered will not be Saved, Are you sure want to Delete ?',
                    showDenyButton: true,
                    confirmButtonText: 'YES',
                    cancelButtonText: 'NO',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.updateID = 0;

                        this.NotifyForm.value.NID = this.EdiNID;
                        /*this.ClientForm.value.TerminalID = this.TerminalID;*/

                        if (this.NotifyForm.value.NID != "") {
                            this.ns.DeleteNotificationEmailDtls(this.NotifyForm.value).subscribe(Data => {
                                //this.ExistingvaluesBind(this.TerminalID);
                                //Swal.fire("Deleted Successfully");

                                Swal.fire({
                                    title: 'Deleted Successfully',
                                    showDenyButton: false,
                                    confirmButtonText: 'OK',
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                        $('#ddlOffice').val(0).trigger("change");
                                        $("#txtEmailID").val("").trigger("change");
                                        $('#ddlRecType').val(0).trigger("change");
                                        $('#ddlAction').val(0).trigger("change");

                                        this.BindExistingRecords(this.BasicID);
                                    } else {

                                    }
                                });
                            },
                                (error: HttpErrorResponse) => {
                                    Swal.fire(error.message)
                                });
                        }
                        else {
                            this.DynamicNumberRange.splice(chkind, 1);
                        }

                        document.querySelectorAll('.checkbox').forEach(_radio => {
                            (<HTMLInputElement>_radio).checked = false;
                        });
                    }
                    else {

                    }
                });
            }
            else {

                Swal.fire({
                    title: 'Are you sure want to Delete ?',
                    showDenyButton: true,
                    confirmButtonText: 'YES',
                    cancelButtonText: 'NO',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.updateID = 0;

                        this.NotifyForm.value.NID = this.EdiNID;
                        /*this.ClientForm.value.TerminalID = this.TerminalID;*/

                        if (this.NotifyForm.value.NID != "") {
                            this.ns.DeleteNotificationEmailDtls(this.NotifyForm.value).subscribe(Data => {
                                //this.ExistingvaluesBind(this.TerminalID);
                                //Swal.fire("Deleted Successfully");

                                Swal.fire({
                                    title: 'Deleted Successfully',
                                    showDenyButton: false,
                                    confirmButtonText: 'OK',
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                        $('#ddlOffice').val(0).trigger("change");
                                        $("#txtEmailID").val("").trigger("change");
                                        $('#ddlRecType').val(0).trigger("change");
                                        $('#ddlAction').val(0).trigger("change");

                                        this.BindExistingRecords(this.BasicID);
                                    } else {

                                    }
                                });
                            },
                                (error: HttpErrorResponse) => {
                                    Swal.fire(error.message)
                                });
                        }
                        else {
                            this.DynamicNumberRange.splice(chkind, 1);
                        }

                        document.querySelectorAll('.checkbox').forEach(_radio => {
                            (<HTMLInputElement>_radio).checked = false;
                        });

                    } else {

                    }
                });

            }

        }
    }


    OnSubmit() {
      

        if (this.updateID == 0) {
            this.AddRecord();
        }
       
        if (this.edID == 0) {
            var ItemsDr = [];
            for (let item of this.DynamicNumberRange) {
                var intNID = 0;

                if (item.OfficeID != 0) {
                    if (typeof item.NID == "undefined")
                        intNID = 0;
                    else
                        intNID = item.NID;

                    ItemsDr.push('Insert:' + intNID, item.OfficeID, item.Office, item.EmailID, item.RecTypeID, item.RecType, item.ActionID, item.Action);
                }

            };
            this.NotifyForm.value.ItemsV = ItemsDr.toString();


            this.NotifyForm.value.BasicID = this.BasicID;
           
            this.ns.saveNotificationEmail(this.NotifyForm.value).subscribe(Data => {

                $('#ddlOffice').val(0).trigger("change");
                $("#txtEmailID").val("").trigger("change");
                $('#ddlRecType').val(0).trigger("change");
                $('#ddlAction').val(0).trigger("change");

                Swal.fire("Record Saved SuccessFully");


                this.BindExistingRecords(this.BasicID);

                this.ValidID = 0;
                this.updateID = 0;
                this.edID = 0;
            },

                (error: HttpErrorResponse) => {
                    Swal.fire(error.message)
                });
        }
    }

    BindExistingRecords(idv) {

        this.NotifyForm.value.BasicID = idv;
        this.ns.GetNotificationEmailDtls(this.NotifyForm.value).subscribe(data1 => {
            this.DynamicNumberRange.length = 0;
            if (data1.length > 0) {
                for (let item of data1) {

                    this.DynamicNumberRange.push({

                        'NID': item.NID,
                        'OfficeID': item.OfficeID,
                        'Office': item.Office,
                        'EmailID': item.EmailID,
                        'RecTypeID': item.RecTypeID,
                        'RecType': item.RecType,
                        'ActionID': item.ActionID,
                        'Action': item.Action
                      
                    });
                }
            }
            else {

            }
        });


    }

    btntabclick(tab) {

        var values = "ID: " + this.BasicID;

        var encrypted = this.ES.set(localStorage.getItem("EncKey"), values);


        if (tab == 1) {
            this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfig'], { queryParams: { encrypted } });
        }
        else if (tab == 2) {
            this.router.navigate(['/views/systemadmin/notificationconfig/notifymail/notifymail'], { queryParams: { encrypted } });
        }
        else if (tab == 3) {
            this.router.navigate(['/views/systemadmin/notificationconfig/notifysystem/notifysystem'], { queryParams: { encrypted } });
        }

    }

}
interface RecType {
    value: string;
    viewValue: string;
}
interface ActionType {
    value: string;
    viewValue: string;
}