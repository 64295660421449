import { Component, Inject, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { MastersService } from "src/app/services/masters.service";
import Swal from "sweetalert2";
import { WorkflowService } from "src/app/services/workflow.service";
import { PaginationService } from "src/app/pagination.service";
import { EncrDecrServiceService } from "src/app/services/encr-decr-service.service";
import { MyCompany, MySACommon } from "src/app/model/systemadmin";
import { Country, DynamicPDF1, MyAgency } from "src/app/model/common";
import { Globals } from "src/app/globals";
import { Title } from "@angular/platform-browser";
import { CustomerContract } from "src/app/model/CustomerContract";
// import { MyCompany } from '../../model/systemadmin';
// import { PaginationService } from '../../pagination.service';
// import { SystemadminService } from '../../services/systemadmin.service';
// import { EncrDecrServiceService } from '../../services/encr-decr-service.service';
import { DomSanitizer } from "@angular/platform-browser";
declare let $: any;

@Component({
  selector: "app-basicdetails",
  templateUrl: "./basicdetails.component.html",
  styleUrls: ["./basicdetails.component.css"],
})
export class BasicdetailsComponent implements OnInit {
  myControl = new FormControl("");
  title = "Work flow basicdetail ";
  currentTab: any;
  service: any;
  deleteId: any;
  private id: number;
  eventnumber: any;
  complianceRowId: number;
  pageSection: number;
  i: number = -1;
  containerList: any = [];
  addrulelist: any = [];
  addparameterlist: any = [];
  addapprovallist: any = [];
  tabsForm: FormGroup;
  encryptedParam: any;

  statusvalues: Status[] = [
    { value: "1", viewValue: "Yes" },
    { value: "2", viewValue: "No" },
  ];
  aclFilename: DynamicPDF1[];
  dscountryItem: any = { Data: [] };
  addItem: any = { Data: [] };
  ApplicationItem: any = { Data: [] };
  officeItem: any = { Data: [] };
  datatypeItem: any = { Data: [] };
  mandatoryItem: any = { Data: [] };
  validationItem: any = { Data: [] };
  param1Item: any = { Data: [] };
  operatorItem: any = { Data: [] };
  approvalItem: any = { Data: [] };
  approvaltypeItem: any = { Data: [] };
  validationtypeItem: any = { Data: [] };

  deleteItem: any = { Data: [] };
  parameterItems: any = { Data: [] };
  createdon: any;
  modifiedon: any;
  modifiedby: any;
  createdby: any;
  eventname:string;
  appName:string;
  // editable: boolean = true;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private globals: Globals,
    private ms: MastersService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public ps: PaginationService,
    public wf: WorkflowService,
    private titleService: Title,
    private ES: EncrDecrServiceService
  ) {
    this.basicForm = this.fb.group({
      id: 0,
      eventnumber: "",
      eventname: "",
      active: "Yes",
      application: "",
      module: "",
      description: "",
      createdon: "",
    });
  }

  // array of all items to be paged
  private allItems: any = { Data: [] };

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];
  private allItemsRules: any = { Data: [] };

  // pager object
  pagerRules: any = {};

  // paged items
  pagedItemsRules: any[];
  private allItemsParams: any = { Data: [] };

  // pager object
  pagerParams: any = {};

  // paged items
  pagedItemsParams: any[];

  DepartmentList: any = [];
  dataSource: Country[];
  searchForm: FormGroup;

  basicForm: FormGroup;
  paramsForm: FormGroup;
  ActivateAddEditDepComp: boolean = false;
  dep: any;

  DepartmentIdFilter: string = "";
  DepartmentNameFilter: string = "";
  DepartmentListWithoutFilter: any = [];

  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   let eventNumber = this.ES.get(localStorage.getItem("EncKey"), params.encrypted);
    //   this.encryptedParam = params.encrypted;
    //   console.log("params", params.encrypted, eventNumber);
    //   this.tabsForm = this.fb.group({
    //     ID: eventNumber,
    //   });
    // });


    this.titleService.setTitle(this.title);
    $(".my-select").select2();
    $(document).on("select2:open", (e) => {
      const selectId = e.target.id;

      $(
        ".select2-search__field[aria-controls='select2-" +
          selectId +
          "-results']"
      ).each(function (key, value) {
        value.focus();
      });
    });
    $(document).ready(function () {
      $('[data-toggle="popover"]').popover();
    });
    $("#ddlApplication").on("select2:select", (e, args) => {
      this.basicForm.patchValue({ application: $("#ddlApplication").val() });
    });

    // this.getDefineList();
    // this.clearSearch();
    this.applicationBinding();

    this.route.queryParams.subscribe((params) => {
      if(params.encrypted){

      let eventNumber = this.ES.get(
        localStorage.getItem("EncKey"),
        params.encrypted
      );
      console.log("params", params.encrypted, eventNumber);
      this.eventnumber = JSON.parse(eventNumber);
      this.encryptedParam = params.encrypted;
      this.getEventByNumber(this.eventnumber?.eventnumber);
      this.tabsForm = this.fb.group({
        ID: eventNumber,
      });
      }
      else{
        this.isDisabled=false
      }
     
      // this.eventnumber =eventNumber;
    });
  }
  dynamicContent: string;

  getEventByNumber(eventNumber: any) {
    this.wf.getDefineListByNumber(eventNumber).subscribe((data: any) => {
      // set items to json response
      console.log("getDefineListByNumber", data?.Data[0]);

      if (Array.isArray(data?.Data) && data?.Data.length > 0) {
        this.basicForm.patchValue({
          ...data?.Data[0],
        });
        this.eventname =  data?.Data[0].eventname
        this.appName =  data?.Data[0].application
        $("#ddlApplication").val(data?.Data[0].application).change();
        //popover dynamicdata
        // this.dynamicContent = ` createdby: ${data?.Data[0].createdby}
        // createdon: ${data?.Data[0].createdon}
        // modifiedby: ${data?.Data[0].modifiedby}
        // modifiedon: ${data?.Data[0].modifiedon}`;
        this.createdon = data?.Data[0].createdon;
        this.modifiedon = data?.Data[0].modifiedon;
        this.modifiedby = data?.Data[0].modifiedby;
        this.createdby = data?.Data[0].createdby;
      }
    });
  }
  // openPop(createdon) {

  //   // this.createdon = createdon;
  //   console.log("this.popop",this.createdon);
  //   this.basicForm.patchValue({
  //     createdon:this.createdon
  //   })

  // }

  applicationBinding() {
    console.log("application", this.basicForm.value);
    this.wf.getApplicationList({ type: "application" }).subscribe((data) => {
      this.ApplicationItem = data;
    });
  }

  addeventList() {
    console.log("addeventlist", this.basicForm.value);
    let root = this;
    this.wf
      .addWorkFlowevent(this.basicForm.value)
      .subscribe(async (data: any) => {
        // Swal.fire(data[0].AlertMessage);

        //   (error: HttpErrorResponse) => {
        //       Swal.fire(error.message)
        //   };
        console.log("addWorkFlowevent", data);
        // return
        let encryptedParam = {
          id: data?.Data[0].id ? data?.Data[0].id : this.basicForm?.value?.id,
          eventnumber: data?.Data[0].eventnumber ? data?.Data[0].eventnumber: this.basicForm?.value?.eventnumber,
        };

        this.dscountryItem = data;
        // if (!this.encryptedParam) {
        // let eventDetail: any = await this.wf
        //   .getDefineListByNumber(data?.Data[0]?.eventnumber)
        //   .toPromise();
        // console.log('eventDetail',eventDetail,data?.Data[0])
        this.encryptedParam = this.ES.set(
          localStorage.getItem("EncKey"),
          JSON.stringify(encryptedParam)
        );
        let params: NavigationExtras = {
          queryParams: { encrypted: this.encryptedParam },
        };
        this.router.navigate(["views/difineworkflow/basicdetails"], params);
        // }
        // Swal.fire('Saved successfully');
        Swal.fire({
          title: "",
          text: "Saved successfully",
        })
        this.getEventByNumber(encryptedParam.eventnumber);
        this.isDisabled = true
        // .then(function () {
        //   console.log("root.basicForm", root.basicForm.value);
        //   if (root.basicForm.value.id == 0) window.location.reload();
         
        // });

        // this.eventnumber = root.basicForm?.value?.eventnumber;
      });
  }

  onChange(id, i: number) {
    this.id = id;
    this.i = i;
  }

  getDefineList() {
    this.wf.getDefineList().subscribe((data) => {
      // set items to json response
      this.allItems = data;
      console.log("getDefineList", data);
      // initialize to page 1
      this.setPage(1);
    });
  }
  isDisabled = true;

  toggleSelect() {
    this.isDisabled = !this.isDisabled;
  }

  // readonly=true;
  // editOption(){
  //   this.readonly=false
  // }
  onSubmit(type: any) {
    this.basicForm.value.application = $("#ddlApplication").val();
    // this.wf.getRuleList().subscribe(data => {
    //   // set items to json response
    //   this.allItems = data;
    //   console.log("getRuleList", data);

    //   // initialize to page 1
    //   this.setPage(1);

    // });

    // else if (type == 'rule')
    //   this.addRuleList();
    // else if (type == 'param')
    //   this.editParameterList();
    // this.addeventList();
    var validation = "";
    // if (this.basicForm.value.eventnumber == "") {
    //     validation += "<span style='color:red;'>*</span> <span>Please Enter Event Number</span></br>"
    // }
    if (this.basicForm.value?.eventname?.replace(/\s+/g,'') == "" || !this.basicForm.value.eventname ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Event Name</span></br>";
    }
    if (this.basicForm.value.application == "" || !this.basicForm.value.application ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Aapplication</span></br>";
    }
    if (this.basicForm.value?.module?.replace(/\s+/g,'')  == "" || !this.basicForm.value.module  ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Module</span></br>";
    }
    if (this.basicForm.value?.description?.replace(/\s+/g,'')  == "" || !this.basicForm.value.description ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Description</span></br>";
    }

    if (validation != "") {
      Swal.fire(validation);
      return false;
    }

    if (type == "event") this.addeventList();
  }

  public selectedName: any;
  public highlightRow(dataItem) {
    this.selectedName = dataItem.CountryCode;
  }
  setPage(page: number) {
    //if (page < 1 || page > this.pager.totalPages) {
    //    return;
    //}

    // get pager object from service
    this.pager = this.ps.getPager(this.allItems.length, page);

    // get current page of items
    //this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  pdflink() {
    this.ms.getPDF(this.searchForm.value).subscribe((data) => {
      console.log(data[0].aclFilename);
      window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
    });

    //alert('hi');
    //this.http.get(this.globals.APIURL + '/home/BLPrintPDF/').subscribe(res => {

    //   });

    /*window.open(RouterLink("BLPrintPDF", "BLPrint")');*/

    /*window.open("assets/pdfFiles/test.pdf");*/
  }

  //openpdf() {
  //    $('#pdfview').modal('show');
  //}

  isDesc: boolean = false;
  column: string = "";
  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.pagedItems.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  changeTab(tab) {
    console.log("this.encryptedParam",this.encryptedParam)
    if (!this.encryptedParam) return;
    //var values = "ID: " + this.tabsForm.value.ID;
    // var values = "ID: 8";
    var encrypted = this.encryptedParam; // this.ES.set(localStorage.getItem("EncKey"), values);
    if (tab == 1) {
      this.router.navigate(["/views/difineworkflow/basicdetails"], {
        queryParams: { encrypted },
      });
    } else if (tab == 2) {
      this.router.navigate(["/views/difineworkflow/workflowrules"], {
        queryParams: { encrypted },
      });
    } else if (tab == 3) {
      this.router.navigate(["/views/difineworkflow/workflowparameters"], {
        queryParams: { encrypted },
      });
    } else if (tab == 4) {
      this.router.navigate(["/views/difineworkflow/workflowconditions"], {
        queryParams: { encrypted },
      });
    }
  }
}
interface Status {
  value: string;
  viewValue: string;
}
