import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberGeneratorService {
    private currentNumber: number = 1;
    generateNextNumber(): number {
        return this.currentNumber++;
    }
  constructor() { }
}
