import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../services/encr-decr-service.service';
import { NotificationService } from '../../../services/notification.service';
import { NotificationBasic } from '../../../model/Notification';
import { SystemadminService } from '../../../services/systemadmin.service';
import { GeneralMaster } from '../../../model/common';

declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';

@Component({
  selector: 'app-notificationconfig',
  templateUrl: './notificationconfig.component.html',
  styleUrls: ['./notificationconfig.component.css']
})
export class NotificationconfigComponent implements OnInit {
    ActiveValues: NotificationType[] = [
        { value: '1', viewValue: 'EMAIL' },
        { value: '2', viewValue: 'SYSTEM' },
    ];
    constructor(private router: Router, private route: ActivatedRoute, private sa: SystemadminService, private ns: NotificationService, private ES: EncrDecrServiceService, private fb: FormBuilder) { }
    NotifyForm: FormGroup;
    InstanceTypeItem: GeneralMaster[];
    BasicID = 0;
    ngOnInit(): void {
        $('.my-select').select2();
        this.createForm();

        var queryString = new Array();
        this.route.queryParams.subscribe(params => {
            var Parameter = this.ES.get(localStorage.getItem("EncKey"), params['encrypted']);
            var KeyPara = Parameter.split(',');
            for (var i = 0; i < KeyPara.length; i++) {
                var key = KeyPara[i].split(':')[0];
                var value = KeyPara[i].split(':')[1];
                queryString[key] = value;
            }
           
            if (queryString["ID"] != null) {

                this.BasicID = queryString["ID"].toString();

            }
            
            //if (this.BasicID == 0) {
            //    Swal.fire({
            //        title: 'Fill the Basic Details.',
            //        showDenyButton: false,
            //        confirmButtonText: 'OK',
            //        allowOutsideClick: false,
            //    }).then((result) => {
            //        if (result.isConfirmed) {
            //            this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfig']);
            //        } else {

            //        }
            //    });
            //    return false;
            //}
        });

        this.BindDropDown();
        this.ExistingNotificationBasic();
    }
    createForm() {
        this.NotifyForm = this.fb.group({
            ID: 0,
            NotificationNumber: '',
            NotificationName: '',
            IsActive: 0,
            ApplicationID: 0,
            Module: '',
            NotificationTypeID: 0,
            Description: '',
            DefaultRecipients: '',          
        });
    }

    BindDropDown() {
        this.sa.GetInstanceType(this.NotifyForm.value).subscribe(data => {
            this.InstanceTypeItem = data;
        });
    }

    Onsubmit() {
        alert(this.NotifyForm.value.IsActive);
        var validation = "";
        if ($('#txtNotificationName').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Notification Name</span></br>"
        }
        var ddlApplicationID = $('#ddlApplicationID').val();
        if (ddlApplicationID == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Application</span></br>"
        }
        var ddlNotificationType = $('#ddlNotificationType').val();
        if (ddlNotificationType == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Notification Type</span></br>"
        }

        if (this.NotifyForm.value.IsActive == 0) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Active</span></br>"
        }
        if ($('#txtModule').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Module</span></br>"
        }
        if (validation != "") {

            Swal.fire(validation)
            return false;
        }

        this.NotifyForm.value.UserID = localStorage.getItem("UserID");
        this.NotifyForm.value.SeqNo = 0;
        this.NotifyForm.value.PageName = "NotificationBasic";
        this.NotifyForm.value.SessionFinYear = "2023";
        this.NotifyForm.value.ApplicationID = $('#ddlApplicationID').val();
        this.NotifyForm.value.Application = $("#ddlApplicationID option:selected").text();
        this.NotifyForm.value.NotificationTypeID = $('#ddlNotificationType').val();
        this.NotifyForm.value.NotificationType = $('#ddlNotificationType option:selected').text();

        if ($('#txtDescription').val() != "") {
            this.NotifyForm.value.Description = $('#txtDescription').val();
        }
        else {
            this.NotifyForm.value.Description = "";
        }

        if ($('#txtDefaultRecipients').val() != "") {
            this.NotifyForm.value.DefaultRecipients = $('#txtDefaultRecipients').val();
        }
        else {
            this.NotifyForm.value.DefaultRecipients = "";
        }

        this.ns.saveNotification(this.NotifyForm.value).subscribe(Data => {
            Swal.fire("Record Saved SuccessFully");
            if (this.NotifyForm.value.ID == 0) {
                setTimeout(() => {
                    this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfigview/notificationconfigview']);
                }, 2000);  //5s
            }
        },

            (error: HttpErrorResponse) => {
                Swal.fire(error.message)
            });
    }

    Oncancel() {
        this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfigview/notificationconfigview']);
    }


    ExistingNotificationBasic() {

        this.NotifyForm.value.ID = this.BasicID;

        this.ns.getExistingNotificaionBasic(this.NotifyForm.value).subscribe(data => {
            this.NotifyForm.patchValue(data[0]);
            if (data.length > 0) {
                $('#ddlApplicationID').select2().val(data[0].ApplicationID);
                $('#ddlNotificationType').select2().val(data[0].NotificationTypeID);
                this.NotifyForm.value.IsActive = data[0].IsActive;
            }
        });
    }

    btntabclick(tab) {

        var values = "ID: " + this.BasicID;

        var encrypted = this.ES.set(localStorage.getItem("EncKey"), values);


        if (tab == 1) { 
            this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfig'], { queryParams: { encrypted } });
        }
        else if (tab == 2) {
            this.router.navigate(['/views/systemadmin/notificationconfig/notifymail/notifymail'], { queryParams: { encrypted } });
        }
        else if (tab == 3) {
            this.router.navigate(['/views/systemadmin/notificationconfig/notifysystem/notifysystem'], { queryParams: { encrypted } });
        }
       
    }

}
interface NotificationType {
    value: string;
    viewValue: string;
}