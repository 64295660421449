import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../services/encr-decr-service.service';
import { useraccess } from '../../../model/useraccessdata';
import { UseraccessService } from '../../../services/useraccess.service';
import { DynamicNotificationEmail, MyOffice } from '../../../model/systemadmin';
import { SystemadminService } from '../../../services/systemadmin.service';
import { PaginationService } from '../../../pagination.service';
declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';
import { isDataSource } from '@angular/cdk/collections';
import { Key } from 'protractor';
import { NumberGeneratorService } from '../../../services/number-generator.service';



interface saveUserObjects {

}


@Component({
  selector: 'app-userroles',
  templateUrl: './userroles.component.html',
  styleUrls: ['./userroles.component.css']
})
export class UserrolesComponent implements OnInit {
    RoleValues = [];
    RoleForm: FormGroup;
    RoleListView = [];
    LandingValues: LandingAppType[] = [
        { value: '1', viewValue: 'MANAGEMENT DASHBOARD' },
        { value: '2', viewValue: 'LINER AGENCY' },
        { value: '3', viewValue: 'FREIGHT FORWARDING' },
        { value: '4', viewValue: 'FINANCE' },
        { value: '5', viewValue: 'SYSTEM ADMIN' },
    ];
    ActiveValues: UserActiveType[] = [
        { value: '1', viewValue: 'YES' },
        { value: '0', viewValue: 'NO' },
    ];
    FilterValues: FilterTypes[] = [
        { value: '1', viewValue: 'Menu' },
        { value: '2', viewValue: 'Sub-Menu' },
        { value: '3', viewValue: 'Function' },
        { value: '4', viewValue: 'Sub-Function' },
    ];
    generatedNumber: number;
    getValue: any = "";
    ValDtls: any;
    payload: any = "";
    chkcreateval = 0;
    chkupdateval = 0;
    chkreadval = 0;
    chkprintval = 0;
    chkdeleteval = 0;
    chkreportval = 0;
    chkdisablcreate = 0;
    chkdisableupdate = 0;
    chkdisabledelete = 0;
    chkdisableread = 0;
    chkdisableprint = 0;
    chkdisablereport = 0;
    disalbecreateval = 0;
    disalbeupdateval = 0;
    disalbereadval = 0;
    disalbeprintval = 0;
    disalbedeleteval = 0;
    disalbereportval = 0;


    arrdatas: any = "";
    ischecked: boolean = false
    all: any;
    selected: any;
    RoleExist = [];
    roleid: 0;
    val: any = {};
    obj: any;
    itemarrayid = "";
    roleidv = "";
    constructor(private router: Router, private ES: EncrDecrServiceService, public ps: PaginationService, private route: ActivatedRoute, private sa: SystemadminService, private us: UseraccessService, private fb: FormBuilder, private numberGeneratorService: NumberGeneratorService) {


        this.route.queryParams.subscribe(param => {
            if (param['value']) {
                this.getValue = decodeQueryParam(param['value']);
               
                console.log("rolesidmain",this.getValue.Id);
                console.log("definelist", this.getValue.role_define_list);

              
            }
        })


    }
    // array of all items to be paged
    private allItems: any[];
    RoleList = "";
    
    // pager object
    pager: any = {};

    // paged items
    pagedItems: any[];
    TrackingValDtls: any;
    uniqid = 0;
    arrayitem = [];
    arrayitemupdate = [];
    chkcreatev = 0;
    public ControlDisplay: boolean = true;
    public ControlDisplayEdit: boolean = true;
    DynamicUserPerObject = [];
    disableFilter = false;
    disableTxtSearch = false;
    disableSearchbtn = false;
    disableClearbtn = false;
    disableSavebtn = false;
    disableCancelbtn = false;
    randomNumber = 0;
    public showEdit: boolean = false;
    ngOnInit(): void {
        this.createForm();
       
        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id

            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        });
        
       
        if (this.getValue.Id != undefined) {
            this.showEdit = true;
            this.disableFilter = true;
            this.disableTxtSearch = true;
            this.disableSearchbtn = true;
            this.disableClearbtn = true;
            this.disableSavebtn = true;
            this.disableCancelbtn = true;
            this.ControlDisplayEdit = true;
            this.Disableformcontrol();
        }
        else {
            this.Enableformcontrol();
            this.ControlDisplayEdit = false;
        }
       
        this.GetExistingAutoId();
        $('#ddlLandingApp').on('select2:select', (e, args) => {
            this.OnchangeLandingApp($('#ddlLandingApp').val());
            //if ($('#ddlLandingApp').val() == 3) {
               
            //    this.BindProfileList();
            //        this.disableFilter = false;
            //        this.disableTxtSearch = false;
            //        this.disableSearchbtn = false;
            //        this.disableClearbtn = false;
            //        this.disableSavebtn = false;
            //        this.disableCancelbtn = false;
              
               
            //}
            //else {
            //    this.disableFilter = true;
            //    this.disableTxtSearch = true;
            //    this.disableSearchbtn = true;
            //    this.disableClearbtn = true;
            //    this.disableSavebtn = true;
            //    this.disableCancelbtn = true;

            //    Swal.fire("Record Not Found");
            //    this.RoleListView.length = 0;
            //}
        });

        //if (this.getValue.Landing_Application_Id != undefined) {
            
        //    this.disableFilter = false;
        //    this.disableTxtSearch = false;
        //    this.disableSearchbtn = false;
        //    this.disableClearbtn = false;
        //    this.disableSavebtn = false;
        //    this.disableCancelbtn = false;
        //}
        //else {
        //    this.disableFilter = true;
        //    this.disableTxtSearch = true;
        //    this.disableSearchbtn = true;
        //    this.disableClearbtn = true;
        //    this.disableSavebtn = true;
        //    this.disableCancelbtn = true;

        //    this.RoleListView.length = 0;
        //}
        if (this.getValue.Role_Id != undefined) {


            let payload = {
                "role_Id": this.getValue.Role_Id,
                "division_Id": 0,
                "division_Name": "",
                "office_Id": 0,
                "ref_Role_Id_sch": "",
                "landing_Application_Id": this.getValue.Landing_Application_Id,
                "is_Active": 1,
                "menu_Type": this.getValue.Landing_Application_Id
            }



            this.us.GetRoleDefineList(payload).subscribe(res => {
                console.log(res);
                if (res?.data?.UserRoles.length > 0) {
                    res?.data?.UserRoles.forEach((item: any) => {
                        this.RoleListView = item.role_define_list;

                        for (let i = 0; i < this.RoleListView.length; i++) {
                            console.log("RoleIDS", this.RoleListView[i].Id);
                            this.roleid = this.RoleListView[i].Id;
                            if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                                this.ControlDisplay = true;
                            }


                        }
                    })
                }
                //this.RoleListView = this.getValue.role_define_list;
                console.log("existingitems", this.RoleListView);

            });
        }
  
    }
   
    checkValue(event: any) {
        console.log(event);
    }

    OnchangeLandingApp(landingapp) {
        if (landingapp != 0 || landingapp != undefined) {
            this.disableFilter = false;
                    this.disableTxtSearch = false;
                    this.disableSearchbtn = false;
                    this.disableClearbtn = false;
                    this.disableSavebtn = false;
                    this.disableCancelbtn = false;
        }
        else {
                this.disableFilter = true;
                this.disableTxtSearch = true;
                this.disableSearchbtn = true;
                this.disableClearbtn = true;
                this.disableSavebtn = true;
                this.disableCancelbtn = true;

                Swal.fire("Record Not Found");
                this.RoleListView.length = 0;
            }
       
            
     
                let payload = {
                    "menu_Name": "",
                    "submenu": "",
                    "function_Name": "",
                    "subfunction_Name": "",
                    "menuType": $('#ddlLandingApp').val()
                }
                this.us.GetUserRoles(payload).subscribe(res => {
                    console.log("allmenulist", res);



                    this.RoleListView = res.data.MainMenuList;
                    for (let i = 0; i < this.RoleListView.length; i++) {

                        if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                            this.ControlDisplay = true;
                        }

                    }


                });
            
    }
  
   
    createForm() {

        if (this.getValue.Role_Id != undefined) {
           
            this.RoleForm = this.fb.group({

                RoleID: [this.getValue ? this.getValue.Role_Id : ""],
                RoleName: [this.getValue ? this.getValue.Role_Name : ""],
                landing_Application: [this.getValue ? this.getValue.Landing_Application_Id : ""],
                user_Active: [this.getValue ? this.getValue.Is_Active : ""],
                RoleDes: [this.getValue ? this.getValue.Role_Description : ""],
               
                
            });
           
        }
        else {
            this.RoleForm = this.fb.group({

               
                RoleID: '',
                RoleName: '',
                landing_Application: '',
                user_Active: 1,
                RoleDes: '',
               

            });

        }
    }
   

    //BindProfileList(landingapp) {


    //    if (this.getValue.Role_Id != undefined) {
          
           
    //        let payload = {
    //            "role_Id": this.getValue.Role_Id,
    //            "division_Id": 0,
    //            "division_Name": "",
    //            "office_Id": 0,
    //            "ref_Role_Id_sch": "",
    //            "is_Active": 1
    //        }



    //        this.us.GetRoleDefineList(payload).subscribe(res => {
    //            console.log(res);
    //            if (res?.data?.UserRoles.length > 0) {
    //                res?.data?.UserRoles.forEach((item: any) => {
    //                    this.RoleListView = item.role_define_list;
                       
    //                    for (let i = 0; i < this.RoleListView.length; i++) {
    //                        console.log("RoleIDS", this.RoleListView[i].Id);
    //                        this.roleid = this.RoleListView[i].Id;
    //                        if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

    //                            this.ControlDisplay = true;
    //                        }
                           
                          
    //                    }
    //                })
    //            }
    //            //this.RoleListView = this.getValue.role_define_list;
    //            console.log("existingitems", this.RoleListView);

    //        });
    //    }
    //    else {
           
    //        let payload = {
    //            "menu_Name": "",
    //            "submenu": "",
    //            "function_Name": "",
    //            "subfunction_Name": "",
    //            "menuTyp": landingapp
    //        }
    //        this.us.GetUserRoles(payload).subscribe(res => {
    //    console.log("allmenulist", res);
      
        
        
    //    this.RoleListView = res.data.MainMenuList;
    //    for (let i = 0; i < this.RoleListView.length; i++) {
            
    //        if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1)  {
                
    //            this.ControlDisplay = true;
    //        }
           
    //    }
       

    //});
    //    }
       


        

    //}

    onSubmitSearch() {

        var menuname = "menu_Name";
        var submenu = "submenu";
        var funcname = "function_Name";
        var subfuncname = "subfunction_Name";
        var txtsearch = "";
        var menuType = "";

        if (this.getValue.Landing_Application_Id != undefined) {
            menuType = this.getValue.Landing_Application_Id;
        }
        else {
            menuType = $('#ddlLandingApp').val();
        }
           
      /*  var menutype = $("#ddlLandingApp").val()*/

       

        var txtvalue = $("#txtSearch").val().trim();
       
        if ($('#ddlFilterC').val() == 1) {
            let payload = {
                menu_Name: txtvalue,
                submenu: "",
                function_Name: "",
                subfunction_Name: "",
                menuType: menuType

            }
            if (this.getValue.Landing_Application_Id != undefined) {
                this.us.GetRoleDefineList(payload).subscribe(res => {
                    console.log(res);
                    if (res?.data?.UserRoles.length > 0) {
                        res?.data?.UserRoles.forEach((item: any) => {
                            this.RoleListView = item.role_define_list;

                            for (let i = 0; i < this.RoleListView.length; i++) {
                                console.log("RoleIDS", this.RoleListView[i].Id);
                                this.roleid = this.RoleListView[i].Id;
                                if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                                    this.ControlDisplay = true;
                                }


                            }
                        })
                    }

                    console.log("existingitems", this.RoleListView);

                });
            }
            else {
                this.us.GetUserRoles(payload).subscribe(res => {
                    console.log("allmenulist", res);



                    this.RoleListView = res.data.MainMenuList;
                    for (let i = 0; i < this.RoleListView.length; i++) {

                        if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                            this.ControlDisplay = true;
                        }

                    }


                });
            }



        }
        if ($('#ddlFilterC').val() == 2) {
            let payload = {
                menu_Name: "",
                submenu: txtvalue,
                function_Name: "",
                subfunction_Name: "",
                menuType: menuType
            }
            if (this.getValue.Landing_Application_Id != undefined) {
                this.us.GetRoleDefineList(payload).subscribe(res => {
                    console.log(res);
                    if (res?.data?.UserRoles.length > 0) {
                        res?.data?.UserRoles.forEach((item: any) => {
                            this.RoleListView = item.role_define_list;

                            for (let i = 0; i < this.RoleListView.length; i++) {
                                console.log("RoleIDS", this.RoleListView[i].Id);
                                this.roleid = this.RoleListView[i].Id;
                                if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                                    this.ControlDisplay = true;
                                }


                            }
                        })
                    }

                    console.log("existingitems", this.RoleListView);

                });
            }
            else {
                this.us.GetUserRoles(payload).subscribe(res => {
                    console.log("allmenulist", res);



                    this.RoleListView = res.data.MainMenuList;
                    for (let i = 0; i < this.RoleListView.length; i++) {

                        if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                            this.ControlDisplay = true;
                        }

                    }


                });
            }


        }
        if ($('#ddlFilterC').val() == 3) {
            let payload = {
                menu_Name: "",
                submenu: "",
                function_Name: txtvalue,
                subfunction_Name: "",
                menuType: menuType
            }
            if (this.getValue.Landing_Application_Id != undefined) {
                this.us.GetRoleDefineList(payload).subscribe(res => {
                    console.log(res);
                    if (res?.data?.UserRoles.length > 0) {
                        res?.data?.UserRoles.forEach((item: any) => {
                            this.RoleListView = item.role_define_list;

                            for (let i = 0; i < this.RoleListView.length; i++) {
                                console.log("RoleIDS", this.RoleListView[i].Id);
                                this.roleid = this.RoleListView[i].Id;
                                if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                                    this.ControlDisplay = true;
                                }


                            }
                        })
                    }

                    console.log("existingitems", this.RoleListView);

                });
            }
            else {
                this.us.GetUserRoles(payload).subscribe(res => {
                    console.log("allmenulist", res);



                    this.RoleListView = res.data.MainMenuList;
                    for (let i = 0; i < this.RoleListView.length; i++) {

                        if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                            this.ControlDisplay = true;
                        }

                    }


                });
            }

        }
        if ($('#ddlFilterC').val() == 4) {
            let payload = {
                menu_Name: "",
                submenu: "",
                function_Name: "",
                subfunction_Name: txtvalue,
                menuType: menuType
            }
            if (this.getValue.Landing_Application_Id != undefined) {
                this.us.GetRoleDefineList(payload).subscribe(res => {
                    console.log(res);
                    if (res?.data?.UserRoles.length > 0) {
                        res?.data?.UserRoles.forEach((item: any) => {
                            this.RoleListView = item.role_define_list;

                            for (let i = 0; i < this.RoleListView.length; i++) {
                                console.log("RoleIDS", this.RoleListView[i].Id);
                                this.roleid = this.RoleListView[i].Id;
                                if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                                    this.ControlDisplay = true;
                                }


                            }
                        })
                    }

                    console.log("existingitems", this.RoleListView);

                });
            }
            else {
                this.us.GetUserRoles(payload).subscribe(res => {
                    console.log("allmenulist", res);



                    this.RoleListView = res.data.MainMenuList;
                    for (let i = 0; i < this.RoleListView.length; i++) {

                        if (this.RoleListView[i].Create_opt == 1 || this.RoleListView[i].Update_opt == 1 || this.RoleListView[i].Read_opt == 1 || this.RoleListView[i].Print_opt == 1 || this.RoleListView[i].Delete_opt == 1 || this.RoleListView[i].Report_opt == 1) {

                            this.ControlDisplay = true;
                        }

                    }


                });
            }

        }
       
      
    }

    clearSearch(landingapp) {
        $('#ddlFilterC').val(0).trigger("change");
        $('#txtSearch').val("");
        /*this.BindProfileList();*/
    }
  
   
    ItmesArr = [];

  
    selecteddata(event, ckval, id, index) {

        if (id == 1) {

            if (event.target.checked == true) {
                this.RoleListView[index].Create_opt = 2;

            } else {
                this.RoleListView[index].Create_opt = 0;
                if (this.RoleListView[index].Update_opt != 1) {
                    this.RoleListView[index].Update_opt = 0;
                }
                if (this.RoleListView[index].Delete_opt != 1) {
                    this.RoleListView[index].Delete_opt = 0;
                }

                
                


            }

        }

        else if (id == 2) {
            if (event.target.checked == true) {

                if (this.RoleListView[index].Create_opt == 2 || this.RoleListView[index].Create_opt == 1) {
                    this.RoleListView[index].Update_opt = 2;
                }

                else if (this.RoleListView[index].Create_opt == 0) {
                    Swal.fire("Not Allowed! You Dont have Create Permission");
                    event.preventDefault();
                    


                }
            }

            else {
                this.RoleListView[index].Update_opt = 0;
                if (this.RoleListView[index].Delete_opt != 1) {
                    this.RoleListView[index].Delete_opt = 0;
                }
                
            }


            //else{

            //    event.preventDefault();
            //    this.RoleListView[index].Update_opt = 0;
            //    this.RoleListView[index].Delete_opt = 0;

            //    Swal.fire("Not Allowed! You Dont have Create Permission");
            //}


        }

        else if (id == 3) {
            if (event.target.checked == true) {
                this.RoleListView[index].Read_opt = 2;
            } else {
                this.RoleListView[index].Read_opt = 0;
                if (this.RoleListView[index].Print_opt != 1) {
                    this.RoleListView[index].Print_opt = 0;
                }
                
            }

        }

        else if (id == 4) {

            if (event.target.checked == true) {
                if (this.RoleListView[index].Create_opt == 1 && this.RoleListView[index].Update_opt == 1) {
                    this.RoleListView[index].Delete_opt = 2;
                }
                else if (this.RoleListView[index].Create_opt == 2 && this.RoleListView[index].Update_opt == 2) {
                    this.RoleListView[index].Delete_opt = 2;
                }
                
                else if (this.RoleListView[index].Create_opt == 0 && this.RoleListView[index].Update_opt == 0) {
                    //this.RoleListView[index].Delete_opt = 2;
                    Swal.fire("Not Allowed! You Dont have Create & Update Permission");
                    event.preventDefault();
                }
                else if (this.RoleListView[index].Create_opt == 1 && this.RoleListView[index].Update_opt == 2) {
                    this.RoleListView[index].Delete_opt = 2;
                }
                else if (this.RoleListView[index].Create_opt == 2 && this.RoleListView[index].Update_opt == 1) {
                    this.RoleListView[index].Delete_opt = 2;
                    //Swal.fire("Not Allowed! You Dont have Create & Update Permission");
                    //event.preventDefault();
                }
                else if (this.RoleListView[index].Create_opt == 1 && this.RoleListView[index].Update_opt == 0) {
                    //this.RoleListView[index].Delete_opt = 2;
                    Swal.fire("Not Allowed! You Dont have Create & Update Permission");
                    event.preventDefault();
                }
                else if (this.RoleListView[index].Create_opt == 0 && this.RoleListView[index].Update_opt == 1) {
                    //this.RoleListView[index].Delete_opt = 2;
                    Swal.fire("Not Allowed! You Dont have Create & Update Permission");
                    event.preventDefault();
                }
                else {
                    Swal.fire("Not Allowed! You Dont have Create & Update Permission");
                    event.preventDefault();
                }
            }
            else {
                this.RoleListView[index].Delete_opt = 0
            }
        }


        else if (id == 5) {
            if (event.target.checked == true) {
                if (this.RoleListView[index].Read_opt == 2) {
                    this.RoleListView[index].Print_opt = 2;
                } else {
                    Swal.fire("Not Allowed! You Dont have Read Permission");
                    event.preventDefault();
                }

            } else {
                this.RoleListView[index].Print_opt = 0;
            }

        }
        else if (id == 6) {
            if (event.target.checked == true) {
                this.RoleListView[index].Report_opt = 2
            } else {
                this.RoleListView[index].Report_opt = 0
            }

        }
        this.ItmesArr.push(this.RoleListView[index]);

        console.log("ItemsArr", this.ItmesArr);

    }




    //selecteddata(event, ckval, id, index) {

    //    if (id == 1) {

    //        if (event.target.checked == true) {
    //            this.RoleListView[index].Create_opt = 2
    //        } else {
    //            this.RoleListView[index].Create_opt = 0
    //        }



    //    }
    //    else if (id == 2) {

    //        if (event.target.checked == true) {
    //            this.RoleListView[index].Update_opt = 2
    //        } else {
    //            this.RoleListView[index].Update_opt = 0
    //        }

    //    }
    //    else if (id == 3) {
    //        if (event.target.checked == true) {
    //            this.RoleListView[index].Read_opt = 2
    //        } else {
    //            this.RoleListView[index].Read_opt = 0
    //        }

    //    }
    //    else if (id == 4) {
    //        if (event.target.checked == true) {
    //            this.RoleListView[index].Delete_opt = 2
    //        } else {
    //            this.RoleListView[index].Delete_opt = 0
    //        }

    //    }
    //    else if (id == 5) {
    //        if (event.target.checked == true) {
    //            this.RoleListView[index].Print_opt = 2
    //        } else {
    //            this.RoleListView[index].Print_opt = 0
    //        }

    //    }
    //    else if (id == 6) {
    //        if (event.target.checked == true) {
    //            this.RoleListView[index].Report_opt = 2
    //        } else {
    //            this.RoleListView[index].Report_opt = 0
    //        }

    //    }
    //    this.ItmesArr.push(this.RoleListView[index]);

    //    console.log("ItemsArr", this.ItmesArr);

    //}
    GetExistingAutoId() {
        this.us.GetAutoRolId(this.RoleForm.value).subscribe(Data => {

            this.generatedNumber = Data[0].AutoId;
            console.log("GeneratorNumber", this.generatedNumber);
            this.randomNumber = this.generatedNumber;
        },

            (error: HttpErrorResponse) => {
                Swal.fire(error.message)
            });
        console.log("ExstingRandom", this.randomNumber);
    }
   


    Onsubmit() {
        var validation = "";

        if ($('#txtRoleName').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Role Name</span></br>"
        }

        if ($('#ddlLandingApp').val() == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Application</span></br>"
        }

        if ($('#ddlActive').val() == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Active</span></br>"
        }

        if ($('#txtRoleDes').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Role Description</span></br>"
        }
        if (this.getValue.Is_Active == 2) {
            validation += "<span style='color:red;'>*</span> <span>Please Remove Roles from User Befor InActive</span></br>"
        }

     
        var Is_ActiveVn = 0;
        if ($('#ddlActive').val() == 0) {

            if (this.getValue.Is_Active == 1) {

                Is_ActiveVn = 1;
                validation += "<span style='color:red;'>*</span> <span>Not Allowed..Please Contact Administrator</span></br>"
                //Swal.fire("Not Allowed..Please Contact Administrator");
            }
            else {
                Is_ActiveVn = 0;
            }



        }

        else {
            Is_ActiveVn = 1;
        }
       
        var checked = $("#datatable input[type=checkbox]:checked").length;

        if (checked == 0) {
            validation += "<span style='color:red;'>*</span> <span>Please Check Atleast One</span></br>"
            
        }
        if (validation != "") {
            Swal.fire(validation);
            return false;
        }
       
        //var checked = $("#datatable input[type=checkbox]:checked").length;

        //if (checked == 0) {
        //    Swal.fire("Please check atleast one Checkbox");
        //}

       /* else {*/
            let uniqueSubfunctionIds = Array.from(new Set(this.ItmesArr.map(item => item.Subfunction_Id)));

            this.ItmesArr = uniqueSubfunctionIds.map(subfunctionId => {
                return this.ItmesArr.find(item => item.Subfunction_Id === subfunctionId);
            });

        console.log("ItmesArr", this.ItmesArr)
        for (let i = 0; i < this.ItmesArr.length; i++) {
            console.log("idarray", this.ItmesArr[i].Id);
            this.itemarrayid = this.ItmesArr[i].Id;

        }

        if (this.getValue.Role_Id != undefined) {
            this.ItmesArr = this.ItmesArr.filter((item: any) => item?.Create_opt == 2 || item?.Update_opt == 2 || item?.Read_opt == 2 || item?.Delete_opt == 2 || item?.Print_opt == 2 || item?.Report_opt == 2 || item?.Create_opt == 0 || item?.Update_opt == 0 || item?.Read_opt == 0 || item?.Delete_opt == 0 || item?.Print_opt == 0 || item?.Report_opt == 0);
        }
        else {
            this.ItmesArr = this.ItmesArr.filter((item: any) => item?.Create_opt == 2 || item?.Update_opt == 2 || item?.Read_opt == 2 || item?.Delete_opt == 2 || item?.Print_opt == 2 || item?.Report_opt == 2);
        }
           
        
        //this.ItmesArr = this.ItmesArr.filter((item: any) => item?.Create_opt == 2 || item?.Update_opt == 2 || item?.Read_opt == 2 || item?.Delete_opt == 2 || item?.Print_opt == 2 || item?.Report_opt == 2);   

            console.log("filterarray",this.ItmesArr);

            var arr = this.ItmesArr;
            var Itemsu = [];
            var payloadarr = [];
            var menu_Id = "";
            var menuname = "";
            var submenuid = "";
            var submenuname = "";
        var functionid = "";
        var subfunctioncode = "";
            var functionname = "";
            var subfunctionid = "";
            var subfunctionname = "";
            /*var role_Id = "";*/
            var ref_Role_Id = "";
        var ref_Role_Name = $("#txtRoleName").val();
        var ref_Application_Id = $('#ddlLandingApp').val();
            var ref_Application = $('#ddlLandingApp option:selected').text();
            var created_by = 0;
            var modified_by = 0;

            var create_opt = 0;
            var update_opt = 0;
            var read_opt = 0;
            var print_opt = 0;
            var delete_opt = 0;
        var report_opt = 0;
        var is_ActiveV = 1;
        var is_Delete = this.chkdeleteval;
        var AutoId = "";
            
            var Isdata = "";

        var defineid = 0;

       
       
            this.generatedNumber = this.randomNumber + 1;
            console.log("AutoGen", this.generatedNumber);
            this.roleidv = "ROLE" + "-" + this.generatedNumber;
        
       
        //     this.generatedNumber = this.randomNumber++;
        ////this.generatedNumber = this.numberGeneratorService.generateNextNumber();
        //    this.roleidv = "ROLE" + "-" + this.generatedNumber;
        //console.log("AutoGenerator", this.generatedNumber);
       

       
       
            if (this.getValue.Role_Id != undefined) {
                this.payload = {
                    "id": this.getValue.Id,
                    "AutoId": this.generatedNumber,
                    "isdata": "U",
                    "role_Id": this.getValue.Role_Id,
                    "role_Name": $("#txtRoleName").val(),
                    "role_Description": $("#txtRoleDes").val(),
                    "assignment_Remarks": "Testing",
                    "landing_Application_Id": $("#ddlLandingApp").val(),
                    "landing_Application": $('#ddlLandingApp option:selected').text(),
                    "division_Id": 1,
                    "division_Name": "Testing",
                    "office_Id": 1,
                    "office_Name": "Testing",
                    "is_Active": Is_ActiveVn,
                    "Sales_Person": 1,
                    "is_Delete": 0,
                    "created_by": 1,
                    "modified_by": 0,

                    "role_define_list": Itemsu,
                }
            }
            else {
                this.payload = {

                    "isdata": "N",
                    "AutoId": this.generatedNumber,
                    "role_Id": this.roleidv,
                    "role_Name": $("#txtRoleName").val(),
                    "role_Description": $("#txtRoleDes").val(),
                    "assignment_Remarks": "Testing",
                    "landing_Application_Id": $("#ddlLandingApp").val(),
                    "landing_Application": $('#ddlLandingApp option:selected').text(),
                    "division_Id": 1,
                    "division_Name": "Testing",
                    "office_Id": 1,
                    "office_Name": "Testing",
                    "is_Active": Is_ActiveVn,
                    "is_Delete": 0,
                    "Sales_Person": 1,
                    "created_by": 1,
                    "modified_by": 0,

                    "role_define_list": Itemsu,
                }
        }
        for (let dataItem of arr) {
            if (!dataItem.Id) {
                Isdata = "N";
                defineid = 0;
            }
            else {
                Isdata = "U";
                defineid = dataItem.Id;
            }
            if (this.getValue.Role_Id != undefined) {
                ref_Role_Id = this.getValue.Role_Id;
            }
            else {

                ref_Role_Id = this.roleidv;

              
            }

            console.log(Itemsu);


            let obj = {
                id: defineid,
                isdata: Isdata, ref_Role_Id: ref_Role_Id, ref_Role_Name: ref_Role_Name, ref_Application_Id: ref_Application_Id, ref_Application: ref_Application, menu_Id: dataItem.Menu_Id, menu_Name: dataItem.Menu_Name, submenu_Id: dataItem.Submenu_Id, submenu: dataItem.Submenu, function_Id: dataItem.Function_Id, sub_Function_Code: dataItem.Sub_Function_Code, function_Name: dataItem.Function_Name, subfunction_Id: dataItem.Subfunction_Id, subfunction_Name: dataItem.Subfunction_Name,
                created_by: created_by,
                modified_by: modified_by,
                Create_opt: dataItem.Create_opt,
                Update_opt: dataItem.Update_opt,
                Read_opt: dataItem.Read_opt,
                Print_opt: dataItem.Print_opt,
                Delete_opt: dataItem.Delete_opt,
                Report_opt: dataItem.Report_opt,
                is_Delete: is_Delete,
                is_Active: is_ActiveV
            }


            Itemsu.push(obj);


            console.log("SaveArray", Itemsu);

        }

            console.log("payloadvalues", this.payload);
        this.us.saveUserRole(this.payload).subscribe(Data => {

           

            Swal.fire("Record Saved Successfully");
            setTimeout(() => {
                this.router.navigate(['/views/systemadmin/userroles/userrolesview/userrolesview']);
            }, 2000);  
                var rolmainid = this.getValue.Id;
                var rolemainvalue = "rolmainid";
                let idString: string = rolmainid.toString();
                console.log("rolegetid", idString);

                let payload1 = {
                    "isdata": "G",
                    "ref_RoleId": idString,
                    "UserId": "0",
                    "Created_by": 0
                }
                console.log("GeneratePayload", payload1);
                this.us.GenerateUserPermissionObject(payload1).subscribe(Data => {
                },

                    (error: HttpErrorResponse) => {
                        Swal.fire(error.message)
                    });
                console.log(this.payload);
               

              
            },

                (error: HttpErrorResponse) => {
                    Swal.fire(error.message)
                });
       
      

        }

    OnclickeEdit() {
        this.Enableformcontrol();
             this.disableFilter = false;
            this.disableTxtSearch = false;
            this.disableSearchbtn = false;
            this.disableClearbtn = false;
            this.disableSavebtn = false;
        this.disableCancelbtn = false;
        this.ControlDisplayEdit = false;
    }
    Disableformcontrol() {
        this.RoleForm.controls["RoleName"].disable();
        this.RoleForm.controls["landing_Application"].disable();
        this.RoleForm.controls["user_Active"].disable();
        this.RoleForm.controls["RoleDes"].disable();
    }
    Enableformcontrol() {
        this.RoleForm.controls["RoleName"].enable();
        
        this.RoleForm.controls["user_Active"].enable();
        this.RoleForm.controls["RoleDes"].enable();
    }
}
interface UserActiveType {
    value: string;
    viewValue: string;
}
interface LandingAppType {
    value: string;
    viewValue: string;
}
interface FilterTypes {
    value: string;
    viewValue: string;
}

export function decodeQueryParam(encodedValue: string): any {
    const decodedBytes = Uint8Array.from(atob(encodedValue), c => c.charCodeAt(0));
    const decoder = new TextDecoder('utf-8');
    const decodedString = decoder.decode(decodedBytes);
    /*console.log("decode", decodedString);*/
    return JSON.parse(decodedString);
}
