import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EncrDecrServiceService } from 'src/app/services/encr-decr-service.service';
import { MastersService } from 'src/app/services/masters.service';
import { SystemadminService } from 'src/app/services/systemadmin.service';

@Component({
  selector: 'app-workflowtabs',
  templateUrl: './workflowtabs.component.html',
  styleUrls: ['./workflowtabs.component.css']
})
export class WorkflowtabsComponent implements OnInit {
  currentTab: any;
  tabsForm:FormGroup;
  // encryptedParam: any;

  // changeTab(tabName) {
  //   this.currentTab = tabName;
  // }
  // constructor() { }
  constructor(private router: Router, private route: ActivatedRoute, private ES: EncrDecrServiceService, 
    private service: MastersService, private sa: SystemadminService, private fb: FormBuilder) {
    this.route.queryParams.subscribe(params => {
      let eventNumber=this.ES.get(localStorage.getItem("EncKey"), params.encrypted)
      // this.encryptedParam = params.encrypted;
      console.log('params', params.encrypted,eventNumber)
        this.tabsForm = this.fb.group({
            ID:eventNumber,

        });
    });
}

  ngOnInit(): void {
    // this.currentTab = 'app-basicdetails'
  }

  changeTab(tab) {


    var values = "ID: " + this.tabsForm.value.ID;
    //var values = "ID: 8";
    var encrypted =  this.ES.set(localStorage.getItem("EncKey"), values);//this.encryptedParam;
    if (tab == 1) {

        this.router.navigate(['/views/difineworkflow/basicdetails'], { queryParams: { encrypted } });
    }
    else if (tab == 2) {

        this.router.navigate(['/views/difineworkflow/workflowrules'], { queryParams: { encrypted } });

    }
    else if (tab == 3) {

        this.router.navigate(['/views/difineworkflow/workflowparameters'], { queryParams: { encrypted } });

    }

    else if (tab == 4) {

      this.router.navigate(['/views/difineworkflow/workflowconditions'], { queryParams: { encrypted } });

  }
  
}

}
