

import { Component, Inject, OnInit,OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import Swal from 'sweetalert2';
import { WorkflowService } from 'src/app/services/workflow.service';
import { PaginationService } from 'src/app/pagination.service';
import { EncrDecrServiceService } from 'src/app/services/encr-decr-service.service';
import { MyCompany, MySACommon } from 'src/app/model/systemadmin';
import { Country, DynamicPDF1, MyAgency } from 'src/app/model/common';
import { Globals } from 'src/app/globals';
import { Title } from '@angular/platform-browser';
import { CustomerContract } from 'src/app/model/CustomerContract';
import { Subject } from 'rxjs';
// import { DataTableDirective } from 'angular-datatables';


declare let $: any;
@Component({
  selector: 'app-defineuserpools',
  templateUrl: './defineuserpools.component.html',
  styleUrls: ['./defineuserpools.component.css']
})
export class DefineuserpoolsComponent implements OnInit {

  // dtTrigger: Subject<any> = new Subject<any>();

  // dtOptions: DataTables.Settings = {};
  isAsc = false;
  myControl = new FormControl('');
  title = 'Define UsersPools ';
  currentTab: any;
  service: any;
  deleteId: any;
  private id: number;
  eventnumber: any;
  complianceRowId: number;
  pageSection: number;
  i: number = -1;
  containerList: any = [];
  userpoolnumber: any;
  currentPage=1;

  // changeTab(tabName) {
  //   this.currentTab = tabName;
  // }

  statusvalues: Status[] = [
    { value: 'Yes', viewValue: 'Yes' },
    { value: 'No', viewValue: 'No' },
  ];
  aclFilename: DynamicPDF1[];
  addItem: any = { Data: [] };
  ApplicationItem: any = { Data: [] };
  deleteItem: any = { Data: [] };
  poolsItems: any = { Data: [] };




  constructor(private http: HttpClient, private globals: Globals,
    private ms: MastersService, private router: Router,
    private route: ActivatedRoute, private fb: FormBuilder,
    public ps: PaginationService, public wf: WorkflowService, private titleService: Title) {
      this.poolsForm = this.fb.group(

        {
          id:0,
          userpoolnumber: '',
          description: '',
          application: '',
          active: '',
        });
        
     }

  // array of all items to be paged
  private allItems: any = { Data: [] };

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  DepartmentList: any = [];
  dataSource: Country[];
  poolsForm: FormGroup;

  ActivateAddEditDepComp: boolean = false;
  dep: any;

  DepartmentIdFilter: string = "";
  DepartmentNameFilter: string = "";
  DepartmentListWithoutFilter: any = [];


  // ngOnDestroy(): void {
  //   // Do not forget to unsubscribe the event
  //   this.dtTrigger.unsubscribe();
  // }

  ngOnInit(): void {
    // this.currentTab = 'basics'

    this.isAsc=true;
    this.titleService.setTitle(this.title);
    $('.my-select').select2();
    $(document).on('select2:open', (e) => {
      const selectId = e.target.id

      $('#ddlApplication').on('select2:select', (e, args) => {
        this.poolsForm.patchValue({application:($("#ddlApplication").val())});
      });  
      $('#ddlStatusv').on('select2:select', (e, args) => {
        this.poolsForm.patchValue({active:($("#ddlStatusv").val())});
      });

      $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
        key,
        value,
      ) {
        value.focus();
      })
    })
    $('#ddlStatusv').val(0).trigger("change");
    $('#ddlApplication').val(0).trigger("change");
    // $('#ddlApplication').on('select2:select', (e, args) => {
    //   this.ApplicationItem($("#ddlApplication").val());
    // });
   
    // this.newForm();
    this.getUserPoolsList();
   this.applicationBinding();
 

  //  this.dtOptions = {
  //   pagingType: 'full_numbers',
  //   pageLength: 2
  // };
    
  }

  public sortOrderForMulityObj(arrObj: Array<any>, field: any, isAsc:any) {

    console.log('field',arrObj, field,isAsc)
    this.isAsc=!isAsc
    arrObj=this.poolsItems;
    arrObj.sort((a, b) => {
      let first: any;
      let sec: any;
      let fieldArr: any = field.split('.');
      for (let i = 0; i < fieldArr.length; i++) {
        a = a[fieldArr[i]];
        b = b[fieldArr[i]]
      }
      if(a){
      first=a;
      sec=b;
      }
      //console.log('first',first)
      if (typeof first === 'string' || first instanceof String) {
        first = first.toLocaleLowerCase(); sec = sec.toLocaleLowerCase();
      }
      if (isAsc)
        return first < sec ? -1 : first > sec ? 1 : 0;
      else
        return first > sec ? -1 : first < sec ? 1 : 0;

      //return  this.isAsc?res:!res
    })
    this.setPage(this.currentPage)
    //this.sortOrderForMulityObj(this.pagedItems,'eventname',this.isAsc)
    return this.pagedItems;
  }


  applicationBinding() {
    console.log("application", this.poolsForm.value);
    this.wf.getApplicationList({ "type": "application" }).subscribe(data => {
      this.ApplicationItem = data;
      $('#ddlApplication').val(0).trigger("change");
    });

  }

  getUserPoolsList() {
    this.wf.getUserPoolsList().subscribe((data:any) => {
      this.poolsItems = data?.Data;
      this.sortOrderForMulityObj(this.pagedItems,'userpoolnumber',this.isAsc)
      // this.dtTrigger.next();
      console.log("getUserPoolsList", data);
     
      this.setPage(1);
      // this.sortOrderForMulityObj();
    });
  }

  // addRoleList() {
  //   console.log("addRoleList", this.poolsForm.value);
  //   // this.poolsForm.patchValue({
  //   //   // eventnumber: this.eventnumber
  //   // })
  //   this.wf.addRoleList(this.poolsForm.value).subscribe(data => {
  //     this.poolsItems = data;
  //     this.poolsForm.reset()
  //     console.log("addRoleList", data);
  //     // this.setPage(1);
  //   });
  // }
  // editRoleList() {
  //   // console.log("editRoleList", this.poolsForm.value);
  //    this.wf.editRoleList(this.poolsForm.value).subscribe(data => {
  //      this.poolsItems = data;
  //      console.log("editRoleList", data);
      
  //  });
  // }



  // deleteRole() {
  //   // console.log("deleteContainer", this.searchForm.value);
  //   console.log("this.id", this.deleteItem)
  //   // this.id = id;
  //   // if ( this.containerList.id) {
  //   //   this.searchForm.controls['isNew'].setValue("D");

  //   // }

  //   this.wf.deleteRole({ id: this.id }).subscribe(data => {
  //     this.deleteItem = data;
  //     // this.getRuleByNumber(this.eventnumber)
  //   });
  // }
  
  onChange(id, i: number) {
    this.id = id;
    this.i = i;
  }
  clearSearch() {     
    this.poolsForm.value.Status = $('#ddlStatusv').val();
    // this.createForm()
    this.poolsForm = this.fb.group(

      {
        id:0,
        userpoolnumber: '',
        description: '',
        application: '',
        active: '',
      });
    this.getUserPoolsList();
    $('#ddlStatusv').val(0).trigger("change");
    $('#ddlApplication').val(0).trigger("change");
    // $('#ddlStatusv').val(0).trigger("change");
}

  onSubmit() {
    // this.poolsForm.value.Status = $('#ddlStatusv').val();
    this.wf.getSearchPool(this.poolsForm.value).subscribe((data:any)  => {
      // set items to json response
      this.poolsItems =  data?.Data;
      console.log("222",data);
      // initialize to page 1
      this.setPage(1);

  },error =>{
    this.poolsItems = []
    this.setPage(1);
  });
    
  }

  public selectedName: any;
  public highlightRow(dataItem) {
    this.selectedName = dataItem.StateCode;
  }
  setPage(page: number) {
    //if (page < 1 || page > this.pager.totalPages) {
    //    return;
    //}

    // get pager object from service
    this.pager = this.ps.getPager(this.poolsItems.length, page);
    this.currentPage=page;
    // get current page of items
    this.pagedItems = this.poolsItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // this.pagedItems=this.sortOrderForMulityObj(this.pagedItems,'userpoolnumber',this.isAsc)
  }

  // pdflink() {

  //   this.ms.getPDF(this.searchForm.value).subscribe(data => {
  //     console.log(data[0].aclFilename);
  //     window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
  //   });


  // }

  isDesc: boolean = false;
  column: string = '';
  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.pagedItems.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

}
interface Status {
  value: string;
  viewValue: string;
}

