import { Component, Inject, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { MastersService } from "src/app/services/masters.service";
import Swal from "sweetalert2";
import { WorkflowService } from "src/app/services/workflow.service";
import { PaginationService } from "src/app/pagination.service";
import { EncrDecrServiceService } from "src/app/services/encr-decr-service.service";
import { MyCompany, MySACommon } from "src/app/model/systemadmin";
import { Country, DynamicPDF1, MyAgency } from "src/app/model/common";
import { Globals } from "src/app/globals";
import { Title } from "@angular/platform-browser";
import { CustomerContract } from "src/app/model/CustomerContract";
import { templateJitUrl } from "@angular/compiler";
import { DatePipe } from "@angular/common";
// import { DatePipe } from '@angular/common';
declare let $: any;
@Component({
  selector: "app-defineuserpoolsadd",
  templateUrl: "./defineuserpoolsadd.component.html",
  styleUrls: ["./defineuserpoolsadd.component.css"],
})
export class DefineuserpoolsaddComponent implements OnInit {
  // now = new Date();
  myControl = new FormControl("");
  title = "Work flow userpools add ";
  currentTab: any;
  service: any;
  deleteId: any;
  private id: number;
  userpoolnumber: any;
  isEdit: boolean = false;
  minDate: string | null;
  isSave:boolean=false;

  pageSection: number;
  i: number = -1;
  containerList: any = [];
  userPoolList: any = [];
  addnewlist: any = [];
  showAdd = true; // Initially, show the select element
  showEditAdd = false; // Initially, hide the input element
  // changeTab(tabName) {
  //   this.currentTab = tabName;
  // }

  statusvalues: Status[] = [
    { value: "Yes", viewValue: "Yes" },
    { value: "No", viewValue: "No" },
  ];
  aclFilename: DynamicPDF1[];
  addItem: any = { Data: [] };
  ApplicationItem: any = { Data: [] };
  DivisionItem: any = { Data: [] };
  userItem:any = {Data:[]}

  deleteItem: any = { Data: [] };
  poolsItems: any = { Data: [] };
  userpooldetails: any = [];
  eventnumber: any;
  user_id: any;
  createdon: any;
  modifiedon: any;
  modifiedby: any;
  createdby: any;
  division: any;
  officeItem: any = [];

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private ms: MastersService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public ps: PaginationService,
    public wf: WorkflowService,
    private titleService: Title,
    public datePipe: DatePipe,

    private ES: EncrDecrServiceService
  ) {
    this.minDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.poolsForm = this.fb.group({
      id: 0,
      userpoolnumber: "",
      description: "",
      userpoolname: "",
      application: "",
      active: "",
      userpooldetails: "",
    });
    this.usersForm = this.fb.group({
      Id: 0,
      startdate: "",
      enddate: "",
      user: "",
      user_id: 0,
      divisionname: "",
      divisioncode: "",
      division: "",
      officecode: "All",
      officename: "",
      active: true,
      office: "All",
      User_Idv:""
      // userpoolnumber: '',
    });
  }

  // array of all items to be paged
  private allItems: any = { Data: [] };

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  DepartmentList: any = [];
  dataSource: Country[];
  poolsForm: FormGroup;
  usersForm: FormGroup;

  ActivateAddEditDepComp: boolean = false;
  dep: any;

  DepartmentIdFilter: string = "";
  DepartmentNameFilter: string = "";
  DepartmentListWithoutFilter: any = [];

  ngOnInit(): void {
    // this.currentTab = 'basics'
    this.getUser()
    this.titleService.setTitle(this.title);
    $(".my-select").select2();
    $(document).on("select2:open", (e) => {
      const selectId = e.target.id;

      $(
        ".select2-search__field[aria-controls='select2-" +
          selectId +
          "-results']"
      ).each(function (key, value) {
        value.focus();
      });

      $("#ddlApplication").on("select2:select", (e, args) => {
        this.poolsForm.patchValue({ application: $("#ddlApplication").val() });
        console.log($("#ddlApplication").val(), '$("#ddlApplication").val()');
      });

      $("#office").on("select2:select", (e: any) => {
        let office = $("#office").find(":selected").val();
        // let officename = $("#office").find(":selected").val();


        let parts = office.split('-');
          // Get the last index
          let officename = parts.pop();
          // Reconstruct the remaining indexes
          let officecode = parts.join('-');
        // let officecode:any;
        console.log("office", officecode, officename);
        // let office = officecode.split("-");
        this.usersForm?.patchValue({
          office:office,
          officecode: office == 'All'? office:officecode,
          officename: office == 'All'? '' :officename,
        });
      });

      $("#division").on("select2:select", (e: any) => {
        let division = $("#division").find(":selected").val();
        // let divisionname = $("#division").find(":selected").val();
        let parts = division.split('-');
        // Get the last index
        let divisionname = parts.pop();
        // Reconstruct the remaining indexes
        let divisioncode = parts.join('-');
        // let divisioncode:any;
        console.log("division", divisioncode, divisionname, this.division);
        // let division = divisioncode.split("-");
        this.usersForm?.patchValue({
          division:division,
          divisioncode: divisioncode,
          divisionname:divisionname,
        });
      });
    });
    $("#user").on("select2:select", (e: any) => {
      let value = $("#user").find(":selected").val();
      // let find = this.valueItem.find((item) => item.id == value)
      let findUserId = this.userItem.find((item) => item.User_Idv == value)
      // const values = this.approvaltypeItem?.Data.map(item => item.value);
      console.log("findUserId",findUserId.User_Idv)
      this.usersForm.patchValue({
        // value: findUserId.User_Idv,
        user:findUserId.User_Idv,
      });   
    });
    // $('#ddlApplication').on('select2:select', (e, args) => {
    //   this.poolsForm.patchValue({application:$("#ddlApplication").val()})});

    // $('#ddlApplication').on('select2:select', (e, args) => {
    //   this.poolsForm.patchValue({application:($("#ddlApplication").val())});
    // });
    // $('#ddlStatusv').on('select2:select', (e, args) => {
    //   this.poolsForm.patchValue({active:($("#ddlStatusv").val())});
    // });
    this.applicationBinding();
    this.divisionBinding();
    // this.clearSearch()
    this.officeBinding();

    this.route.queryParams.subscribe((params) => {
      console.log("params");
      //EVENTNUMBER ERROR CLEAR CODE
      if (params.userpoolnumber) {
        this.getUserByNumber(params.userpoolnumber);
        this.userpoolnumber = params.userpoolnumber;
      }
    });
  }

  applicationBinding() {
    console.log("application", this.poolsForm.value);
    this.wf.getApplicationList({ type: "application" }).subscribe((data) => {
      this.ApplicationItem = data;
    });
  }

  divisionBinding() {
    console.log("divisionBinding", this.usersForm.value);
    this.wf.getDivisionList({ Id: 0 }).subscribe((data: any) => {
      console.log("divition", data);
      this.DivisionItem = data.data?.Division || [];
    });
  }

  officeBinding() {
    // console.log("office", this.searchForm.value);
    this.wf.getOfficeList({ type: "office" }).subscribe((data: any) => {
      console.log("office", data);
      this.officeItem = data?.data?.Office || [];
    });
  }

  // getUserPoolsList() {
  //   this.wf.getUserPoolsList().subscribe((data: any) => {
  //     if (Array.isArray(data.Data)) {
  //       let userpool = data.Data.filter(val => val.userpoolnumber == this.userpoolnumber)
  //       this.userPoolList = userpool;
  //     }
  //     console.log("getUserPoolsList", data)
  //   });
  // }

  getUserByNumber(userpoolnumber: any) {
    this.wf.getUserListByNumber(userpoolnumber).subscribe((data: any) => {
      // set items to json response
      // this.userPoolList.push(this.usersForm.value)
      // this.addnewlist.push(this.usersForm.value)
      // this.userPoolList = data?.Data;
      console.log("getUserListByNumber", data?.Data[0]);
      if (Array.isArray(data?.Data) && data?.Data.length > 0) {
        this.userPoolList = data?.Data[0]?.userpooldetails;
        this.poolsForm.patchValue({
          ...data?.Data[0],
        });
        $("#ddlApplication").val(data?.Data[0].application).change();

        this.createdon = data?.Data[0].createdon;
        console.log("createdon.", this.createdon);
        this.modifiedon = data?.Data[0].modifiedon;
        this.modifiedby = data?.Data[0].modifiedby;
        this.createdby = data?.Data[0].createdby;
      }
    });
  }

  deleteeUserPools() {
    console.log(
      "this.id",
      this.deleteItem,
      this.user_id,
      this.isEdit || this.i < -1,
      !this.user_id
    );
    if (this.isEdit || this.i <= -1) {
      return;
    }
    if (!this.user_id) {
      this.userPoolList.splice(this.i, 1);
      this.i = -1;
      this.user_id = null;
      this.id = null;
      return;
    }
    let find = this.userPoolList.filter((index) => index.user_id);
    if (find.length == 1) {
      Swal.fire("Cannot be delete the user pool");
      return;
    }
    this.wf.deleteeUserPools({ id: this.user_id }).subscribe((data) => {
      this.deleteItem = data;
      this.ngOnInit();
    });
  }

  onChange(data, i: number) {
    if (this.isEdit) {
      return;
    }
    this.id = data;

    this.user_id = data;
    // this.userpoolnumber = data.Data[0].userpoolnumber;
    this.i = i;
  }

  // clearSearch() {
  //   this.poolsForm.value.application = $('#ddlApplication').val();
  //   // this.createForm()
  //   // this.RuleList();
  //   $('#ddlApplication').val(0).trigger("change");
  // }

  // addUserPoolsList(type:any) {
  //   // if (this.isEdit)
  //   // return
  //   console.log("addUserPoolsList", type);
  //   if(type=='add')
  //   this.userPoolList.push(this.usersForm.value)
  //   else if(type == 'edit'){
  //     console.log('this.usersForm.value', this.usersForm.value);
  //     this.userPoolList[this.i]=this.usersForm.value
  //   }

  //   let data: any = this.poolsForm.value
  //   console.log('this.userPoolList', this.userPoolList)
  //   data['userpooldetails'] = this.userPoolList;
  //   this.usersForm.reset()
  //   this.usersForm.patchValue({ id: 0, userpoolnumber: this.userpoolnumber, user_id: 0 })
  // }
  addUserPoolsList(type: any) {
    if (this.isEdit) return;

    this.isSave=true;
    console.log("addRuleList", this.usersForm.value);
    var validation = "";
    if (this.usersForm.value.user == "" || !this.usersForm.value.user) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter user</span></br>";
    }
    if (
      this.usersForm.value.startdate == "" ||
      !this.usersForm.value.startdate
    ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Start date</span></br>";
    }
    if (this.usersForm.value.enddate == "" || !this.usersForm.value.enddate) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter End date</span></br>";
    }
    if (
      this.usersForm.value.divisioncode == "" ||
      !this.usersForm.value.divisioncode
    ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Divition</span></br>";
    }
    if (
      this.usersForm.value.officecode == "" ||
      !this.usersForm.value.officecode
    ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Office</span></br>";
    }
    if (validation != "") {
      Swal.fire(validation);
      return false;
    }
    // let finddata = this.userPoolList.find((val) => val.user.toLowerCase()?.replace(/\s+/g,'')
    //  == this.usersForm.value?.user.toLowerCase()?.replace(/\s+/g,'') )
    // if(finddata){
    //   Swal.fire("User Name Already Exits")
    //   return
    // }
    let maxValue:any='';
    this.userPoolList.forEach(val => {
      //getting the value from each object and 
      //comparing to existing value
      if( val.divisioncode?.toLowerCase() ==  this.usersForm.value?.divisioncode?.toLowerCase() && 
      val.officecode?.toLowerCase() == this.usersForm.value?.officecode?.toLowerCase() &&
       val.user?.toLowerCase() == this.usersForm.value?.user?.toLowerCase() ){
        if(maxValue=='')
        maxValue=val.enddate
      if(maxValue<val.enddate)
       maxValue =val.enddate;
       console.log('maxValue',maxValue)
       }
    });
    if(maxValue> this.usersForm.value?.enddate){
      Swal.fire('Active only user')
      return 
    }
    console.log('maxValue',maxValue)
    // let finddivitiondata = this.userPoolList.find(
    //   (val) =>
    //     val.divisioncode?.toLowerCase() ==
    //       this.usersForm.value?.divisioncode?.toLowerCase() &&
    //     val.officecode?.toLowerCase() ==
    //       this.usersForm.value?.officecode?.toLowerCase()
    // );
    // if (finddivitiondata) {
    //   Swal.fire("Division and  Office Already Exits");
    //   return;
    // }

    let datas = this.userPoolList[this.i];
    // console.log('data',data)
    let today=new Date();
    today.setHours(0,0,0,0);
    // if(data && new Date(data?.enddate)<today){
    //   Swal.fire(`You can't edit`);
    //   return true;
    // }
    let findactiveuser = this.userPoolList.find((val) => 
    val.divisioncode?.toLowerCase() ==  this.usersForm.value?.divisioncode?.toLowerCase() && 
     val.officecode?.toLowerCase() == this.usersForm.value?.officecode?.toLowerCase() &&
      val.user?.toLowerCase() == this.usersForm.value?.user?.toLowerCase()  
      && new Date (this.minDate).getTime() <=new Date(val?.enddate).getTime());
    
      // &&new Date (this.minDate).getTime() <=new Date(val?.endate).getTime()
      if(findactiveuser){
        console.log(findactiveuser,new Date (this.minDate).getTime() ,new Date(findactiveuser?.enddate).getTime())
        Swal.fire('Active only user')
        return;
      }
    //   let findofficedata = this.userPoolList.find((val) =>
    //   (val.officecode?.toLowerCase()  == this.usersForm.value?.officecode?.toLowerCase()) &&
    //    (val.divisioncode?.toLowerCase()  == this.usersForm.value?.divisioncode?.toLowerCase()) )
    //  if(findofficedata){
    //    Swal.fire("Office Already Exits")
    //    return
    //  }
    
    if (this.isEdit) return;
    console.log("addUserPoolsList", type);
    this.userPoolList.push(this.usersForm.value);
    let data: any = this.poolsForm.value;

    console.log("this.userPoolList", this.userPoolList);
    data["userpooldetails"] = this.userPoolList;
    this.usersForm.reset();
    this.usersForm.patchValue({
      Id: 0,
      id: 0,
      userpoolnumber: this.userpoolnumber,
      user_id: 0,
      user: "",
      divisionname: "",
      divisioncode: "",
      division: "",
      office: "",
      active: true,
      User_Idv:""
    });
    $("#office").val("").change();
    $("#division").val("").change();
    $("#user").val("").change();

  }
  editAdd() {
    if (this.user_id > -1 && this.i > -1 && this.isEdit) {
      var validation = "";
      if (this.usersForm.value.user == "" || !this.usersForm.value.user) {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Enter user</span></br>";
      }
      if (
        this.usersForm.value.startdate == "" ||
        !this.usersForm.value.startdate
      ) {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Enter Start date</span></br>";
      }
      if (this.usersForm.value.enddate == "" || !this.usersForm.value.enddate) {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Enter End date</span></br>";
      }
      if (
        this.usersForm.value.divisioncode == "" ||
        !this.usersForm.value.divisioncode
      ) {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Divition</span></br>";
      }
      if (
        this.usersForm.value.officecode == "" ||
        !this.usersForm.value.officecode
      ) {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Enter Office</span></br>";
      }
      // if (this.usersForm.value?.value?.replace(/\s+/g,'') == "") {
      //   validation +=
      //     "<span style='color:red;'>*</span> <span>Please Enter Value</span></br>";
      // }
      if (validation != "") {
        Swal.fire(validation);
        return false;
      }

      let maxValue:any='';
      let findIndex:number=-1;
      this.userPoolList.forEach((val,index:number) => {
        //getting the value from each object and 
        //comparing to existing value
        if( val.divisioncode?.toLowerCase() ==  this.usersForm.value?.divisioncode?.toLowerCase() && 
        val.officecode?.toLowerCase() == this.usersForm.value?.officecode?.toLowerCase() &&
         val.user?.toLowerCase() == this.usersForm.value?.user?.toLowerCase() &&
          new Date(this.minDate).getTime()<= new Date(val.enddate).getTime()){
        //   if(maxValue=='')
        //   maxValue=val.enddate
        // if(maxValue<val.enddate)
        //  maxValue =val.enddate;
        //  console.log('maxValue',maxValue)
         findIndex = index
         }
         
      });

      // if(maxValue> this.usersForm.value?.enddate){
  
      //   Swal.fire('Active only user')
      //   return 
      // }
      if(findIndex >= 0 &&findIndex != this.i){
  
        Swal.fire('Active only user')
        return 
      }
      console.log('maxValue',maxValue)
      // let findIndex: number = -1;

      // let findDivisionItem = this.userPoolList.find((item, index: number) => {
      //   if (
      //     item.divisioncode?.toLowerCase() ==
      //       this.usersForm.value?.divisioncode?.toLowerCase() &&
      //     item.officecode?.toLowerCase() ==
      //       this.usersForm.value?.officecode?.toLowerCase()
      //   ) {
      //     findIndex = index;
      //     return item;
      //   }
      // });

      // if (
      //   this.userPoolList[this.i].divisioncode?.toLowerCase() !=
      //     this.usersForm.value?.divisioncode?.toLowerCase() ||
      //   this.userPoolList[this.i].officecode?.toLowerCase() !=
      //     this.usersForm.value?.officecode?.toLowerCase()
      // ) {
      //   if (findDivisionItem) {
      //     Swal.fire("Divition & Office Already Exits");
      //     return;
      //   }
      // }
      // else{
      // if(findIndex != this.i && findIndex ){
      // Swal.fire("Divitions Already Exits")
      // return
      // }
      // }

      // let findOfficeItem = this.userPoolList.find((item,index:number) => {
      //   if(item.officecode?.toLowerCase()  == this.usersForm.value?.officecode?.toLowerCase() ){
      //     findIndex = index;
      //     return item
      //   }
      // });

      // if(this.userPoolList[this.i].officecode?.toLowerCase()   != this.usersForm.value?.officecode?.toLowerCase()  ){

      //   if(findOfficeItem){
      //     Swal.fire("Office Already Exits")
      //     return
      //   }

      // }
      // else{
      // if(findIndex != this.i && findIndex ){
      // Swal.fire("Office Already Exits")
      // return
      // }
      // }

      // console.log("loggss", findIndex, findDivisionItem);

      console.log("editAdd", this.userPoolList[this.i]);
      // if(this.usersForm.value.approvertype == 'User Id'){
      //   this.usersForm.patchValue({
      //     value_name:this.usersForm.value.value
      //   })
      // }
      this.isSave=false;
      this.userPoolList[this.i] = this.usersForm.value;
      //DROPDOWN RESET
      // $("#approvertype").val("").change();
      // $("#value").val("").change();
      this.usersForm.reset();
      this.usersForm.patchValue({
        office:'All',
          officecode:'All',
          officename: '',
      })
      this.showAdd = true;
      this.showEditAdd = false;
      this.i = -1;
      this.id = null;
      this.user_id = null;

      this.isEdit = false;
      $("#office").val("All").change();
      $("#division").val("").change();
      $("#user").val("").change();

    }
  }
  editUserPools() {
    let data = this.userPoolList[this.i];
    console.log('data',data)
    let today=new Date();
    today.setHours(0,0,0,0);
    if(data && new Date(data?.enddate)<today){
      Swal.fire(`You can't edit`);
      return true;
    }
    if (this.i < 0) {
      return true;
    }
    this.isEdit = true;
   
    let offieName =
      data?.officecode + (data?.officename ? "-" + data?.officename : "");
    data.office = offieName;
    let divisionname =
      data?.divisioncode + (data?.divisionname ? "-" + data?.divisionname : "");
    data.division = divisionname;
    console.log("editdata", data);
    this.usersForm.patchValue(data);

    //DATE UPPEND CODE
    const startDate = data.startdate.split("T")[0];
    const endDate = data.enddate.split("T")[0];
    this.usersForm.patchValue({
      startdate: startDate,
      enddate: endDate,
    });
    this.showAdd = false;
    this.showEditAdd = true;
          const values = this.userPoolList.map(item => item.user);

    $("#office").val(offieName).change();

    $("#division").val(divisionname).change();
    $("#user").val(data.user).change();
console.log(values)
  }
  getUser(){
    this.wf.getUser().subscribe((data:any) => {
      this.userItem = data?.data?.TrasnportType_list;
      console.log("userItem",data)

     
    });
  }
  onSubmit() {
    if (this.isEdit) {
      var validation = "";
      // if (this.poolsForm.value.userpoolnumber == "") {
      //     validation += "<span style='color:red;'>*</span> <span>Please Enter User Pool Number </span></br>"
      // }
      // if (this.poolsForm.value.application == "") {
      //   validation +=
      //     "<span style='color:red;'>*</span> <span>Please Enter Application</span></br>";
      // }

      if (this.poolsForm.value.active == "") {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Enter Active</span></br>";
      }
      console.log(this.poolsForm.value, "this.poolsForm.value");

      if (validation != "") {
        Swal.fire(validation);
        return false;
      }

      //     let findItem = this.userPoolList.find((item,index:number) => {
      //       if(item.user?.toLowerCase()?.replace(/\s+/g,'')  == this.usersForm.value?.user?.toLowerCase()?.replace(/\s+/g,'') ){
      //         findIndex = index;
      //         return item
      //       }
      //     });

      //     if(this.userPoolList[this.i].user?.toLowerCase()?.replace(/\s+/g,'')   != this.usersForm.value?.user?.toLowerCase() ?.replace(/\s+/g,'') ){

      //       if(findItem){
      //         Swal.fire("User Name Already Exits")
      //         return
      //       }

      //     }
      // else{
      // if(findIndex != this.i && findIndex ){
      //   Swal.fire("User Name Already Exits")
      //   return
      // }
      // console.log("loggss",findIndex,findItem)
      // }

      let data: any = this.poolsForm.value;
      console.log("this.userPoolList", this.userPoolList);
      data["userpooldetails"] = this.userPoolList;
      data["userpooldetails"][this.i] = this.usersForm.value;
      this.wf.addUserPoolsList(data).subscribe((data) => {
        // set items to json response
        // this.poolsItems = data;
        console.log("editUserPools", data);
        console.log("editsave", this.poolsForm.value);
        // initialize to page 1
        // this.setPage(1);

        Swal.fire("update successfully");
        this.router.navigate(
          ["/views/views/difineworkflow/defineuserpools"],
          {}
        );
      });
      this.isEdit = false;
      return;
    }
    // this.poolsForm.value.application = $("#ddlApplication").val();
    var validation = "";
    // if (this.poolsForm.value.userpoolnumber == "") {
    //     validation += "<span style='color:red;'>*</span> <span>Please Enter User Pool Number </span></br>"
    // }
    if (this.poolsForm.value.application == "") {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Application</span></br>";
    }

      if (this.poolsForm.value.userpoolname == "") {
          validation +=
              "<span style='color:red;'>*</span> <span>Please Enter User Pool Name</span></br>";
      }

    if (this.poolsForm.value.active == "") {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Active</span></br>";
    }
    console.log(this.poolsForm.value, "this.poolsForm.value");
    if (validation != "") {
      Swal.fire(validation);
      return false;
    }

    let data: any = this.poolsForm.value;
    console.log("this.userPoolList", this.userPoolList);
    data["userpooldetails"] = this.userPoolList;
    // data['userpooldetails'] = this.addnewlist;
    this.wf.addUserPoolsList(data).subscribe((data:any) => {
      console.log("addsave", data);
      // this.addnewlist=[]
      this.userPoolList = [];
   
        Swal.fire(this.isSave?'Saved successfully':'updated successfully')
     
   
     // Swal.fire(data.Data.length>0?data.Data[0]?.AlertMessage:"Saved successfully");
      this.router.navigate(["/views/views/difineworkflow/defineuserpools"], {});
      //EVENTNUMBER ERROR CLEAR CODE
      if (this.userpoolnumber) {
        this.getUserByNumber(this.userpoolnumber);
      }
      this.usersForm.reset();
      //MULTIPLE ADD CODE
      this.usersForm.patchValue({
        id: 0,
        userpoolnumber: this.userpoolnumber,
        user_id: 0,
        user: "",
      });
    });
  }

  public selectedName: any;
  public highlightRow(dataItem) {
    this.selectedName = dataItem.StateCode;
  }
  setPage(page: number) {
    //if (page < 1 || page > this.pager.totalPages) {
    //    return;
    //}

    // get pager object from service
    this.pager = this.ps.getPager(this.poolsItems.length, page);

    // get current page of items
    //this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  // pdflink() {

  //   this.ms.getPDF(this.searchForm.value).subscribe(data => {
  //     console.log(data[0].aclFilename);
  //     window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
  //   });

  // }

  isDesc: boolean = false;
  column: string = "";
  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.pagedItems.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
}
interface Status {
  value: string;
  viewValue: string;
}
