

import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import Swal from 'sweetalert2';
import { WorkflowService } from 'src/app/services/workflow.service';
import { PaginationService } from 'src/app/pagination.service';
import { EncrDecrServiceService } from 'src/app/services/encr-decr-service.service';
import { MyCompany, MySACommon } from 'src/app/model/systemadmin';
import { Country, DynamicPDF1, MyAgency } from 'src/app/model/common';
import { Globals } from 'src/app/globals';
import { Title } from '@angular/platform-browser';
import { CustomerContract } from 'src/app/model/CustomerContract';
import { SystemadminService } from 'src/app/services/systemadmin.service';

declare let $: any;
@Component({
  selector: 'app-workflowrules',
  templateUrl: './workflowrules.component.html',
  // styleUrls: ['./workflowrules.component.css']
})
export class WorkflowrulesComponent implements OnInit {

  myControl = new FormControl('');
  title = 'Work flow rules ';
  currentTab: any;
  deleteId: any;
  private id: number;
  eventnumber: any;
  complianceRowId: number;
  pageSection: number;
  i: number = -1;
  containerList: any = [];
  addrulelist: any = [];
  addnewlist: any = [];

  addparameterlist: any = [];
  addapprovallist: any = [];
  yes: any;
  tabsForm: FormGroup;
  isEdit: boolean = false;
  isAsc: boolean;

  // changeTab(tabName) {
  //   this.currentTab = tabName;
  // }

  // statusvalues: Status[] = [
  //   { value: '1', viewValue: 'Yes' },
  //   { value: '2', viewValue: 'No' },
  // ];
  aclFilename: DynamicPDF1[];
  dscountryItem: any = { Data: [] };
  addItem: any = { Data: [] };
  ApplicationItem: any = { Data: [] };
  officeItem: any =[];
  datatypeItem: any = { Data: [] };
  mandatoryItem: any = { Data: [] };
  validationItem: any = { Data: [] };
  param1Item: any = { Data: [] };
  operatorItem: any = { Data: [] };
  approvalItem: any = { Data: [] };
  approvaltypeItem: any = { Data: [] };
  validationtypeItem: any = { Data: [] };

  deleteItem: any = { Data: [] };
  parameterItems: any = { Data: [] };
  encryptedParam: any;
  office: any;
  newlist: any;
  createdon: any;
  modifiedon: any;
  modifiedby: any;
  createdby: any;
  eventname:string;
  appName:string;

  constructor(private http: HttpClient, private globals: Globals,
    private ms: MastersService, private router: Router,
    private route: ActivatedRoute, private fb: FormBuilder,
    public ps: PaginationService, public wf: WorkflowService, private titleService: Title
    , private ES: EncrDecrServiceService, private service: MastersService,
    private sa: SystemadminService,) {


    this.searchForm = this.fb.group(
      {
        id: 0,
        eventnumber: '',
        rulenumber: '',
        priority: 0,
        description: '',
        rulename: '',
        officecode:"All",
        officename:"",
        office: 'All',
        active: 0,
        // value: '',
        // type: '',
      });
    this.route.queryParams.subscribe(params => {
      let eventNumber = this.ES.get(localStorage.getItem("EncKey"), params.encrypted)
      this.encryptedParam = params.encrypted;
      console.log('params', params.encrypted, eventNumber)
      let eventparse = JSON.parse(eventNumber)
      console.log('this.eventnumber?.eventnumber',eventparse?.eventnumber);
      this.searchForm.patchValue({
        eventnumber: eventparse?.eventnumber
      })
      this.tabsForm = this.fb.group({
        ID: eventNumber,

      });

    });

  }

  // array of all items to be paged
  private allItems: any = { Data: [] };

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];
  private allItemsRules: any = { Data: [] };

  // pager object
  pagerRules: any = {};

  // paged items
  pagedItemsRules: any[];
  private allItemsParams: any = { Data: [] };

  // pager object
  pagerParams: any = {};

  // paged items
  pagedItemsParams: any[];
  currentPage=1;


  DepartmentList: any = [];
  dataSource: Country[];
  searchForm: FormGroup;


  basicForm: FormGroup;
  ActivateAddEditDepComp: boolean = false;
  dep: any;

  DepartmentIdFilter: string = "";
  DepartmentNameFilter: string = "";
  DepartmentListWithoutFilter: any = [];

  ngOnInit(): void {
    this.isAsc=false
    // this.currentTab = 'basics'
    this.titleService.setTitle(this.title);
    $('.my-select').select2();
    $(document).on('select2:open', (e) => {
      const selectId = e.target.id

      $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
        key,
        value,
      ) {
        value.focus();
      })
    })
    // $('#ddlApplication').on('select2:select', (e, args) => {
    //   this.ApplicationItem($("#ddlApplication").val());
    // });


    // this.getDefineList();

    this.clearSearch();
    this.getRuleList()
    this.officeBinding();
    // this.officeType()
    // this.addeventList();
    //  this.addRuleList();
    $('#office').on("select2:select", (e: any) => {
      let office = $('#office').find(":selected").val()
      // let officename = $('#office').find(":selected").val()

      // let officecode:any;
      // console.log('office', officecode,officename)
      // let office=officecode.split('-');
      // this.searchForm?.patchValue({
      //   // office: office,
      //   officecode:office[0],
      //    officename:office.length>1?office[1]:''
      // })
      let parts = office.split('-');
      // Get the last index
      let officename = parts.pop();
      // Reconstruct the remaining indexes
      let officecode = parts.join('-');
    // let officecode:any;
    console.log("office", officecode, officename);
    // let office = officecode.split("-");
    this.searchForm?.patchValue({
      office:office,
      officecode: office == 'All'? office:officecode,
      officename: office == 'All'? '' :officename,
    });
    })
    this.route.queryParams.subscribe(
      params => {
        let eventNumber = this.ES.get(localStorage.getItem("EncKey"), params.encrypted)

        console.log('params', params.encrypted, eventNumber)
        // this.getEventByNumber(params.eventNumber)
        this.eventnumber = JSON.parse(eventNumber)
        this.getRuleByNumber( this.eventnumber?.eventnumber)
        // this.eventnumber = eventNumber
        this.getEventByNumber(this.eventnumber?.eventnumber);

      }
    )
    $('#active').on("select2:select", (e: any) => {
      let active = $('#active').find(":selected").val()
      console.log('active', active)
      this.searchForm.patchValue({
        active: active
      })
    })


  }
//   moveUpCompliance() {
//     if (this.i > 0 && this.i < this.addrulelist.length) {
//         const element = this.addrulelist[this.i];
//         this.addrulelist.splice(this.i, 1);
//         this.i = this.i - 1; // Decrement the index before moving
//         this.addrulelist.splice(this.i, 0, element); // Move to the previous index
//         this.pageSection = Math.floor(this.i / 10) + 1;
//         this.setPage(this.pageSection);
//         // this.isSaveComplianceBtn = false;
//     }
// }
  
  moveUpCompliance() {
    if(this.i<0 || this.isEdit){
      return true
    }
    console.log("iiiiiiiU", this.i);
    if (this.i > 0) {
      // console.log("index",this.i);

      const element = this.addrulelist[this.i];
      this.addrulelist.splice(this.i, 1)
      this.addrulelist.splice(this.i - 1, 0, element);
      this.i = this.i - 1;
      this.pageSection = Math.floor((this.i) / 10) + 1;
      console.log("pageSection",this.pageSection);
      
     
      this.setPage(this.pageSection);
      // this.isSaveComplianceBtn = false;
    }
  }
  
 
  moveDownCompliance() {
    if(this.i<0 || this.isEdit){
      return true
    }
    console.log("iiiiiiiD", this.i);
    if (this.i < this.addrulelist.length - 1 ) { //&& this.i > -1
      const element = this.addrulelist[this.i];
      this.addrulelist.splice(this.i, 1)
      this.addrulelist.splice(this.i + 1, 0, element);
      this.i = this.i + 1;
      this.pageSection = Math.floor((+this.i) / 10) + 1;
      this.setPage(this.pageSection);
      // this.isEditBtn = false
    }
    console.log("iiiiiiiD2", this.i);
  }

 


  deleteRule() {
    if(this.i <= -1 || !this.id || this.isEdit ){
      return this.isEdit = false;
          }
    console.log("this.id", this.deleteItem)
    this.wf.AttachDelete({ id: this.id }).subscribe(data => {
      this.deleteItem = data;
      Swal.fire('Deleted successfully');
      // this.getRuleByNumber(this.eventnumber)
    });
  }
  getEventByNumber(eventNumber: any) {
    this.wf.getDefineListByNumber(eventNumber).subscribe((data: any) => {
      // set items to json response
      console.log("getDefineListByNumber", data?.Data[0]);

      if (Array.isArray(data?.Data) && data?.Data.length > 0) {
        // this.basicForm.patchValue({
        //   ...data?.Data[0],
        // });
        this.eventname =  data?.Data[0].eventname
        this.appName =  data?.Data[0].application
        // $("#ddlApplication").val(data?.Data[0].application).change();
        //popover dynamicdata
        // this.dynamicContent = ` createdby: ${data?.Data[0].createdby}
        // createdon: ${data?.Data[0].createdon}
        // modifiedby: ${data?.Data[0].modifiedby}
        // modifiedon: ${data?.Data[0].modifiedon}`;
        this.createdon = data?.Data[0].createdon;
        this.modifiedon = data?.Data[0].modifiedon;
        this.modifiedby = data?.Data[0].modifiedby;
        this.createdby = data?.Data[0].createdby;
      }
    });
  }
  getRuleByNumber(eventNumber: any) {
    console.log('getRuleByNumber', eventNumber)
    this.wf.getRuleByNumber(eventNumber).subscribe((data: any) => {
      // set items to json response
      console.log('getRuleByNumber', data);
      this.addrulelist = data?.Data;
      this.sortOrderForMulityObj(this.addrulelist,'priority',this.isAsc = true)

      
      //UPDOWN CODE
      this.addrulelist.sort(function (a, b) {
        return a.priority < b.priority ? -1 : 1;
      });
      // this.addrulelist.push(this.searchForm.value)
      // this.addnewlist.push(this.searchForm.value)
      // this.allItemsRules = data;
      // if(Array.isArray(data?.Data) && data?.Data.length>0){
      //   this.basicForm.patchValue({
      //     ...data?.Data[0]

      //   })
      // }
    });
  }

  officeBinding() {
    console.log("office", this.searchForm.value);
    this.wf.getOfficeList({ "type": "office" }).subscribe((data:any) => {
      console.log('data',data)
      this.officeItem = data?.data?.Office   || []  ;
    });

  }

  onChange(data, i: number) {
    if(this.isEdit){
      return
    }
    this.id = data;
    this.i = i;
  }

  clearSearch() {
    this.searchForm.value.application = $('#ddlApplication').val();
    // this.createForm()
    // this.RuleList();
    $('#ddlApplication').val(0).trigger("change");

  }
  
  public sortOrderForMulityObj(arrObj: Array<any>, field: any, isAsc:any) {

    console.log('field',arrObj, field,isAsc)
    this.isAsc=!isAsc
   //  arrObj=arrObj.length>0?arrObj:this.addrulelist;
     this.addrulelist.sort((a, b) => {
      let first: any;
      let sec: any;
      let fieldArr: any = field.split('.');
      for (let i = 0; i < fieldArr.length; i++) {
        a = a[fieldArr[i]];
        b = b[fieldArr[i]]
      }
      if(a){
      first=a;
      sec=b;
      }
      //console.log('first',first)
      if (typeof first === 'string' || first instanceof String) {
        first = first.toLocaleLowerCase(); sec = sec.toLocaleLowerCase();
      }
      if (isAsc)
        return first < sec ? -1 : first > sec ? 1 : 0;
      else
        return first > sec ? -1 : first < sec ? 1 : 0;

      //return  this.isAsc?res:!res
    })
    this.setPage(this.currentPage)
    //this.sortOrderForMulityObj(this.pagedItems,'eventname',this.isAsc)
    //return this.pagedItems;
  }
  


  getRuleList() {
    this.wf.getRuleList().subscribe((data: any) => {

      if (Array.isArray(data.Data)) {
        let rule = data.Data.filter(val => val.eventnumber == this.eventnumber?.eventnumber)
        this.addrulelist = rule;
        //UP DOWN ISSUE  CODE
        // setTimeout(()=>{
          this.sortOrderForMulityObj(this.addrulelist,'priority',this.isAsc)
        // },400)
        

        // this.addrulelist.sort(function (a, b) {
        //   return a.priority < b.priority ? -1 : 1;
        // });
        //this.setPage(1)
      }

      console.log("getRuleList", this.addrulelist,data);
      // initialize to page 1
      this.setPage(1);
    },error => {
      this.addrulelist = []
      this.setPage(1);
    });
  }

  editRuleList() {
 //RADIO BTN NO  SELECT EDIT NOT WORKING CODE
//     if(this.i <=-1 || !this.id ){
//       console.log("editrules",this.i,this.id)
// return this.isEdit = false;

//     }
if (this.i < 0) {
  return true;
}
    this.isEdit = true;
    let data = this.addrulelist[this.i]
    let offieName = data?.officecode +  (data?.officename ?  "-" + data?.officename : '')
    data.office = offieName
console.log("data222",data)
    this.searchForm.patchValue(data)
    console.log('editrulelist', data);
    //DROPDOWN UPEND CODE
    // $('#active').val(data?.active ? 'Yes' : 'No' || '0').change();
    $('#office').val(offieName).change()

    $('#active').val(this.addrulelist[this.i].active).change()

  }
  // newLst(){
  //   console.log("newLst", this.searchForm.value);


  // }
  addRuleList() {
    if (this.isEdit)
      return
    console.log("addRuleList", this.searchForm.value);
    var validation = "";
    if (this.searchForm.value?.rulename?.replace(/\s+/g,'') == "" || !this.searchForm.value.rulename) {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Rule Name</span></br>"
    }
    if (this.searchForm.value?.description?.replace(/\s+/g,'') == "" || !this.searchForm.value.description) {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Description</span></br>"
    }
    if (this.searchForm.value.officecode == "" || !this.searchForm.value.officecode) {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Office</span></br>"
    }
    if (this.searchForm.value.active == "" || !this.searchForm.value.active) {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Active </span></br>"
    }

    if (validation != "") {
      Swal.fire(validation)
      return false;
    }

    let finddata = this.addrulelist.find((val) => val.rulename.toLowerCase()?.replace(/\s+/g,'')  == this.searchForm.value?.rulename.toLowerCase()?.replace(/\s+/g,'') )
  if(finddata){
    Swal.fire("Param Name Already Exits")
    return
  }
    this.addrulelist.push(this.searchForm.value)
    this.setPage(this.pager.endPage?this.pager.endPage:1);
    console.log("this.pager.endPage",this.pager.endPage);
    
    // this.addnewlist.push(this.searchForm.value)
    this.searchForm.reset()
    //MULTIPLE ADD CODE + ADD VALIDATION
    this.searchForm.patchValue({ office:'All',officecode:'All', officename:'',id: 0, eventnumber: this.eventnumber?.eventnumber, priority: 0, rulenumber: '', active: 0 ,rulename:'',description:'',})
    //dropdown reset code
    $('#office').val('All').change()
    $('#active').val('0').change()
  }

  onSubmit(type: any) {
    
    if(this.addrulelist.lenght == 0){
      return
    }
    let data: any = this.searchForm.value;
    let root=this;

    // if (type == 'edit')
    
    if (this.isEdit) {
      //DUPLICATE CHECKING CODE
      let findIndex :number = -1;
      let findItem = this.addrulelist.find((item,index:number) => {
        if(item.rulename?.toLowerCase()  == this.searchForm.value?.rulename?.toLowerCase() ){
          findIndex = index;
          return item
        }
      });
      if(this.addrulelist[this.i].rulename?.toLowerCase()?.replace(/\s+/g,'')   != this.searchForm.value?.rulename?.toLowerCase() ?.replace(/\s+/g,'') ){
       
        if(findItem){
          Swal.fire("Param Name Already Exits")
          return
        }

      }
else{
  if(findIndex != this.i && findIndex ){
    Swal.fire("Param Name Already Exits")
    return
  }
  console.log("loggss",findIndex,findItem)
}
// return
      //RADIO BTN NO  SELECT EDIT NOT WORKING CODE
      if(this.i < 0 || this.searchForm.invalid){
        return 
            }
       console.log(this.searchForm.value,"rexcord")
      this.wf.addWorkFlowview([this.searchForm.value]).subscribe(data => {
        this.getRuleByNumber(this.eventnumber?.eventnumber)

        // set items to json response
        this.allItemsRules = data;
        console.log("editRule", data);
        // initialize to page 1
        // this.setPage(1);
        this.i = -1;
        this.id =null;
        //dropdown reset code
        $('#office').val('All').change()
        $('#active').val('0').change()
        this.searchForm.reset()
        Swal.fire('Update successfully');
        this.searchForm.patchValue({ office:'All',officecode:'All', officename:'',id: 0, eventnumber: this.eventnumber?.eventnumber, priority: 0, rulenumber: '', active: 0 ,rulename:'',description:'',})

       this.getRuleList();

      });
      this.isEdit = false;
      return;
    }

    // else if (type == 'add')
    data = [];
    //UPDOWN CODE
    this.addrulelist.map((val, i) => {
      if(!val.id){
        val.id = 0
      }
      val.eventnumber =  this.eventnumber?.eventnumber
      data.push({ ...val, priority: i + 1 })
    })
    // data = this.addnewlist;
    this.wf.addWorkFlowview(data).subscribe((data: any) => {
      console.log('save', data);
      // this.addnewlist=[]
      this.i = -1;
      this.id =null;
      this.addrulelist = []
      Swal.fire('Saved successfully');
      // this.router.navigate(['/views/difineworkflow/workflowconditions'], {});
    //   Swal.fire({
    //     title: "",
    //     text: 'Saved successfully',       
    // }).then(function() {
    //   console.log('root.searchForm',root.searchForm.value)
    //   if(root.searchForm.value.id==0)
    //    window.location.reload();
    //   //  this.getRuleByNumber(root.searchForm?.value?.eventnumber)
    // });
      this.getRuleByNumber(this.eventnumber?.eventnumber)
      this.searchForm.reset()
      $('#office').val('All').change()
      $('#active').val('0').change()
      //MULTIPLE ADD CODE
      this.searchForm.patchValue({office:'All',officecode:'All', officename:'', id: 0, eventnumber: this.eventnumber?.eventnumber, priority: 0, rulenumber: '', active: 0 })
      // this.getRuleList();
    });
 

  }

  public selectedName: any;
  public highlightRow(dataItem) {
    this.selectedName = dataItem.CountryCode;
  }
  setPage(page: number) {
    //if (page < 1 || page > this.pager.totalPages) {
    //    return;
    //}

    // get pager object from service
    this.pager = this.ps.getPager(this.addrulelist.length, page);
    this.currentPage=page;
    // get current page of items
    this.pagedItems = this.addrulelist.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // this.pagedItems=this.sortOrderForMulityObj(this.pagedItems,'rulenumber',this.isAsc)
  }

  pdflink() {

    this.ms.getPDF(this.searchForm.value).subscribe(data => {
      console.log(data[0].aclFilename);
      window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
    });

    //alert('hi');
    //this.http.get(this.globals.APIURL + '/home/BLPrintPDF/').subscribe(res => {

    //   });


    /*window.open(RouterLink("BLPrintPDF", "BLPrint")');*/

    /*window.open("assets/pdfFiles/test.pdf");*/
  }

  //openpdf() {
  //    $('#pdfview').modal('show');
  //}

  isDesc: boolean = false;
  column: string = '';
  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.pagedItems.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  changeTab(tab) {


    var values = "ID: " + this.tabsForm.value.ID;
    //var values = "ID: 8";
    var encrypted = this.encryptedParam;//this.ES.set(localStorage.getItem("EncKey"), values);
    if (tab == 1) {

      this.router.navigate(['/views/difineworkflow/basicdetails'], { queryParams: { encrypted } });
    }
    else if (tab == 2) {

      this.router.navigate(['/views/difineworkflow/workflowrules'], { queryParams: { encrypted } });

    }
    else if (tab == 3) {

      this.router.navigate(['/views/difineworkflow/workflowparameters'], { queryParams: { encrypted } });

    }

    else if (tab == 4) {

      this.router.navigate(['/views/difineworkflow/workflowconditions'], { queryParams: { encrypted } });

    }

  }

}
interface Status {
  value: string;
  viewValue: string;
}


