import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ReportPermission } from 'src/app/model/reportpermission';
//import { GeneralMaster } from 'src/app/model/common';
import { Globals } from '../globals';


@Injectable({
  providedIn: 'root'
})
export class ReportpermissionService {
    payload: any = "";
    constructor(private http: HttpClient, private globals: Globals) { }

    SaveReportPermission(OL: ReportPermission): Observable<ReportPermission[]> {

        return this.http.post<any>(this.globals.APIURL + '/ReportPermission/ReportPermission/', OL);
    }

    GetReportPermssionView(OL: ReportPermission): Observable<ReportPermission[]> {

        return this.http.post<any>(this.globals.APIURL + '/ReportPermission/ReportPermissionView/', OL);
    }

    GetReportPermssionExistingView(OL: ReportPermission): Observable<ReportPermission[]> {

        return this.http.post<any>(this.globals.APIURL + '/ReportPermission/ReportPermissionExistingBind/', OL);
    }
  
}
