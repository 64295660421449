import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Globals } from '../globals';
import { MyAgency } from '../model/common';
import { CustomerContract } from '../model/CustomerContract';
import { MySACommon } from '../model/systemadmin';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  officeBinding(arg0: {}) {
    throw new Error('Method not implemented.');
  }
  getoffice(arg0: {}) {
    throw new Error('Method not implemented.');
  }
  getofficeType(arg0: {}) {
    throw new Error('Method not implemented.');
  }



  constructor(private http: HttpClient, private globals: Globals) { }
  public authToken = "";

  loadToken() {
    const token = localStorage.getItem('Token');
    this.authToken = token;
  }
  createAuthHeader() {
    this.loadToken();
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.authToken}`
    );
    return { headers };
  }

  ///////workflowRule-Page

  addWorkFlowview(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowrule/add', OL);
  }

  // getOfficeList(OL: any): Observable<any[]> {
  //   return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  // }
  getOfficeList(OL: any): Observable<any[]> {
    
    return this.http.post<any[]>(this.globals.APIURLFN + '/Office/GetOrganizationOfficeList', OL)
  }

  getRuleList(): Observable<MyAgency[]> {

    return this.http.get<MyAgency[]>(this.globals.APIURL + '/Workflowrule');
  }


  editRule(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowrule/edit', OL);
  }

  getRuleByNumber(eventNumber): Observable<MyAgency[]> {
    console.log('eventnumber', eventNumber);

    return this.http.get<MyAgency[]>(this.globals.APIURL + '/Workflowrule/' + eventNumber);
  }


  AttachDelete(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowrule/delete', OL);
  }

  /////WorkFlowEvent-Page

  addWorkFlowevent(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowevent/add', OL);
  }

  getSearchList(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowevent/search', OL);
  }

  getDefineList(): Observable<MyAgency[]> {

    return this.http.get<MyAgency[]>(this.globals.APIURL + '/Workflowevent');
  }

  getDefineListByNumber(eventNumber: any): Observable<MyAgency[]> {

    return this.http.get<MyAgency[]>(this.globals.APIURL + '/Workflowevent/' + eventNumber);
  }

  getApplicationList(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }


  /////WorkFlowParameters-Page
  getParameterList(): Observable<any[]> {

    return this.http.get<any[]>(this.globals.APIURL + '/Workflowparameters');
  }

  addParameter(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowparameters/add', OL);
  }

  editParameter(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowparameters/edit', OL);
  }

  getParamByNumber(eventnumber): Observable<any[]> {

    return this.http.get<any[]>(this.globals.APIURL + '/Workflowparameters/' + eventnumber);
  }

 

  paramsDelete(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowparameters/delete', OL);
  }

  getDataTypeList(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

  getMandatoryList(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }
  
  getValidationList(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }


  /////WorkFlowOrg-Roles-Page


  //  getParamByNumber(eventNumber): Observable<any[]> {
  //     return this.http.get<any[]>(this.globals.APIURL + '/Workfloworgrole');
  //   }
  getRoleList(): Observable<any[]> {

    return this.http.get<any[]>(this.globals.APIURL + '/Workfloworgrole');
  }
  addRoleList(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workfloworgrole/add', OL);
  }

  editRoleList(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workfloworgrole/edit', OL);
  }

  deleteRole(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workfloworgrole/delete', OL);
  }

  /////WorkFlow-Define USer bools-page
  getSearchPool(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowuserpool/search', OL);
  }

  getDivisionList(OL: any): Observable<any[]> {
    
    return this.http.post<any[]>(this.globals.APIURLFN + '/Division/GetOrganizationDivisionsList', OL)
  }

  getUserPoolsList(): Observable<any[]> {

    return this.http.get<any[]>(this.globals.APIURL + '/Workflowuserpool');
  }

  getUserListByNumber(userpoolnumber): Observable<any[]> {

    return this.http.get<any[]>(this.globals.APIURL + '/Workflowuserpool/' + userpoolnumber);
  }

  addUserPoolsList(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowuserpool/add', OL);
  }


  editUserPools(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowuserpool/edit', OL);
  }

  deleteeUserPools(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowuserpool/deleteuser', OL);
  }

   /////WorkFlow-CONDITIONS-page
  //  https://odaksaqa.odaksolutions.in/ffddev/api/Login/GetActiveUsers
  getUser(): Observable<MyAgency[]> {

    return this.http.get<MyAgency[]>(this.globals.APIURLUSER + '/Login/GetActiveUsers' );
  }

   getParam1(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

  getParam2(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

   getRuleNumber(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

  getOperator(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

  getValue(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

  getApproval(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

  getApprovaltype(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

  getValidationtype(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/WorkflowConfiguration', OL);
  }

  getcondition(): Observable<MyAgency[]> {

    return this.http.get<MyAgency[]>(this.globals.APIURL + '/Workflowcondition');
  }

  getconditionByNumber(eventNumber: any): Observable<MyAgency[]> {

    return this.http.get<MyAgency[]>(this.globals.APIURL + '/Workflowcondition/' + eventNumber);
  }

  addWorkFlowcondition(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowcondition/add', OL);
  }

  editCondition(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowcondition/edit', OL);
  }

  deleteapproval(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowcondition/Deleteuser', OL);
  }

  deletehole(OL: any): Observable<any[]> {
    return this.http.post<any[]>(this.globals.APIURL + '/Workflowcondition/delete', OL);
  }

  getOneRule(eventnumber: any): Observable<any[]> {

    return this.http.get<any[]>(this.globals.APIURL + "/Workflowcondition/Getrules/" + eventnumber)
  }   
  
  /////Workbench-page
     getSearchBench(OL: any): Observable<any[]> {
      return this.http.post<any[]>(this.globals.APIURL + '/WorkflowWorkbench/search', OL);
    }

    getProgressList(OL: any): Observable<any[]> {
      return this.http.post<any[]>(this.globals.APIURL + '/WorkflowWorkbench/Progress', OL);
    }

    forwardUser(OL: any): Observable<any[]> {
      return this.http.post<any[]>(this.globals.APIURL + '/WorkflowWorkbench/Forwarduser', OL);
    }

}

