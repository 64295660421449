import { Component, Inject, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { MastersService } from "src/app/services/masters.service";
import Swal from "sweetalert2";
import { WorkflowService } from "src/app/services/workflow.service";
import { PaginationService } from "src/app/pagination.service";
import { EncrDecrServiceService } from "src/app/services/encr-decr-service.service";
import { MyCompany, MySACommon } from "src/app/model/systemadmin";
import { Country, DynamicPDF1, MyAgency } from "src/app/model/common";
import { Globals } from "src/app/globals";
import { Title } from "@angular/platform-browser";
import { CustomerContract } from "src/app/model/CustomerContract";
import { group } from "console";
import { Log } from "oidc-client";
// import { MyCompany } from '../../model/systemadmin';
// import { PaginationService } from '../../pagination.service';
// import { SystemadminService } from '../../services/systemadmin.service';
// import { EncrDecrServiceService } from '../../services/encr-decr-service.service';
declare let $: any;

@Component({
  selector: "app-workflowconditions",
  templateUrl: "./workflowconditions.component.html",
  styleUrls: ["./workflowconditions.component.css"],
})
export class WorkflowconditionsComponent implements OnInit {
  myControl = new FormControl("");
  title = "Work flow conditions ";
  currentTab: any;
  service: any;
  deleteId: any;
  private id: number;
  eventnumber: any;
  complianceRowId: number;
  pageSection: number;
  approvalpageSection: number;
  i: number = -1;
  approvalid : any;
  approvali : number = -1;
  containerList: any = [];
  addrulelist: any = [];
  addparameterlist: any = [];
  addapprovallist: any = [];
  tabsForm: FormGroup;
  encryptedParam: any;
  isEdit: boolean = false;
  isApprovalEdit: boolean = false;


  addnewlist: any = [];
  currentPage = 1;
  // changeTab(tabName) {
  //   this.currentTab = tabName;
  // }

  statusvalues: Status[] = [
    { value: "1", viewValue: "Yes" },
    { value: "2", viewValue: "No" },
  ];
  aclFilename: DynamicPDF1[];
  dscountryItem: any = { Data: [] };
  addItem: any = { Data: [] };
  ApplicationItem: any = { Data: [] };
  officeItem: any = { Data: [] };
  datatypeItem: any = { Data: [] };
  mandatoryItem: any = { Data: [] };
  validationItem: any = { Data: [] };
  param1Item: any = { Data: [] };
  param2Item: any = { Data: [] };
  operatorItem: any = { Data: [] };
  approvalItem: any = { Data: [] };
  approvaltypeItem: any = { Data: [] };
  approvaltypeItem1: any = { Data: [] };
  approvaltypeItem2: any = { Data: [] };

  validationtypeItem: any = { Data: [] };
  userItem:any = {Data:[]}
  valueItem: any = { Data: [] };
  rulenumberItem: any = { Data: [] };
  basicForm: FormGroup;
  deleteItem: any = { Data: [] };
  // deleteItems: any = { Data: [] };

  parameterItems: any = { Data: [] };
  approvalForm: FormGroup;
  resetForm: FormGroup;
  surrenderList: any;
  newlist: any = [];
  addholelist: any;
  user_id: any;
  onerulelist: any = { Data: [] };
  eventNumber: any;
  createdon: any;
  modifiedby: any;
  createdby: any;
  modifiedon: any;
  eventname: string;
  appName: string;
  paramonelist: any = [];
  paramtwolist: any = [];
  allOperatorList:any = []
  isDisableParams:boolean=false;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private ms: MastersService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public ps: PaginationService,
    public wf: WorkflowService,
    private titleService: Title,
    private ES: EncrDecrServiceService
  ) {
    this.conditionForm = this.fb.group({
      id: 0,
      eventnumber: "",
      rulenumber: "",
      conditionoperator: "",
      conditionapprover: "",
      approval_level: "",
      validationtype: "",
      conditionid: 0,
      conditionname: "",
      // conditionnumber: '',
      conditiontype: "True",
      value_to: "",
      value_from: "",
      param2: "",
      param1: "",
      operators: "",
      validation_type: "",
      rulename: "",
    });
    this.conditionForm.get("param2").disable();

    this.approvalForm = this.fb.group({
      user_id: 0,
      step: 0,
      approvertype: "",
      value: "",
      value_name:"",
      User_Idv: ""
    });

    // this.newList();
this.loadEncrptParam();
   
  }
  loadEncrptParam(){
    this.route.queryParams.subscribe((params) => {
      let eventNumber = this.ES.get(
        localStorage.getItem("EncKey"),
        params.encrypted
      );
      this.encryptedParam = params.encrypted;
      console.log("params", params.encrypted, eventNumber);
      let eventparse = JSON.parse(eventNumber);
      console.log("this.eventnumber?.eventnumber", eventparse?.eventnumber);
      this.conditionForm.patchValue({
        eventnumber: eventparse?.eventnumber,
      });
      this.tabsForm = this.fb.group({
        ID: eventNumber,
      });
    });

    this.route.queryParams.subscribe((params) => {
      let eventNumber = this.ES.get(
        localStorage.getItem("EncKey"),
        params.encrypted
      );
      console.log("params", params.encrypted, eventNumber);
      // this.getconditionByNumber(eventNumber)
      this.eventnumber = JSON.parse(eventNumber);

      // this.eventnumber = eventNumber
      this.getEventByNumber(this.eventnumber?.eventnumber);
      this.getcondition();
      this.getOneRule();
      this.getRuleList();
    });
  }

  // array of all items to be paged
  private allItems: any = { Data: [] };

  // pager object
  pager: any = {};
  
  appprovalpager :any = {}
  approvalcurrentPage = 1;
  // get current page of items
  approvalpagedItems : any[];
  // paged items
  pagedItems: any[];
  private allItemsRules: any = { Data: [] };
  // pager object
  pagerRules: any = {};
  // paged items
  pagedItemsRules: any[];
  private allItemsParams: any = { Data: [] };
  // pager object
  pagerParams: any = {};
  // paged items
  pagedItemsParams: any[];
  DepartmentList: any = [];
  dataSource: Country[];
  conditionForm: FormGroup;
  // conditionForm: FormGroup;
  ActivateAddEditDepComp: boolean = false;
  dep: any;

  DepartmentIdFilter: string = "";
  DepartmentNameFilter: string = "";
  DepartmentListWithoutFilter: any = [];
  // readonly=false;
  showSelect = true; // Initially, show the select element
  showInput = false; // Initially, hide the input element

  showAdd = true; // Initially, show the select element
  showEditAdd = false; // Initially, hide the input element
  valued: any;
  // orgList:any=[];
  poolList:any=[];
  ngOnInit(): void {
    $("#inputFromValue").show();
    // this.currentTab = 'basics'

    $("#conditiontype").val("True").change();

    this.titleService.setTitle(this.title);
    $(".my-select").select2();
    $(document).on("select2:open", (e) => {
      const selectId = e.target.id;

      $("#rulenumber").on("select2:select", (e: any) => {
        let rulenumber = $("#rulenumber").find(":selected").val();

        let rule = this.addrulelist.filter(
          (val) => val.rulenumber == rulenumber
        );

        console.log("rulenumber", rulenumber, rule);
        this.conditionForm.patchValue({
          rulenumber: rulenumber,
          rulename: rule.length > 0 ? rule[0]?.rulename : "",
        });
        // this.getOneRule()
      });

      $("#validation_type").on("select2:select", (e: any) => {
        let validation_type = $("#validation_type").find(":selected").val();
        if (validation_type && validation_type.includes("Form Values")) {
          this.conditionForm.get("param2").enable();
          $("#inputFromValue").hide();
          $("#inputValue").hide();
       

        } else {

          this.conditionForm.patchValue({
            param2: "",
          });
          $("#param2").val("").change();
          $("#inputFromValue").show();
          // $("#inputValue").show();

          this.conditionForm.get("param2").disable();
        }
        if(validation_type && validation_type.includes("All")){
          this.conditionForm.patchValue({
            operators: "",
            conditionapprover: "",
            approval_level: "",
            value_to: "",
            value_from: "",
            param2: "",
            param1: "",

          });
          this.addapprovallist = []
          $("#inputParamValue").hide();
            $("#inputBox").hide();
            $("#approval_level").val("").change()
          // this.conditionForm.get("operators").disable();
        } else {
          $("#inputParamValue").show();
          this.conditionForm.get("operators").enable();
          // $("#inputBox").show();
        }

        // if(validation_type && validation_type.includes("User")){
        //   // this.conditionForm.get("conditiontype").enable();

        // // To remove the readonly attribute
        // $('#conditiontype').prop('readonly', false);

        // } else {
      
        // // this.conditionForm.get("conditiontype").disable();
        // $('#conditiontype').prop('readonly', true);

       
        // }
        //PROP USING DROPDOWN DISABLE PAYLOAD THROW CODE
        

        console.log("validation_type", validation_type);
        this.conditionForm.patchValue({
          validation_type: validation_type,
        });
        this.onChaneParamTwo();
        this.onChaneParamOne();
        this.onChangeValaidationType(false)
        this.onOperatorChange()
        this.onCheckCNDType()
      });
      $("#conditionoperator").on("select2:select", (e: any) => {
        let conditionoperator = $("#conditionoperator").find(":selected").val();
        console.log("conditionoperator", conditionoperator);
        this.conditionForm.patchValue({
          conditionoperator: conditionoperator,
        });
      });
      $("#operators").on("select2:select", (e: any) => {
        let operators = $("#operators").find(":selected").val();
        let validation_type = $("#validation_type").find(":selected").val();
        if (operators && operators.includes("Between") && !validation_type.includes("Form Values")) {

          // this.conditionForm.get('value_to').`enable()
          $("#inputValue").show();
          $("#inputFromValue").show();
        } else {
          // this.conditionForm.get('value_to').disable()
          $("#inputValue").hide();
          if(!validation_type.includes("Form Values")){
            $("#inputFromValue").show();
          }
          

        }
       
        if (validation_type && validation_type.includes("Form Values")) {
          this.conditionForm.get("param2").enable();
          $("#inputFromValue").hide();
          $("#inputValue").hide();
       

        } else {

          this.conditionForm.patchValue({
            param2: "",
          });
          $("#param2").val("").change();
          $("#inputFromValue").show();
          // $("#inputValue").show();

          this.conditionForm.get("param2").disable();
        }
        // if (operators && operators.includes('Between')) {

        //   $('#inputBox').hide();
        // } else {
        //   // Hide the input box or revert the UI changes here
        //   $('#inputBox').show();
        // }
        console.log("operators", operators);
        this.conditionForm.patchValue({
          operators: operators,
        });
        // this.onChangeOPerator();
        this.onChaneParamOne();
        this.onChaneParamTwo();
        this.onOperatorChange()

      });

      // $('#inputBox').hide();

      $("#approval_level").on("select2:select", (e: any) => {
        let approval_level = $("#approval_level").find(":selected").val();
        if (approval_level && approval_level.includes("Auto Approved")) {
          // Show the input box or modify the UI here
          // $('#inputBoxContainer').show(); // Replace with appropriate selector
          $("#inputBox").hide();
        } else {
          // Hide the input box or revert the UI changes here
          $("#inputBox").show();
        }
        // if (this.addapprovallist.length >= 1 && approval_level.includes('One Level')) {
        //   Swal.fire("can't add above one list")
        //   return
        // }

        console.log("approval_level", approval_level);
        this.conditionForm.patchValue({
          approval_level: approval_level,
        });
      });

      $("#approvertype").on("select2:select", (type: any) => {
        let approvertype = $("#approvertype").find(":selected").val();
        if (approvertype && approvertype.includes("User Id")) {
          // Show the input box or modify the UI here
          $("#inputBoxContainer").show(); // Replace with appropriate selector
          $("#inputBoxContainers").hide();
        } else {
          // Hide the input box or revert the UI changes here
          $("#inputBoxContainer").hide(); // Replace with appropriate selector
          $("#inputBoxContainers").show();
        }
        // if (this.addapprovallist.length >= 1 && approvertype.includes('User Pool')) {
        //   Swal.fire("can't add above one list")
        //   return
        // }
        console.log("approvertype", approvertype);
        // if (approvertype.split(" ").join("").toLowerCase().includes("role"))
        //   this.valueItem=this.orgList// this.onGetOrgRoles();
        // else 
        if (
          approvertype.split(" ").join("").toLowerCase().includes("pool")
        )
          this.valueItem=this.poolList//this.onGetUserPool();
        this.approvalForm.patchValue({
          approvertype: approvertype,
          value : ''
        });
      });

      $("#param1").on("select2:select", (e: any) => {
        let param1 = $("#param1").find(":selected").val();
        console.log("param1", param1);
        this.conditionForm.patchValue({
          param1: param1,
        });
        this.onChaneParamOne();
      });
      $("#param2").on("select2:select", (e: any) => {
        let param2 = $("#param2").find(":selected").val();
        console.log("param2", param2);
        this.conditionForm.patchValue({
          param2: param2,
        });
        this.onChaneParamTwo();
      });
      $("#conditiontype").on("select2:select", (e: any) => {
        let conditiontype = $("#conditiontype").find(":selected").val();
        console.log("conditiontype", conditiontype);
        this.conditionForm.patchValue({
          conditiontype: conditiontype,
        });
      });
      $("#value").on("select2:select", (e: any) => {
        let value = $("#value").find(":selected").val();
        console.log(this.valueItem,value)
        // let value1 = $("#User_Idv").find(":selected").val();
        let findUserPool = this.valueItem.find((item) => item.value == value)
        // let find1 = this.userItem.find((item) => item.Id == value)
        const values = this.approvaltypeItem?.Data.map(item => item.value);
// console.log("find1",find1)
        // console.log("value", value,values);
        // let valuename :any;
        // if(values == "User Id"){
        //   valuename = find1.User_Idv
        // }else{
        //   valuename = findUserPool.value
        // }
        console.log("findUserPool",findUserPool.value)
        this.approvalForm.patchValue({
          value: findUserPool.value,
          value_name:findUserPool.value,
          // User_Idv:find1.User_Idv
        });
      });
      $("#User_Idv").on("select2:select", (e: any) => {
        let value = $("#User_Idv").find(":selected").val();
        // let find = this.valueItem.find((item) => item.id == value)
        let findUserId = this.userItem.find((item) => item.User_Idv == value)
        const values = this.approvaltypeItem?.Data.map(item => item.value);
        console.log("value", value,values);
        // let valuename :any;
        // if(values == "User Id"){
        //   valuename = find1.User_Idv
        // }else{
        //   valuename = find.value
        // }
        console.log("findUserId",findUserId.User_Idv)
        this.approvalForm.patchValue({
          value: findUserId.User_Idv,
          value_name:findUserId.User_Idv,
        });   
      });

      $(
        ".select2-search__field[aria-controls='select2-" +
          selectId +
          "-results']"
      ).each(function (key, value) {
        value.focus();
      });
    });

    this.clearSearch();
    // this.getRuleList();
    this.getParameterList();
    this.getOperatorBinding();
    this.getApprovalBinding();
    this.getApprovaltypeBinding();
    this.getValidationtypeBinding();
    this.getUser();
    this.onGetUserPool();
this.loadEncrptParam();
   
  }
  getEventByNumber(eventNumber: any) {
    this.wf.getDefineListByNumber(eventNumber).subscribe((data: any) => {
      // set items to json response
      console.log("getDefineListByNumber", data?.Data[0]);

      if (Array.isArray(data?.Data) && data?.Data.length > 0) {
        // this.basicForm.patchValue({
        //   ...data?.Data[0],
        // });
        this.eventname = data?.Data[0].eventname;
        this.appName = data?.Data[0].application;
        // $("#ddlApplication").val(data?.Data[0].application).change();
        //popover dynamicdata
        // this.dynamicContent = ` createdby: ${data?.Data[0].createdby}
        // createdon: ${data?.Data[0].createdon}
        // modifiedby: ${data?.Data[0].modifiedby}
        // modifiedon: ${data?.Data[0].modifiedon}`;
        this.createdon = data?.Data[0].createdon;
        this.modifiedon = data?.Data[0].modifiedon;
        this.modifiedby = data?.Data[0].modifiedby;
        this.createdby = data?.Data[0].createdby;
      }
    });
  }
  getOneRule() {
    this.wf.getOneRule(this.eventnumber?.eventnumber).subscribe(
      (data: any) => {
        this.onerulelist = data.Data;

        console.log("getOneRule", data);
        // this.ngOnInit()
        // initialize to page 1
        // this.setPage(1);
      },
      (error) => {
        this.onerulelist = [];
      }
    );
  }
onOperatorChange(){
  console.log("onChange operator",this.conditionForm.value)
        if (this.conditionForm.value?.operators.includes("Between") && 
        (this.conditionForm.value?.validation_type.includes("Form Values") || 
        this.conditionForm.value?.validation_type.includes("User"))) {
          this.conditionForm.patchValue({
            operators:this.operatorItem.length > 0 ? this.operatorItem[0]?.value : "",
            value_from: "",
            value_to: ""
          });  
          Swal.fire("Between not allowed in form value type") 
          console.log("onChange operator  true",this.conditionForm.value)
 
          $("#operators").val(this.conditionForm.value?.operators).change();  
        }
        if(this.conditionForm.value?.validation_type.includes("All")){
          this.isDisableParams=true;
          this.conditionForm.patchValue({
            operators: "",
            value_from: "",
            value_to: "",
            param1:"",
            param2:"",
            approval_level:"",
            conditionapprover: "",


          }); 
          this.addapprovallist = []

        }
        else{
          this.isDisableParams=false;

        }
}



  onChangeValaidationType(isEdit){
    if (this.conditionForm.value.validation_type && this.conditionForm.value.validation_type.includes("Form Values")) {
      console.log("this.allOperatorList",this.allOperatorList)
   
        // this.operatorItem = this.allOperatorList?.filter((val) => val.value != 'Between')
      if(this.conditionForm.value.operators && this.conditionForm.value.operators.includes("Between")){
        console.log("console")
        this.conditionForm.patchValue({
          operators:this.conditionForm.value?.operators? this.conditionForm.value?.operators: this.operatorItem.length > 0 ? this.operatorItem[0]?.value : "",
          value_from: "",
          value_to: ""
        });
        $("#operators").val(this.conditionForm.value?.operators).change();
      } else {
          console.log("console",this.conditionForm.value?.operators)
          
        this.conditionForm.patchValue({
          operators:this.conditionForm.value?.operators? this.conditionForm.value?.operators :this.operatorItem.length > 0 ? this.operatorItem[0]?.value : "",
          value_from: "",
          value_to: ""
        });
        $("#operators").val(this.conditionForm.value?.operators).change();
      }
    } else {

      // this.operatorItem = this.allOperatorList
      this.conditionForm.patchValue({
        operators:this.conditionForm.value?.operators ? this.conditionForm.value?.operators :this.operatorItem.length > 0 ? this.operatorItem[0]?.value : "",
        // value_from: "",
        // value_to: ""
      });
      $("#operators").val(this.conditionForm.value?.operators).change();
    }
    
  }

  getRuleList() {
    this.wf.getRuleList().subscribe((data: any) => {
      if (Array.isArray(data.Data)) {
        let rule = data.Data.filter(
          (val) => val.eventnumber == this.eventnumber?.eventnumber
        );
        this.addrulelist = rule;
      }
      console.log("getRuleList", this.addrulelist);
      // initialize to page 1
      // this.setPage(1);
    });
  }
  // moveUpCompliance() {
  //   console.log("iiiiiii", this.i);
  //   if (this.i > 0) {

  //     const element = this.addapprovallist[this.i];
  //     this.addapprovallist.splice(this.i, 1)
  //     this.addapprovallist.splice(this.i - 1, 0, element);
  //     this.i = +this.i - 1;
  //     this.pageSection = Math.floor((+this.i - 1) / 10) + 1;
  //     this.setPage(this.pageSection);
  //     // this.isSaveComplianceBtn = false;
  //   }
  // }

  moveUpCompliance() {
    if (this.approvali < 0 || this.isApprovalEdit ) {
      return true;
    }
    console.log("iiiiiiiU", this.approvali);
    if (this.approvali > 0) {
      // console.log("index",this.approvali);

      const element = this.addapprovallist[this.approvali];
      this.addapprovallist.splice(this.approvali, 1);
      this.addapprovallist.splice(this.approvali - 1, 0, element);
      this.approvali = this.approvali - 1;
      this.approvalpageSection = Math.floor(this.approvali / 10) + 1;
      console.log("pageSection", this.approvalpageSection);

      this.approvalSetPage(this.approvalpageSection);
      // this.isSaveComplianceBtn = false;
    }
  }
  moveDownCompliance() {
    if (this.approvali < 0 || this.isApprovalEdit) {
      return true;
    }
    console.log("iiiiiii", this.approvali);

    if (this.approvali != this.addapprovallist.length - 1 && this.approvali > -1) {
      const element = this.addapprovallist[this.approvali];
      this.addapprovallist.splice(this.approvali, 1);
      this.addapprovallist.splice(this.approvali + 1, 0, element);
      this.approvali = +this.approvali + 1;
      this.approvalpageSection = Math.floor(+this.approvali / 10) + 1;
      this.approvalSetPage(this.approvalpageSection);
      // this.isEditBtn = false
    }
  }

  getParameterList() {
    this.wf.getParameterList().subscribe((data: any) => {
      // set items to json response
      // this.parameterItems = data;
      if (Array.isArray(data.Data)) {
        let parameter = data.Data.filter(
          (val) => val.eventnumber == this.eventnumber?.eventnumber
        );
        this.addparameterlist = parameter;
        this.paramonelist = parameter;
        this.paramtwolist = parameter;
      }
      console.log("getParameterList", data);
    });
  }

  getUser(){
    this.wf.getUser().subscribe((data:any) => {
      this.userItem = data?.data?.TrasnportType_list;
      console.log("userItem",data)

     
    });
  }

  getValidationtypeBinding() {
    // console.log("validationtype", this.conditionForm.value);
    this.wf.getValidationtype({ type: "validationtype" }).subscribe((data) => {
      this.validationtypeItem = data;
    });
  }
  getApprovaltypeBinding() {
    // console.log("approvertype", this.approvalForm.value);
    this.wf.getApprovaltype({ type: "approvertype" }).subscribe((data:any) => {
      this.approvaltypeItem = data;
      this.approvaltypeItem1 = data?.Data[0]?.value;
      this.approvaltypeItem2 = data?.Data[1]?.value;

console.log("approvaltypeItem1",this.approvaltypeItem1)

    });
  }
  getOperatorBinding() {
    // console.log("conditionoperator", this.conditionForm.value);
    this.wf.getOperator({ type: "conditionoperator" }).subscribe((data:any) => {
     
      this.allOperatorList = data?.Data;
      this.operatorItem = this.allOperatorList
    });
  }
  
  getApprovalBinding() {
    // console.log("approvallevel", this.conditionForm.value);
    this.wf.getApproval({ type: "approvallevels" }).subscribe((data) => {
      this.approvalItem = data;
    });
  }

  // onGetOrgRoles() {
  //   this.wf.getRoleList().subscribe((res: any) => {
  //     // console.log('chagegetRoleList', res)
  //     if (res?.Data.length > 0) {
  //       let tempArr = [];
  //       tempArr.push({ value: "Select Role", id: "" });
  //       res?.Data.map((val) => {
  //         tempArr.push({ value: val.rolename, id: val.id });
  //       });
  //       this.orgList = tempArr;
  //       console.log("change", tempArr, res);
  //     }
  //   });
  // }
  onGetUserPool() {
    this.wf.getUserPoolsList().subscribe((res: any) => {
      // console.log('getUserPoolsList', res)
      if (res?.Data.length > 0) {
        let tempArr = [];
        tempArr.push({ value: "Select Pool", id: "" });
          res?.Data.map((val) => {
              //var upin = val.userpoolnumber - val.userpoolname;
              tempArr.push({ value: val.userpoolnumber + " - " + val.userpoolname, id: val.id });
        });
        this.poolList = tempArr;
      }
    });
  }

  onChange(data, i: number) {
    this.id = data;
    this.user_id = data;
    this.i = i;
  }

  onChangeApproval(data, i: number) {
  if ( this.isApprovalEdit) return

    this.approvalid = data;
    // this.user_id = data;
    this.approvali = i;
  }



  clearSearch() {
    // this.conditionForm.value.conditionoperator = $('#conditionoperator').val();
    // this.createForm()
    // this.RuleList();
    $("#conditionoperator").val(0).trigger("change");
  }

  deleteapproval() {
    
    //   const index = this.addapprovallist.findIndex(val => val ==this.user_id);
    console.log("this.id", this.addapprovallist,this.approvalid,this.approvali);

 
    if ( this.approvalid && this.approvalid?.user_id > 0 && !this.isApprovalEdit && this.approvali >-1 ) {
      if(this.addapprovallist.length == 1){
        Swal.fire("Min one approval need")
        return
      }
      this.wf.deleteapproval({ id: this.approvalid?.user_id }).subscribe((data) => {
        this.deleteItem = data;
        this.addapprovallist = this.addapprovallist.filter( (val) => val?.step !== this.approvalid?.step);
        this.approvalSetPage(1);
        this.approvalid = null;
        this.approvali = -1;
        console.log("this.id", this.addapprovallist);
        this.isApprovalEdit = false

        // this.addapprovallist=this.addapprovallist.filter((val => val.approvalid >0));
      });
    } else if(this.approvalid && this.approvali >-1 && !this.isApprovalEdit){
      this.addapprovallist = this.addapprovallist.filter(
        (val) => val?.step !== this.approvalid?.step
      );
      this.approvalSetPage(1);
     this.approvalid = null;
      this.approvali = -1;
      this.isApprovalEdit = false
      console.log("this.id ", this.addapprovallist);
    }else{
      console.log("this.id else", this.addapprovallist);
      // this.addapprovallist.splice(this.approvali,1)
      // this.approvalSetPage(1);
    }

  }
  // deletehole() {
  //   console.log("this.id", this.deleteItem)
  //   this.wf.deletehole({ id: this.id }).subscribe(data => {
  //     this.deleteItem = data;
  //   });
  // }
  addNewCondition() {
    this.isEdit = false;
    this.isApprovalEdit = false;
    this.approvalid = null;
    this.approvali= -1;

    this.i = -1;
    this.id = null;
    this.approvalForm.reset()
    $("#approvertype").val("").change();
    $("#value").val("").change();

    this.conditionForm.reset();
    this.conditionForm.patchValue({
      id: 0,
      eventnumber:this.eventnumber?.eventnumber ? this.eventnumber?.eventnumber : "",
      rulenumber: "",
      conditionoperator: "",
      conditionapprover: "",
      approval_level: "",
      validationtype: "",
      conditionid: 0,
      conditionname: "",
      // conditionnumber: '',
      conditiontype: "True",
      value_to: "",
      value_from: "",
      param2: "",
      param1: "",
      operators: "",
      validation_type: "",
      rulename: "",
    })
    this.selectReset();
    this.addapprovallist = [];
    $("#inputBox").hide();

    this.showSelect = true;
    this.showInput = false;
    // this.isDisableParams=false;
    $("#inputParamValue").show();

    this.showAdd = true;
    this.showEditAdd = false;
    this.operatorItem=this.allOperatorList
    //SELECT RADIO BUTTON UNSELECT CODE
    let tempaddholelist = this.addholelist
    this.addholelist = []
    this.addholelist = tempaddholelist
    if(this.addholelist?.length > 0){
   this.setPage(1);

    }
  }

  addconditionList() {
    // type=this.valued
    let root = this;
    // this.valued=this.addconditionList
    let data: any = this.conditionForm.value;
    // console.log('new', data);
    // console.log('this.addapprovallist', this.addapprovallist)
    data["conditionapprover"] = [];
    //UPDOWN CODE
    if (Array.isArray(this.addapprovallist))
      this.addapprovallist.map((val, i) => {
        if(!val.user_id ){
          val.user_id = 0
        }

        if(val.approvertype && val.value){
          data["conditionapprover"].push({ ...val, step: i + 1 });
        }
      
      }).filter((item:any)=> item?.user_id >=0 );


    if (!data.param2) {
      data["param2"] = "";
    }
    if (this.isEdit) {

      let findIndex =-1
      let finddata = this.addholelist.find((val,index) =>{
      if(val.conditionname?.toLowerCase()?.replace(/\s+/g,'') == this.conditionForm.value?.conditionname?.toLowerCase()?.replace(/\s+/g,'')){
        findIndex= index
        return val
      }
     })
  
      if(finddata && findIndex != this.i){
        Swal.fire("ConditionName  Already Exits")
        return
      }
      //payload id :0 sending code
      data.id = data.id ? data.id: 0
      data.eventnumber = data.eventnumber
      console.log("save", data);
      // return
      // data['conditionapprover'] = this.addapprovallist;
      this.wf.addWorkFlowcondition(data).subscribe((data) => {
        //   Swal.fire(data[this.i].AlertMessage);
        //   (error: HttpErrorResponse) => {
        //     Swal.fire(error.message)
        // }
        console.log("save", data);
        this.getconditionByNumber(this.eventnumber?.eventnumber);
        //MULTIPLE ADD CODE
        this.approvalForm.patchValue({
          user_id: 0,
          eventnumber: this.eventnumber?.eventnumber,
          step: 0,
        });
        //DROPDOWN RESET
        // console.log('222',this.addapprovallist);
        this.approvalForm.reset();
        //save complete append reset code
        setTimeout(() => {
          this.selectReset();
          this.addapprovallist = [];
        }, 500);
        // this.selectReset();
        //   Swal.fire({
        //     title: "",
        //     text: 'Saved successfully',
        // })
        // this.addNewCondition();
        //  this.isEdit=true;
        //   if(this.isEdit){
        console.log("siEdit", this.isEdit);
        Swal.fire("Update Successfully");
        // setTimeout(() => {
          this.conditionForm.patchValue({
            id: 0,
            eventnumber:this.eventnumber?.eventnumber ? this.eventnumber?.eventnumber : "",
            rulenumber: "",
            conditionoperator: "",
            conditionapprover: "",
            approval_level: "",
            validationtype: "",
            conditionid: 0,
            conditionname: "",
            // conditionnumber: '',
            conditiontype: "True",
            value_to: "",
            value_from: "",
            param2: "",
            param1: "",
            operators: "",
            validation_type: "",
            rulename: "",
          })
          
          $("#rulenumber").val("0").change();
        // }, 300);
         this.approvalid = null;
    this.approvali= -1;
    this.approvalForm.reset()
    $("#approvertype").val("").change();
    $("#value").val("").change();
        // } else {


        // Swal.fire('Saved Successfully');
        // }
        // Swal.fire('Saved successfully');
        this.getcondition();
      this.getOneRule();
    this.addNewCondition();
    
        // .then(function() {
        //   console.log('root.conditionForm',root.conditionForm.value)
        //   if(root.conditionForm.value)
        //    window.location.reload();
        //    this.getconditionByNumber(root.conditionForm?.value?.eventnumber)
        // });

      });
    } else {
      let finddata = this.addholelist?.find((val) => val.conditionname?.toLowerCase()?.replace(/\s+/g,'') == this.conditionForm.value?.conditionname?.toLowerCase()?.replace(/\s+/g,''))
      if(finddata){
        Swal.fire("ConditioName  Already Exits")
        console.log("save", );
        return
      }
      //payload id :0 sending code
      data.id = data.id ? data.id: 0
      console.log("save", data);
      // return
      // return
      this.wf.addWorkFlowcondition(data).subscribe((data) => {
        console.log("save", data);
        this.getconditionByNumber(this.eventnumber?.eventnumber);
        //MULTIPLE ADD CODE
        this.approvalForm.reset();
        
        this.approvalForm.patchValue({
          user_id: 0,
          eventnumber: this.eventnumber?.eventnumber,
          step: 0,
        });
  
        setTimeout(() => {
          this.selectReset();
          this.addapprovallist = [];
          
          
        }, 500);

        Swal.fire("Saved successfully");
        this.approvalid = null;
        this.approvali= -1;
      
        $("#approvertype").val("").change();
        $("#value").val("").change();
        this.getcondition();
        this.getOneRule();
        setTimeout(() => {
          this.conditionForm.patchValue({
            id: 0,
            eventnumber:this.eventnumber?.eventnumber ? this.eventnumber?.eventnumber : "",
            rulenumber: "",
            conditionoperator: "",
            conditionapprover: "",
            approval_level: "",
            validationtype: "",
            conditionid: 0,
            conditionname: "",
            // conditionnumber: '',
            conditiontype: "True",
            value_to: "",
            value_from: "",
            param2: "",
            param1: "",
            operators: "",
            validation_type: "",
            rulename: "",
          })
          
          $("#rulenumber").val("0").change();
        }, 300);
   
      });
     
    }



    // Swal.fire('Saved successfully');
  }

  selectReset() {
    $("#param1").val("0").change();
    $("#param2").val("0").change();
    $("#operators").val("").change();
    $("#approval_level").val("").change();
    $("#rulenumber").val("0").change();
    $("#validation_type").val("").change();
    $("#value_from").val("").change();
    $("#value_to").val("").change();
    $("#conditionname").val("").change();
    $("#rulename").val("").change();
    $("#value").val("").change();
    $("#approvertype").val("").change();
    this.loadEncrptParam();
   
   
  }
  getconditionByNumber(eventNumber: any) {
    this.wf.getconditionByNumber(eventNumber).subscribe((data: any) => {
      // set items to json response
      // this.addholelist = data?.Data;

      // console.log('getconditionByNumber', data);
      if (Array.isArray(data?.Data) && data?.Data.length > 0) {
        this.addapprovallist = data?.Data[this.i]?.conditionapprover || [];
        this.addapprovallist = this.addapprovallist.filter(
          (val) => val.user_id > 0
        );
        //UPDOWN CODE
        this.addapprovallist.sort(function (a, b) {
          return a.step < b.step ? -1 : 1;
        });
        this.conditionForm.patchValue({
          ...data?.Data[0],
        });
      }
      this.conditionForm.reset();
    });
  }
  editAdd() {

    if(this.approvalid && this.approvali > -1 && this.isApprovalEdit){
 var validation = "";
    if (this.approvalForm.value.approvertype == "") {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter ApproverType</span></br>";
    }
    if (this.approvalForm.value?.value?.replace(/\s+/g,'') == "") {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Value</span></br>";
    }
    if (validation != "") {
      Swal.fire(validation);
      return false;
    }
    let findIndex =-1
    let finddata = this.addapprovallist.find((val,index) =>{
    if(val.approvertype?.toLowerCase()?.replace(/\s+/g,'') == this.approvalForm.value?.approvertype?.toLowerCase()?.replace(/\s+/g,'')
    && val.value?.toLowerCase()?.replace(/\s+/g,'') == this.approvalForm.value?.value?.toLowerCase()?.replace(/\s+/g,'')){
      findIndex= index
      return val
    }
   })

    if(finddata && findIndex != this.approvali){
      Swal.fire("Approval  Already Exits")
      return
    }
   
    console.log("editAdd", this.addapprovallist[this.approvali]);
    if(this.approvalForm.value.approvertype == 'User Id'){
      this.approvalForm.patchValue({
        value_name:this.approvalForm?.value?.value_name
      })
    }
    this.addapprovallist[this.approvali] = this.approvalForm.value;
    //DROPDOWN RESET
    $("#approvertype").val("").change();
    $("#value").val("").change();
    $("#User_Idv").val("").change();

    this.approvalForm.reset();
    this.showAdd = true;
    this.showEditAdd = false;
   this.approvali = -1;
   this.approvalid = null;
   this.isApprovalEdit=false;

  }

  }
  addApprovalList(type: any) {
    if (this.isApprovalEdit) return
   console.log("qq",this.approvalForm.value)
    var validation = "";

    if (this.approvalForm.value.approvertype == "" || !this.approvalForm.value.approvertype) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter ApproverType</span></br>";
    }
    if (this.approvalForm.value.value == "" || !this.approvalForm.value.value) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Value</span></br>";
    }
   
    if (validation != "") {
      Swal.fire(validation);
      return false;
    }

    let approval_level = $("#approval_level").find(":selected").val() || "";
    if(this.addapprovallist.length > 0){
      if(!this.conditionForm.value.approval_level ){
        Swal.fire("Please select the Approval level")
        return
      }else if(this.conditionForm.value.approval_level .includes("Auto Approved")){
        Swal.fire("Please select Approval level is only one level or multiple level")
        return
      }

      // else if(this.addapprovallist[0]?.approvertype == "User Id"
      //   && this.addapprovallist[0]?.approvertype  != this.approvalForm.value.approvertype){
      //     Swal.fire("Please select Approver Type is User Id ")
      //     return
      // }
      // else if((this.addapprovallist[0]?.approvertype == "User Pool")) {
      //   Swal.fire("Max Approval count reached")
      //   return
      // }
      }
    if (this.addapprovallist.length >= 1 &&approval_level.includes("One Level")) {
      Swal.fire("Up to 1 approval only allowed");
      return;
    }

    // if ( (approval_level.includes("One Level") ||  approval_level.includes("Multi Level")) && this.addapprovallist.length == 0) {
    //   Swal.fire("Please Add Approval List");
    //   return;
    // }

    let finddata = this.addapprovallist.find((val) => (val?.approvertype?.toLowerCase()?.replace(/\s+/g,'') == this.approvalForm.value?.approvertype?.toLowerCase()?.replace(/\s+/g,''))
    && val?.value?.toLowerCase()?.replace(/\s+/g,'') == this.approvalForm.value?.value?.toLowerCase()?.replace(/\s+/g,'') )
    if(finddata){
      Swal.fire("Approval  Already Exits")
      return
    }
    // let approvertype = $('#approvertype').find(":selected").val()

    // if (this.addapprovallist.length >= 1 && approvertype.includes('User Pool')) {
    //   Swal.fire("can't add above one lists")
    //   return
    // }
    console.log("addApprovalList", type);
    // if (type == 'add')
    if(this.approvalForm.value.approvertype == 'User Id'){
      this.approvalForm.patchValue({
        value_name:this.approvalForm.value.value_name,
        // User_Idv:this.approvalForm.value.User_Idv

      })
    }
    this.addapprovallist.push(this.approvalForm.value);
    // else if (type == 'edit') {
    //   console.log('this.approvalForm.value', this.approvalForm.value);
    //   this.addapprovallist[this.i] = this.approvalForm.value
    // }
    let data: any = this.conditionForm.value;
    data["conditionapprover"] = this.addapprovallist;
    // this.addapprovallist = []
    this.approvalForm.reset();
    //MULTIPLE ADD CODE
    this.approvalForm.patchValue({
      user_id: 0,
      eventnumber: this.eventnumber?.eventnumber,
      step: 0,
    });
    //DROPDOWN RESET
    $("#approvertype").val("").change();
    $("#value").val("").change();
    $("#User_Idv").val("").change();
    this.approvalid = null;
    this.approvali = -1;
    this.isApprovalEdit = false;
  }

  editConditionList() {

    console.log("editConditionList1",this.approvali,this.approvalid,this.addapprovallist[this.approvali].value)

    if (this.approvali <= -1 || !this.approvalid) {

    console.log("editConditionList2",this.approvali,this.approvalid)
      return this.isApprovalEdit = false;
    }
    if (this.isApprovalEdit == true){

    console.log("editConditionList3",this.approvali,this.approvalid)
      return
    }
    this.isApprovalEdit = true;
    let data = this.addapprovallist[this.approvali];
    console.log("editdata", data,this.addapprovallist[this.approvali].value);
    this.approvalForm.patchValue(data);
    //DROPDOWN UPEND CODE
    $("#approvertype").val(this.addapprovallist[this.approvali].approvertype).change();
    // setTimeout(() => {
      $("#value").val(this.addapprovallist[this.approvali]?.value).change();
      $("#User_Idv").val(this.addapprovallist[this.approvali].value).change();
    // }, 200);
 
    console.log("editConditionListdrop",this.addapprovallist[this.approvali].value)

    let approvertype = $("#approvertype").find(":selected").val();
    if (approvertype && approvertype.includes("User Id")) {
      console.log('user')
      // Show the input box or modify the UI here
      $("#inputBoxContainer").show(); // Replace with appropriate selector
      $("#inputBoxContainers").hide();
    } else {
      console.log('user pool')
      // Hide the input box or revert the UI changes here
      $("#inputBoxContainer").hide(); // Replace with appropriate selector
      $("#inputBoxContainers").show();
    }
    console.log("approvertype", approvertype);
    // if (approvertype?.split(" ").join("").toLowerCase().includes("role"))
    //   this.valueItem=this.orgList//this.onGetOrgRoles();
    // else
     if (approvertype?.split(" ").join("").toLowerCase().includes("pool"))
     this.valueItem=this.poolList// this.onGetUserPool();
    this.approvalForm.patchValue({
      approvertype: approvertype,
    });

    this.showAdd = false;
    this.showEditAdd = true;
    $("#approvertype").val(this.addapprovallist[this.approvali].approvertype).change();
    // setTimeout(() => {
      $("#value").val(this.addapprovallist[this.approvali].value).change();
      $("#User_Idv").val(this.addapprovallist[this.approvali].value).change();
    // });
    console.log("",this.approvalForm.value)

  }
  edit(type: any) {
    // this.valued=this.edit
    // only select click edit working code
    if (this.i < 0) {
      return true;
    }
    console.log("value1", this.i);
    // let select = $('#select').find(":selected").val()
    // if(select){

    //   this.conditionForm.enable()
    // } else{

    //   this.conditionForm.disable()
    // }
    this.isApprovalEdit = false;
    this.approvalid = null;
    this.approvali= -1;
    this.approvalForm.reset()
    $("#approvertype").val("").change();
    $("#value").val("").change();
    $("#User_Idv").val("").change();

    this.isEdit = true;

    let data = this.addholelist[this.i];
    console.log("selectindex", this.i);

    this.conditionForm.patchValue(data);
    if (this.isEdit) {
      console.log("this.isEdit1", this.isEdit);

      this.wf
        .getconditionByNumber(this.eventnumber?.eventnumber)
        .subscribe((data: any) => {
          // if (Array.isArray(data.Data)) {
          //   this.addapprovallist = data?.Data[this.i]?.conditionapprover;
          //   let condition = data.Data.filter(val => val.eventnumber == this.eventnumber)
          //   console.log('getconditionByNumber',data);
          //   this.addholelist = condition;
          // }
          if (Array.isArray(data?.Data) && data?.Data.length > 0) {
            this.addapprovallist = data?.Data[this.i]?.conditionapprover || [];
            this.addapprovallist = this.addapprovallist.map((val) => {
             
              // let find = this.orgList.find((item) => item.id == val.value)
              let findpool = this.poolList.find((item) => item.id == val.value)
              // if(val.approvertype ==  'Org. Role' && find){
              //   val.value_name = find.value
              // }else 
              if(val.approvertype ==  'User Pool' && findpool){
                val.value_name = findpool?.value
              }
              else{
                val.value_name = val.value

              }
              console.log("val.value_nameval.value_name",val)
              if(val.user_id > 0){
                return val
              }
            }).filter((item:any)=> item?.user_id);
            console.log(this.addapprovallist,'yyy')
            let condition = data.Data.filter((val) => val.eventnumber == this.eventnumber?.eventnumber );
            this.addholelist = condition;
            // this.conditionForm.patchValue({
            //   ...data?.Data[this.i]
            // })
            // <ASENDING,>DESENDING CODE
            this.addapprovallist.sort(function (a, b) {
              return a.step < b.step ? -1 : 1;
            });
            let approval_level = $("#approval_level").find(":selected").val();
            console.log("approval_level",approval_level)
            if (approval_level && approval_level.includes("Auto Approved")) {
              // Show the input box or modify the UI here
              // $('#inputBoxContainer').show(); // Replace with appropriate selector
              $("#inputBox").hide();
            } else {
              // Hide the input box or revert the UI changes here
              $("#inputBox").show();
            }
          }

          let validation_type = $("#validation_type").find(":selected").val();
          if (validation_type && validation_type.includes("Form Values")) {
            this.conditionForm.get("param2").enable();
            $("#inputFromValue").hide();
            $("#inputValue").hide();

          } else {
            this.conditionForm.get("param2").disable();
            $("#inputFromValue").show();
          }
          if(validation_type && validation_type.includes("All")){
            
            $("#inputParamValue").hide();
              $("#inputBox").hide();
            this.conditionForm.get("operators").disable();
          } else {
            $("#inputParamValue").show();
            // this.conditionForm.get("operators").enable();
            // $("#inputBox").show();
          }
        
          
          
          let rulenumber = $("#rulenumber").find(":selected").val();
          //  if(rulenumber){
          //    this.conditionForm.get('rulenumberinput').disable()
          //  }
          //    let rule= this.addrulelist.filter(val => val.rulenumber == rulenumber)

          //   console.log('rulenumber', rulenumber,rule)
          //   this.conditionForm.patchValue({
          //     rulenumber: rulenumber,
          //     rulename:rule.length>0?rule[0]?.rulename:''
          //   })
          let rule = this.addrulelist.filter( (val) => val.rulenumber == this.conditionForm.value.rulenumber );

          console.log("rulenumber", rulenumber, rule);
          this.conditionForm.patchValue({
            // rulenumber: rulenumber,
            rulename: rule.length > 0 ? rule[0]?.rulename : "",
          });

          let operators = $("#operators").find(":selected").val();
          if (operators && operators.includes("Between")) {
            // this.conditionForm.get('value_to').enable()
            $("#inputValue").show();
          } else {
            // this.conditionForm.get('value_to').disable()
            $("#inputValue").hide();
          }

          this.showSelect = false;
          this.showInput = true;
        });

      //this.addapprovallist = this.addholelist[this.i]?.conditionapprover;

      // console.log("editCondition", data);
      // //EDIT DROPDOWN DATA BINNDING

      // $('#rulenumber').val(this.onerulelist[this.i]?.rulenumber).change()
      // console.log('this.addrulelist.rulenumber',this.onerulelist[this.i]?.rulenumber);

      $("#rulename").val(this.onerulelist[this.i]?.rulename).change();

      $("#validation_type")
        .val(this.addholelist[this.i]?.validation_type)
        .change();
      // console.log('this.validation_type',this.addholelist[this.i]);

      $("#param1").val(this.addholelist[this.i]?.param1).change();
      // console.log('this.addparameterlist',this.addparameterlist);
      console.log("param2", this.addholelist[this.i]);
      $("#param2").val(this.addholelist[this.i]?.param2).change();

      $("#conditiontype")
        .val(this.addholelist[this.i]?.conditiontype.toString())
        .change();
      console.log(
        "this.addholelist-conditiontype",
        this.addholelist[this.i]?.conditiontype
      );

      $("#approval_level")
        .val(this.addholelist[this.i].approval_level)
        .change();
      console.log("this.addholelist", this.addholelist[this.i]);

      $("#operators").val(this.addholelist[this.i].operators).change();
      // console.log('this.addholelist',this.addholelist[this.i]);
      console.log(
        "999",
        this.conditionForm.getRawValue(),
        this.addholelist[this.i].operators
      );
      this.onChaneParamOne();
      this.onChaneParamTwo();
       this.onChangeValaidationType(true)
       this.onCheckCNDType()
    }

    // this.isEdit = true;
    return;
  }

  onChaneParamOne() {
    if(this.conditionForm.value.operators   ){
      // this.paramtwolist = this.
      console.log("ddddd",this.conditionForm.value.operators)
    }this.paramtwolist=[]
    this.paramtwolist = this.addparameterlist.filter((val) => {
      console.log("cc",val.paramname != this.conditionForm.getRawValue().param1)
      // console.log("c2",!this.conditionForm.value.operators)
      // console.log("c3", this.conditionForm.value.operators?.toLowerCase() != "not equal to" && this.conditionForm.value.operators?.toLowerCase() != "equal to" )
      // console.log("c4",val.datatype?.toLowerCase() != "text" )
      console.log('yy',((!this.conditionForm.value.operators  || this.conditionForm.value.operators =='0') ? true : 
      this.conditionForm.value.operators?.toLowerCase() != "not equal to" && this.conditionForm.value.operators?.toLowerCase() != "equal to" ?
      val.datatype?.toLowerCase() != "text" : true))
   if(val.paramname != this.conditionForm.getRawValue().param1 &&  ((!this.conditionForm.value.operators  || this.conditionForm.value.operators =='0') ? true : 
   this.conditionForm.value.operators?.toLowerCase() != "not equal to" && this.conditionForm.value.operators?.toLowerCase() != "equal to" ?
   val.datatype?.toLowerCase() != "text" && val.datatype?.toLowerCase() != 'user id': true)){
    return val
   }  
    }  
    );
   
    // &&
    //   this.conditionForm.value.operators
    //     ? this.conditionForm.value.operators?.toLowerCase() != "not equal to" &&
    //       this.conditionForm.value.operators?.toLowerCase() != "equal to"
    //       ? val.datatype?.toLowerCase() != "text"
    //       : true
    //     : true

    let find = this.paramtwolist.find(
      (val) => val.paramname == this.conditionForm.getRawValue().param2
    );
    if (!find) {
      console.log("paramsssss",)
      this.conditionForm.patchValue({
        param2: "",
      });
      $("param2").val("0").change();
    }
    console.log(this.conditionForm.value, this.addparameterlist,find);
   
  }

  onChaneParamTwo() {
    this.paramonelist=[]
    this.paramonelist = this.addparameterlist.filter((val) => { 
      console.log("dc",val.paramname != this.conditionForm.getRawValue().param2)
      // console.log("d2",!this.conditionForm.value.operators)
      // console.log("d3", this.conditionForm.value.operators?.toLowerCase() != "not equal to" && this.conditionForm.value.operators?.toLowerCase() != "equal to" )
      console.log("d4",val.datatype?.toLowerCase() != "text" && val.datatype?.toLowerCase() != 'user id')
      console.log('xx',( (!this.conditionForm.value.operators || this.conditionForm.value.operators == '0')  ? true : 
      this.conditionForm.value.operators?.toLowerCase() != "not equal to" && this.conditionForm.value.operators?.toLowerCase() != "equal to" ?
      val.datatype?.toLowerCase() != "text" : true))
      if(val.paramname != this.conditionForm.getRawValue().param2 && ( (!this.conditionForm.value.operators || this.conditionForm.value.operators == '0')  ? true : 
    this.conditionForm.value.operators?.toLowerCase() != "not equal to" && this.conditionForm.value.operators?.toLowerCase() != "equal to" ?
    val.datatype?.toLowerCase() != "text" && val.datatype?.toLowerCase() != 'user id' : true)){
      return val
    }
    }
    );
    // &&
    //   this.conditionForm.value.operators? this.conditionForm.value.operators?.toLowerCase() != "not equal to" &&
    //     this.conditionForm.value.operators?.toLowerCase() != "equal to" ? val.datatype?.toLowerCase() != "text"  : true : true
    let find = this.paramonelist.find(
      (val) => val.paramname == this.conditionForm.getRawValue().param1
    );
    if (!find) {
      console.log("param22222",)

      this.conditionForm.patchValue({
        param1: "",
      });
      $("param1").val("0").change();
    }
    console.log(
      "onChaneParam",
      this.conditionForm.value,
      this.paramonelist,
      this.paramtwolist,find
    );
   
  }

  onChangeOPerator() {
    this.paramonelist = this.addparameterlist.filter((val) =>
      this.conditionForm.value.operators ? this.conditionForm.value.operators?.toLowerCase() != "not equal to" ||
      this.conditionForm.value.operators?.toLowerCase() != "equal to"? true
          : (val.datatype?.toLowerCase() != "text" &&  val.datatype?.toLowerCase() != "user id")
        : true
    );
    console.log(
      "onChangeOPerator",
      this.conditionForm.value.operators,
      this.paramonelist
    );
  }

  getcondition() {
    this.wf
      .getconditionByNumber(this.eventnumber?.eventnumber)
      .subscribe((data: any) => {
        if (Array.isArray(data?.Data) && data?.Data.length > 0) {
          this.addapprovallist = data?.Data[this.i]?.conditionapprover || [];
          let condition = data.Data.filter(
            (val) => val.eventnumber == this.eventnumber?.eventnumber
          );
          this.addholelist = condition;
          // console.log('999999999',this.addholelist)
          // <ASENDING,>DESENDING CODE
          // this.addapprovallist.sort(function (a, b) {
          //   return a.step < b.step ? -1 : 1;
          // })
          // if(this.addapprovallist.length >0){

          this.setPage(1);
          //}
        }
      });
    // this.wf.getconditionByNumber(this.eventnumber).subscribe((data: any) => {
    //   if (Array.isArray(data.Data)) {
    //     let condition = data.Data.filter(val => val.eventnumber == this.eventnumber)
    //     this.addholelist = condition;
    //     //UPDOWN CODE
    //     // this.addapprovallist.sort(function (a, b) {
    //     //   return a.step < b.step ? -1 : 1;
    //     // })
    //   }
    //   console.log("getcondition", data);
    // });
  }
  onSubmit() {
    let root = this;
    // if (this.conditionForm.valid) {
    // }
    var validation = "";
    if(this.conditionForm.value.validation_type != 'All'){

    

    if (this.conditionForm.value.conditionnumber == "" || !this.conditionForm.value.conditionnumber ) {
      // validation +=
      //   "<span style='color:red;'>*</span> <span>Please Enter Condition Number</span></br>";
    }
    if (this.conditionForm.value?.conditionname?.replace(/\s+/g,'') == "" || !this.conditionForm.value.conditionname ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Condition Name</span></br>";
    }
    if (this.conditionForm.value.rulenumber == "" || !this.conditionForm.value.rulenumber ) {
      // console.log("this.conditionForm.value.rulenumber",this.conditionForm.value.rulenumber);

      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Rule Number</span></br>";
    }
    // if (this.conditionForm.value.rulename == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Rule Name </span></br>"
    // }
    if (this.conditionForm.value.validation_type == "" || !this.conditionForm.value.validation_type) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Validation Type </span></br>";
    }

    // if (this.conditionForm.value.value_to == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Value2 </span></br>"
    // }
    // if (this.conditionForm.value.value_from == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Value1 </span></br>"
    // }
    if (this.conditionForm.value.param1 == "" || !this.conditionForm.value.param1) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Parameter 1 </span></br>";
    }
    console.log("param1",this.conditionForm.value.param1)
    if (this.conditionForm.value.param2 == "" && this.conditionForm.value.validation_type == "Form Values") {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Parameter 2 </span></br>"
    }
    if (this.conditionForm.value.operators == "" || !this.conditionForm.value.operators) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter operators  </span></br>";
    }

    if (this.conditionForm.value.approval_level == "" || !this.conditionForm.value.approval_level) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Approval Level </span></br>";
    }

    let operators = $("#operators").find(":selected").val();

    if (
      (this.conditionForm.value?.value_to?.replace(/\s+/g,'') == "" || !this.conditionForm.value.value_to ||this.conditionForm.value?.value_from?.replace(/\s+/g,'') == "" || 
      !this.conditionForm.value.value_from) && this.conditionForm.value.validation_type != "Form Values" && operators && operators.includes("Between")) {
      if (this.conditionForm.value?.value_to?.replace(/\s+/g,'') == "" || !this.conditionForm.value.value_to) {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Enter Value 2 </span></br>";
      }
      if (this.conditionForm.value?.value_from?.replace(/\s+/g,'') == "" || !this.conditionForm.value.value_from) {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Enter Value 1 </span></br>";
      }
    } else {
      if ((this.conditionForm.value?.value_from?.replace(/\s+/g,'') == "" || !this.conditionForm.value.value_from) && this.conditionForm.value.validation_type != "Form Values"
      ) {
        validation +=
          "<span style='color:red;'>*</span> <span>Please Enter Value 1 </span></br>";
      }
    }

    if ( parseFloat(this.conditionForm.value?.value_from?.toString()) >= parseFloat(this.conditionForm.value?.value_to?.toString()) &&
      operators.indexOf("Between") > -1 ) {
      Swal.fire("Value 1 Greaterthen Value 2");
      return;
    }
  

    console.log(
      "this.conditionForm.value.value_from",
      this.conditionForm.value.value_from,
      this.conditionForm.value,
      operators
    );
    
    if (validation != "") {
      Swal.fire(validation);
      return false;
    }

    console.log("4444",this.addholelist,this.addapprovallist)
    // let approval_level = $("#approval_level").find(":selected").val() || "";
    if ( (this.conditionForm.value.approval_level.includes("One Level") ||  this.conditionForm.value.approval_level.includes("Multi Level"))
     && this.addapprovallist.length == 0) {
      Swal.fire("Please Add Approval List");
      return;
    }
    // return
    // console.log("this.isEdit",this.isEdit)
    // if (this.isEdit) {
    //   let data: any = this.conditionForm.value
    //   // console.log('new', data);
    //   // console.log('this.addapprovallist', this.addapprovallist)
    //   data['conditionapprover'] = this.addapprovallist;
    //   data['conditionapprover'][this.i] = this.approvalForm.value

    //   this.wf.addWorkFlowcondition(data).subscribe(data => {
    //     // set items to json response
    //     // this.parameterItems = data;
    //     console.log("editCondition", data);
    //     //TABLE LIST RESET

    //     //  console.log('111',this.addapprovallist);
    //     this.addapprovallist = []

    //     //DROPDOWN RESET
    //     this.approvalForm.reset()
    //     $('#approvertype').val('').change()
    //     $('#value').val('').change()
    //     // this.approvalForm.reset()
    //     $('#param1').val('').change()
    //     $('#param2').val('').change()
    //     $('#operators').val('').change()
    //     $('#approval_level').val('').change()
    //     $('#rulenumber').val('').change()
    //     $('#validation_type').val('').change()
    //     $('#value_from').val('').change()
    //     $('#value_to').val('').change()
    //     $('#conditionname').val('').change()
    //     $('#rulename').val('').change()
    //   //   Swal.fire({
    //   //     title: "",
    //   //     text: 'Update successfully',
    //   // })
    //   // .then(function() {
    //   //   console.log('root.conditionForm',root.conditionForm.value)
    //   //   if(root.conditionForm.value)
    //   //    window.location.reload();
    //   //    this.getconditionByNumber(root.conditionForm?.value?.eventnumber)
    //   // });
    //     Swal.fire('Update Successfully');
    //     // this.ngOnInit()

    //   });
    //   this.isEdit = false;
    //   return;
    // }
    // else{
//     
      console.log("typeof", this.conditionForm.value.value_from,isNaN(this.conditionForm.value.value_from) )
      const numberPattern = /^[0-9]+$/

      if(this.conditionForm.value.operators?.toLowerCase() != "not equal to" &&
        this.conditionForm.value.operators?.toLowerCase() != "equal to" ){
        let param1type:any = this.conditionForm.value.value_from
        let param2type:any = this.conditionForm.value.value_to

        console.log("typeof", this.conditionForm.value.value_from )
          if(operators.indexOf("Between") > -1 && !this.conditionForm.value?.validation_type?.includes("Form Values")  
          && ( isNaN(param1type) || !(param1type > 0 || isNaN(param2type) || !(param2type > 0) ))){
          
              Swal.fire('Params value is not a number or value is zero')
              return
            
         }else if(isNaN(param1type)  || !(param1type > 0) && !this.conditionForm.value?.validation_type?.includes("Form Values")){

            Swal.fire('Params value is not a number or value is zero')
            return
           }
// return
}
console.log('savung')
// return

    }
    this.addconditionList();
    // this.getOneRule();
    // this.getcondition();

    // }
    // this.wf.addWorkFlowcondition(this.conditionForm.value).subscribe(Data => {
    //   Swal.fire(Data[0].AlertMessage);
    // },
    //   (error: HttpErrorResponse) => {
    //     Swal.fire(error.message)
    //   });
    // this.addNewCondition();
  }

  onSumbitCheck(){
    if(this.conditionForm.value?.validation_type.includes("All")){
      Swal.fire({
        title: 'If select "All" then condition have "Auto Approved". Do you want continue?',
        showDenyButton: true,
        confirmButtonText: 'YES',
        denyButtonText: 'NO',
        }).then((result) => {
              if (result.isConfirmed) {
                
                 var validation = "";
             
                 if (this.conditionForm.value?.conditionname?.replace(/\s+/g,'') == "" || !this.conditionForm.value.conditionname ) {
                   validation +=
                     "<span style='color:red;'>*</span> <span>Please Enter Condition Name</span></br>";
                 }
                 if (this.conditionForm.value.rulenumber == "" || !this.conditionForm.value.rulenumber ) {
                   // console.log("this.conditionForm.value.rulenumber",this.conditionForm.value.rulenumber);
                   validation +=
                     "<span style='color:red;'>*</span> <span>Please Enter Rule Number</span></br>";
                 }
               
                 if (this.conditionForm.value.validation_type == "" || !this.conditionForm.value.validation_type) {
                   validation +=
                     "<span style='color:red;'>*</span> <span>Please Enter Validation Type </span></br>";
                 }            
                 if (validation != "") {
                   Swal.fire(validation);
                   return false;
                 }  
                 this.onSubmit()  
        }
      })
    }
    else{
this.onSubmit();
    }
  }

onCheckCNDType(){
  if (this.conditionForm.value.validation_type && this.conditionForm.value.validation_type.includes("User")) {
    $('#conditiontype').prop('disabled', false); // Enable the select field
} else {
    $('#conditiontype').prop('disabled', true); // Disable the select field
    this.conditionForm.patchValue({
      conditiontype : "True"
    })

    $("#conditiontype").val("True").change();
}
}

  public selectedName: any;
  public highlightRow(dataItem) {
    this.selectedName = dataItem.CountryCode;
  }
  setPage(page: number) {
    //if (page < 1 || page > this.pager.totalPages) {
    //    return;
    //}

    // get pager object from service
    this.pager = this.ps.getPager(this.addholelist?.length, page);
    this.currentPage = page;
    // get current page of items
    this.pagedItems = this.addholelist?.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }
  approvalSetPage(page: number) {
   
    this.appprovalpager = this.ps.getPager(this.addapprovallist?.length, page);
    this.approvalcurrentPage = page;
    // get current page of items
    this.approvalpagedItems = this.addapprovallist?.slice(
      this.appprovalpager.startIndex,
      this.appprovalpager.endIndex + 1
    );
  }

  pdflink() {
    this.ms.getPDF(this.conditionForm.value).subscribe((data) => {
      console.log(data[0].aclFilename);
      window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
    });

    //alert('hi');
    //this.http.get(this.globals.APIURL + '/home/BLPrintPDF/').subscribe(res => {

    //   });

    /*window.open(RouterLink("BLPrintPDF", "BLPrint")');*/

    /*window.open("assets/pdfFiles/test.pdf");*/
  }

  //openpdf() {
  //    $('#pdfview').modal('show');
  //}

  isDesc: boolean = false;
  column: string = "";
  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.pagedItems.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }

  changeTab(tab) {
    var values = "ID: " + this.tabsForm.value.ID;
    //var values = "ID: 8";
    var encrypted = this.encryptedParam; // this.ES.set(localStorage.getItem("EncKey"), values);
    if (tab == 1) {
      this.router.navigate(["/views/difineworkflow/basicdetails"], {
        queryParams: { encrypted },
      });
    } else if (tab == 2) {
      this.router.navigate(["/views/difineworkflow/workflowrules"], {
        queryParams: { encrypted },
      });
    } else if (tab == 3) {
      this.router.navigate(["/views/difineworkflow/workflowparameters"], {
        queryParams: { encrypted },
      });
    } else if (tab == 4) {
      this.router.navigate(["/views/difineworkflow/workflowconditions"], {
        queryParams: { encrypted },
      });
    }
  }
}
interface Status {
  value: string;
  viewValue: string;
}
