import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../../services/encr-decr-service.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationSystem, DynamicNotificationSystem } from '../../../../model/Notification';
import { SystemadminService } from '../../../../services/systemadmin.service';
import { GeneralMaster } from '../../../../model/common';
import { MyOffice } from '../../../../model/systemadmin';
declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';

@Component({
  selector: 'app-notifysystem',
  templateUrl: './notifysystem.component.html',
  styleUrls: ['./notifysystem.component.css']
})
export class NotifysystemComponent implements OnInit {
    RefDateValues: RefDateType[] = [
        { value: '1', viewValue: 'BEFORE' },
        { value: '2', viewValue: 'AFTER' },
    ];
    ActiveValues: ActionType[] = [
        { value: '1', viewValue: 'Include' },
        { value: '2', viewValue: 'Exclude' },
    ];

    BasicID = 0;
    NotifyForm: FormGroup;
    OfficeValues: MyOffice[];
    DynamicNumberRange: Array<DynamicNotificationSystem> = [];
    val: any = {};
    NID = null;
    HDArrayIndex = '';
    EdiNID = 0;
    chkindex = "0";
    RangeID = 1;
    ValidID = 0;
    updateID = 0;
    EditID = 0;
    edID = 0;
    constructor(private router: Router, private route: ActivatedRoute, private sa: SystemadminService, private ns: NotificationService, private ES: EncrDecrServiceService, private fb: FormBuilder) { }

    ngOnInit(): void {
        $('.my-select').select2();
        this.createForm();

        var queryString = new Array();
        this.route.queryParams.subscribe(params => {
            var Parameter = this.ES.get(localStorage.getItem("EncKey"), params['encrypted']);
            var KeyPara = Parameter.split(',');
            for (var i = 0; i < KeyPara.length; i++) {
                var key = KeyPara[i].split(':')[0];
                var value = KeyPara[i].split(':')[1];
                queryString[key] = value;
            }

            if (queryString["ID"] != null) {

                this.BasicID = queryString["ID"].toString();

            }
            if (this.BasicID == 0) {
                Swal.fire({
                    title: 'Fill the Basic Details.',
                    showDenyButton: false,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfig']);
                    } else {

                    }
                });
                return false;
            }

            this.BindDropDown();
            this.BindExistingRecords(this.BasicID);
        });

    }

    createForm() {
        this.NotifyForm = this.fb.group({
            OfficeID: 0,
            UserID: '',
            ActionID: 0,
            RefDateID: 0,
            NoOfDays:''
        });
    }
    BindDropDown() {
        this.sa.GetOffice(this.NotifyForm.value).subscribe(data => {
            this.OfficeValues = data;
        });
    }

    AddRecord() {
        var validation = "";

        var ddlOffice = $('#ddlOffice').val();
        if (ddlOffice == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Office</span></br>"
        }
        if ($('#txtUserID').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter UserID</span></br>"
        }
       
        var ddlAction = $('#ddlAction').val();
        if (ddlAction == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Action</span></br>"
        }




        for (var i = 0; i < this.DynamicNumberRange.length; i++) {

            if (this.DynamicNumberRange[i].NID != this.NID) {
                if (this.DynamicNumberRange[i].OfficeID == $('#ddlOffice').val()
                    && (this.NID == null || this.NID != null)) {
                    validation += "<span style='color:red;'>*</span> <span>Office Already Exists</span></br>"
                }
            }
        }



        if (validation != "") {
            this.edID = 1;
            Swal.fire(validation);
            return false;
        }
        var NIDValue;

        this.updateID = 1;
        this.edID = 0;

        NIDValue = (this.NID == null) ? 0 : this.NID;
        this.val = {
            NID: NIDValue,
            OfficeID: $("#ddlOffice").val(),
            Office: $("#ddlOffice option:selected").text(),
            UserID: $("#txtUserID").val(),
            ActionID: $("#ddlAction").val(),
            Action: $("#ddlAction option:selected").text(),

        };

        if (this.HDArrayIndex != null && this.HDArrayIndex.length != 0) {
            this.DynamicNumberRange[this.HDArrayIndex] = this.val;
        } else {
            this.DynamicNumberRange.push(this.val);
        }

        $('#ddlOffice').val(0).trigger("change");
        $("#txtUserID").val("").trigger("change");
        $('#ddlAction').val(0).trigger("change");

        this.NID = 0;
        this.HDArrayIndex = '';
    }

    OnClickRadio(index, NID) {

        this.EdiNID = NID;
        this.chkindex = index;
    }
    EditRecord() {
        var checked = $("#datatable input[type=radio]:checked").length;
        if (this.DynamicNumberRange.length != 0) {
            if (checked == 0) {
                Swal.fire("Please Select Any one");
            }
            else {

                var NIDV = 0;
                NIDV = this.EdiNID;

                this.DynamicNumberRange[this.chkindex].NID = NIDV;
                this.HDArrayIndex = this.chkindex;
                this.NID = this.DynamicNumberRange[this.chkindex].NID;
                this.EdiNID = this.DynamicNumberRange[this.chkindex].NID;


                $("#ddlOffice").select2().val(this.DynamicNumberRange[this.chkindex].OfficeID).trigger("change");
                $("#txtUserID").val(this.DynamicNumberRange[this.chkindex].UserID);
                $("#ddlAction").val(this.DynamicNumberRange[this.chkindex].ActionID).trigger("change");

            }
        }
        else {
            Swal.fire("No Records Found");
        }
    }

    Onsubmit() {

        

        if (this.updateID == 0) {
            this.AddRecord();
        }
        

        if (this.edID == 0) {
            var ItemsDr = [];
            for (let item of this.DynamicNumberRange) {
                var intNID = 0;

                if (item.OfficeID != 0) {
                    if (typeof item.NID == "undefined")
                        intNID = 0;
                    else
                        intNID = item.NID;

                    ItemsDr.push('Insert:' + intNID, item.OfficeID, item.Office, item.UserID, item.ActionID, item.Action);
                }

            };
            this.NotifyForm.value.ItemsV = ItemsDr.toString();
            this.NotifyForm.value.BasicID = this.BasicID;

            if ($('#ddlRefDate').val() != "") {
                this.NotifyForm.value.RefDateID = $('#ddlRefDate').val();
            }
            else {
                this.NotifyForm.value.RefDateID = 0;
            }
            if ($('#txtNoOfDays').val() != "") {
                this.NotifyForm.value.NoOfDays = $('#txtNoOfDays').val();
            }
            else {
                this.NotifyForm.value.RefDateID = "";
            }

            this.ns.saveNotificationSystem(this.NotifyForm.value).subscribe(Data => {

                $('#ddlOffice').val(0).trigger("change");
                $("#txtUserID").val("").trigger("change");
                $('#ddlAction').val(0).trigger("change");

                Swal.fire("Record Saved SuccessFully");


                this.BindExistingRecords(this.BasicID);

                this.ValidID = 0;
                this.updateID = 0;
                this.edID = 0;
            },

                (error: HttpErrorResponse) => {
                    Swal.fire(error.message)
                });
            }
        
    }

    BindExistingRecords(idv) {

        this.NotifyForm.value.BasicID = idv;


        this.ns.GetExistingNotificationSystemDtls(this.NotifyForm.value).subscribe(data => {
            this.NotifyForm.patchValue(data[0]);

            $('#ddlRefDate').select2().val(data[0].RefDateID);

        },

            (error: HttpErrorResponse) => {
                Swal.fire(error.message)
            });


        this.ns.GetNotificationSystemDtls(this.NotifyForm.value).subscribe(data1 => {
            this.DynamicNumberRange.length = 0;
            if (data1.length > 0) {
                for (let item of data1) {

                    this.DynamicNumberRange.push({

                        'NID': item.NID,
                        'OfficeID': item.OfficeID,
                        'Office': item.Office,
                        'UserID': item.UserID,
                        'ActionID': item.ActionID,
                        'Action': item.Action

                    });
                }
            }
            else {

            }
        });


    }

    btntabclick(tab) {

        var values = "ID: " + this.BasicID;

        var encrypted = this.ES.set(localStorage.getItem("EncKey"), values);


        if (tab == 1) {
            this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfig'], { queryParams: { encrypted } });
        }
        else if (tab == 2) {
            this.router.navigate(['/views/systemadmin/notificationconfig/notifymail/notifymail'], { queryParams: { encrypted } });
        }
        else if (tab == 3) {
            this.router.navigate(['/views/systemadmin/notificationconfig/notifysystem/notifysystem'], { queryParams: { encrypted } });
        }

    }

}
interface RefDateType {
    value: string;
    viewValue: string;
}
interface ActionType {
    value: string;
    viewValue: string;
}