import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../../services/encr-decr-service.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationBasic } from '../../../../model/Notification';
import { SystemadminService } from '../../../../services/systemadmin.service';
import { GeneralMaster } from '../../../../model/common';
import { PaginationService } from 'src/app/pagination.service';
declare let $: any;
@Component({
  selector: 'app-notificationconfigview',
  templateUrl: './notificationconfigview.component.html',
  styleUrls: ['./notificationconfigview.component.css']
})
export class NotificationconfigviewComponent implements OnInit {
    private allItems: any[];
    pager: any = {};
    pagedItems: any[];
    searchForm: FormGroup;
    constructor(private router: Router, private route: ActivatedRoute, private ns: NotificationService, public ps: PaginationService, private fb: FormBuilder, private ES: EncrDecrServiceService) { }

    ngOnInit(): void {
        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id

            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        })
        this.createForm();

        this.clearSearch();
        this.NotificationBasicList();
    }

    createForm() {
        this.searchForm = this.fb.group({
            ID: 0,
            NotificationNumber: '',
            NotificationName: '',
            Description: '',
            Application: '',
            Module: '',
            IsActive: 0,
          
        });
    }
    NotificationBasicList() {

        this.ns.getNotificaionBasicView(this.searchForm.value).subscribe(data => {
            this.allItems = data;
            this.setPage(1);
        });
    }
    OnClickEdit(IDv) {

        var values = "ID: " + IDv;
        var encrypted = this.ES.set(localStorage.getItem("EncKey"), values);
        this.router.navigate(['/views/systemadmin/notificationconfig/notificationconfig'], { queryParams: { encrypted } });

    }
    onSubmit() {

        this.searchForm.value.NotificationNumber = $('#txtNotificationNumber').val();
        this.searchForm.value.NotificationName = $('#txtNotificationName').val();
        this.searchForm.value.Description = $('#txtDescription').val();
        this.searchForm.value.Application = $('#txtApplication').val();
        this.searchForm.value.Module = $('#txtModule').val()
        this.searchForm.value.IsActive = $('#ddlActive').val()
       
        this.NotificationBasicList();

    }
    clearSearch() {
        this.createForm();
        $('#txtNotificationNumber').val('');
        $('#txtNotificationName').val('');
        $('#txtDescription').val('');
        $('#txtApplication').val('');
        $('#txtModule').val('');
        $('#ddlActive').val(0).trigger("change");
      
        this.NotificationBasicList();

    }
    setPage(page: number) {
        //if (page < 1 || page > this.pager.totalPages) {
        //    return;
        //}

        // get pager object from service
        this.pager = this.ps.getPager(this.allItems.length, page);

        // get current page of items
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }

    isDesc: boolean = false;
    column: string = '';
    sort(property) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.pagedItems.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    };
}
