import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-difineworkflow',
  templateUrl: './difineworkflow.component.html',
  styleUrls: ['./difineworkflow.component.css']
})
export class DifineworkflowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
