

import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import Swal from 'sweetalert2';
import { WorkflowService } from 'src/app/services/workflow.service';
import { PaginationService } from 'src/app/pagination.service';
import { EncrDecrServiceService } from 'src/app/services/encr-decr-service.service';
import { MyCompany, MySACommon } from 'src/app/model/systemadmin';
import { Country, DynamicPDF1, MyAgency } from 'src/app/model/common';
import { Globals } from 'src/app/globals';
import { Title } from '@angular/platform-browser';
import { CustomerContract } from 'src/app/model/CustomerContract';
import { formatCurrency } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-defineorgroles',
  templateUrl: './defineorgroles.component.html',
  styleUrls: ['./defineorgroles.component.css']
})
export class DefineorgrolesComponent implements OnInit {

  myControl = new FormControl('');
  title = 'Define Org.Roles ';
  currentTab: any;
  service: any;
  deleteId: any;
  private id: any;
  eventnumber: any;
  complianceRowId: number;
  pageSection: number;
  i: number = -1;
  containerList: any = [];
  addrolelist: any = [];
  isEdit: boolean = false;
  rolenumber: any;
  addnewlist: any = [];
  conditionlist:any =[]

  changeTab(tabName) {
    this.currentTab = tabName;
  }

  statusvalues: Status[] = [
    { value: '1', viewValue: 'Yes' },
    { value: '2', viewValue: 'No' },
  ];
  aclFilename: DynamicPDF1[];
  addItem: any = { Data: [] };
  ApplicationItem: any = { Data: [] };
  deleteItem: any = { Data: [] };
  roleItems: any = { Data: [] };




  constructor(private http: HttpClient, private globals: Globals,
    private ms: MastersService, private router: Router,
    private route: ActivatedRoute, private fb: FormBuilder,
    public ps: PaginationService, public wf: WorkflowService, private titleService: Title) {
    this.roleForm = this.fb.group(

      {
        id: 0,
        rolenumber: '',
        rolename: '',
        description: '',
        application: '',
        // active: true,
        api: '',

      });
  }

  // array of all items to be paged
  private allItems: any = { Data: [] };

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  DepartmentList: any = [];
  dataSource: Country[];
  roleForm: FormGroup;


  ActivateAddEditDepComp: boolean = false;
  dep: any;

  DepartmentIdFilter: string = "";
  DepartmentNameFilter: string = "";
  DepartmentListWithoutFilter: any = [];

  ngOnInit(): void {
    this.currentTab = 'basics'
this.getcondition()



    this.titleService.setTitle(this.title);
    $('.my-select').select2();
    $(document).on('select2:open', (e) => {
      const selectId = e.target.id

      $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
        key,
        value,
      ) {
        value.focus();
      })
    })
    $('#ddlApplication').on('select2:select', (e, args) => {
      this.ApplicationItem($("#ddlApplication").val());
    });

    // this.newForm();
    this.getRoleList();
    // this.route.queryParams.subscribe(
    //   params => {
    //     console.log('params', params.eventNumber)
    //     this.getRoleByNumber(params.eventNumber)
    //   }
    // )




  }

  // getRoleByNumber(eventNumber: any) {
  //   this.wf.getRoleByNumber(eventNumber).subscribe((data: any) => {
  //     // set items to json response
  //     // console.log('getDefineListByNumber',data?.Data[0]);
  //     this.roleItems = data;
  //     // if(Array.isArray(data?.Data) && data?.Data.length>0){
  //     //   this.basicForm.patchValue({
  //     //     ...data?.Data[0]
  //     //   })
  //     // }
  //   });
  // }

  getRoleList() {
    // console.log("addContainerToList", this.roleForm.value);

    this.wf.getRoleList().subscribe((data: any) => {
      if (Array.isArray(data?.Data)) {
        let roles = data?.Data?.filter(val => val.eventnumber == this.eventnumber)
        this.addrolelist = roles;
      }
      else{
        this.addrolelist = [];
      }
      console.log("getRoleList", data);
      this.setPage(1)

      // this.roleItems = data;
      // console.log("getRoleList", data);
      // this.setPage(1);

    },error=>{
      console.log(error);
      this.addrolelist = [];
      this.setPage(1)

      
    });

  }
  addRoleLists() {
    if (this.isEdit)
      return
    console.log("addRoleList", this.roleForm.value);
    var validation = "";
    if (this.roleForm.value.rolename == "") {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Role Name</span></br>"
    }
    if (this.roleForm.value.description == "") {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Description</span></br>"
    }
    if (this.roleForm.value.application == "") {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Application</span></br>"
    }
    if (this.roleForm.value.api == "") {
      validation += "<span style='color:red;'>*</span> <span>Please Enter Api </span></br>"
    }

    if (validation != "") {
      Swal.fire(validation)
      return false;
    }

    let finddata = this.addrolelist.find((val) => val.rolename.toLowerCase()?.replace(/\s+/g,'')  == this.roleForm.value?.rolename.toLowerCase()?.replace(/\s+/g,'') )
    if(finddata){
      Swal.fire("Param Name Already Exits")
      return
    }
    this.addrolelist.push(this.roleForm.value)
    this.addnewlist.push(this.roleForm.value)

    this.roleForm.reset()
    //MULTIPLE ADD CODE
    this.roleForm.patchValue({ id: 0, rolenumber: ''})
  }
  
  editRoleList() {

    if(  !this.id || this.i<=-1){
      this.isEdit =false;
      return
    }
    this.isEdit = true;
    let data = this.addrolelist[this.i]
    this.roleForm.patchValue(data)
    console.log("editRoleList", this.roleForm.value,this.isEdit);
  }


  deleteRole() {
   console.log("deleteContainer", this.id , this.i);

    if(!this.isEdit && this.id && this.i>-1){
// if()
      console.log("this.id", this.id,this.addrolelist[this.i])
      this.wf.deleteRole({ id: this.id,rolename:this.addrolelist[this.i].rolename}).subscribe((data:any) => {
        if(data?.Data[0]?.AlertMegId == -1){
          Swal.fire("This Org Role is used event")
          return
        }
        this.deleteItem = data;
        this.addrolelist.splice(this.i,1) 
        this.setPage(1)
        Swal.fire('Deleted successfully');
    // if(data?.Data[]?.AlertMegId == -1){

    // }
        // this.getRuleByNumber(this.eventnumber)
        // this.ngOnInit();
        // this.getRoleList();
        this.i=-1
        this.id=null
      });
    }
    
    else if(!this.id && this.i>-1){
      this.addrolelist.splice(this.i,1) 
        this.setPage(1)
        console.log("deleteContainer", this.id , this.i,this.addrolelist);

    }

  
  }

  onChange(data, i: number) {
    if(this.isEdit){
      return;
    }
   
    this.id = data;

    this.i = i;
  }

  onSubmit() {
    if (this.isEdit ) {
      if(this.id && this.i>-1){
        var validation = "";
        if (this.roleForm.value.rolename == "") {
          validation += "<span style='color:red;'>*</span> <span>Please Enter Role Name</span></br>"
        }
        if (this.roleForm.value.description == "") {
          validation += "<span style='color:red;'>*</span> <span>Please Enter Description</span></br>"
        }
        if (this.roleForm.value.application == "") {
          validation += "<span style='color:red;'>*</span> <span>Please Enter Application</span></br>"
        }
        if (this.roleForm.value.api == "") {
          validation += "<span style='color:red;'>*</span> <span>Please Enter Api </span></br>"
        }
    
        if (validation != "") {
          Swal.fire(validation);
          return false;
        }
        let findIndex :number = -1;

        let findItem = this.addrolelist.find((item,index:number) => {
          if(item.rolename?.toLowerCase()  == this.roleForm.value?.rolename?.toLowerCase() ){
            findIndex = index;
            return item
          }
        });

        if(this.addrolelist[this.i].rolename?.toLowerCase()?.replace(/\s+/g,'')   != this.roleForm.value?.rolename?.toLowerCase() ?.replace(/\s+/g,'') ){
       
          if(findItem){
            Swal.fire("Role Name Already Exits")
            return
          }
  
        }
  else{
    if(findIndex != this.i && findIndex ){
      Swal.fire("Role Name Already Exits")
      return
    }
    console.log("loggss",findIndex,findItem)
  }
      this.wf.addRoleList([this.roleForm.value]).subscribe(data => {
        // set items to json response
        this.roleItems = data;
        console.log("editRoleList", data);
        // initialize to page 1
        this.roleForm.reset();
        Swal.fire('Update successfully');
      //   Swal.fire({
      //     title: "",
      //     text: 'Saved successfully',       
      // }).then(function() {
      //   console.log('root.roleForm',root.roleForm.value)
      //   if(root.roleForm.value)
      //    window.location.reload();
      //   //  this.getEventByNumber(root.roleForm?.value?.eventnumber)
      // });
      // this.ngOnInit();
        // this.setPage(1);

        this.getRoleList()
        this.isEdit = false;
        this.i=-1
        this.id=null
        this.roleForm.reset()
        this.roleForm.patchValue({ id: 0, rolenumber: ''})

      });
      return;
    }
    return
  }
    // var validation = "";
    // if (this.roleForm.value.rolename == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Role Name</span></br>"
    // }
    // if (this.roleForm.value.description == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Description</span></br>"
    // }
    // if (this.roleForm.value.application == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Application</span></br>"
    // }
    // if (this.roleForm.value.api == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Api </span></br>"
    // }

    // if (validation != "") {
    //   Swal.fire(validation);
    //   return false;
    // }
    let root = this;

    // let data: any = this.roleForm.value
   let data = this.addrolelist;
    // data = this.addnewlist;
    this.wf.addRoleList(data).subscribe(data => {
      // this.roleItems = data;
      this.roleForm.reset()
       //MULTIPLE ADD CODE
       this.isEdit = false;
       this.i=-1
       this.id=null
      this.getRoleList()   
        this.roleForm.patchValue({ id: 0, rolenumber: ''})
    //   console.log("addRoleList", data);
      Swal.fire('Saved successfully');
    });

  }

  public selectedName: any;
  public highlightRow(dataItem) {
    this.selectedName = dataItem.CountryCode;
  }
  setPage(page: number) {
    //if (page < 1 || page > this.pager.totalPages) {
    //    return;
    //}

    // get pager object from service
    this.pager = this.ps.getPager(this.roleItems.length, page);

    // get current page of items
    //this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  // pdflink() {

  //   this.ms.getPDF(this.searchForm.value).subscribe(data => {
  //     console.log(data[0].aclFilename);
  //     window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
  //   });


  // }

  isDesc: boolean = false;
  column: string = '';
  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.pagedItems.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  getcondition() {
    this.wf
      .getconditionByNumber(this.eventnumber?.eventnumber)
      .subscribe((data: any) => {
        console.log(data,'array')

        if (Array.isArray(data?.Data) && data?.Data.length > 0) {
          this.conditionlist = data?.Data || [];
        }
      });

  }

}
interface Status {
  value: string;
  viewValue: string;
}





























