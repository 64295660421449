import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../services/encr-decr-service.service';
import { useraccess } from '../../../model/useraccessdata';
import { UseraccessService } from '../../../services/useraccess.service';
import { DynamicNotificationEmail, MyOffice } from '../../../model/systemadmin';
import { SystemadminService } from '../../../services/systemadmin.service';
import { PaginationService } from '../../../pagination.service';
declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';
import { isDataSource } from '@angular/cdk/collections';
import { Key } from 'protractor';
import { NumberGeneratorService } from '../../../services/number-generator.service';
import { ReportpermissionService } from '../../../services/reportpermission.service';
@Component({
  selector: 'app-reportpermissionview',
  templateUrl: './reportpermissionview.component.html',
  styleUrls: ['./reportpermissionview.component.css']
})
export class ReportpermissionviewComponent implements OnInit {
    ReportForm: FormGroup;
    LandingAppValues: LandingAppValues[] = [
        { value: '1', viewValue: 'MANAGEMENT DASHBOARD' },
        { value: '2', viewValue: 'LINER AGENCY' },
        { value: '3', viewValue: 'FREIGHT FORWARDING' },
        { value: '4', viewValue: 'FINANCE' },
        { value: '5', viewValue: 'STYSTEM ADMIN' },
    ];
    FilterValues: FilterTypes[] = [
        { value: '1', viewValue: 'Menu' },
        { value: '2', viewValue: 'Sub-Menu' },
        { value: '3', viewValue: 'Function' },
        { value: '4', viewValue: 'Sub-Function' },
    ];
    private allItems: any[];

    // pager object
    pager: any = {};

    // paged items
    pagedItems: any[];
    disableFilter = false;
    disableTxtSearch = false;
    disableSearchbtn = false;
    disableClearbtn = false;
    disableSavebtn = false;
    disableCancelbtn = false;
    filtervalue = "";
    constructor(private router: Router, private ES: EncrDecrServiceService, public ps: PaginationService, private route: ActivatedRoute, private sa: SystemadminService, private rp: ReportpermissionService, private fb: FormBuilder, private numberGeneratorService: NumberGeneratorService) { }

    ngOnInit(): void {
        this.createForm();
        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id

            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        });
        //this.clearSearch();
        $('#ddlLandingApp').on('select2:select', (e, args) => {
            this.OnchangeLandingApp($('#ddlLandingApp').val());
        });
        
        //$('#ddlFilterC').on('select2:select', (e, args) => {
        //    this.OnchangeFilterApp($('#ddlFilterC').val());
        //});
        //this.BindProfileList();
    }
    OnchangeLandingApp(landingapp) {
        this.ReportForm.value.LandingAppID = landingapp;
        if (landingapp != 0 || landingapp != undefined) {
            this.disableFilter = false;
            this.disableTxtSearch = false;
            this.disableSearchbtn = false;
            this.disableClearbtn = false;
            this.disableSavebtn = false;
            this.disableCancelbtn = false;
            this.ReportForm.value.MenuName = "";
            this.ReportForm.value.SubMenuName = "";
            this.ReportForm.value.FunctionName = "";
            this.ReportForm.value.SubFunctionName = ""; 
            this.BindProfileList();
           
        }
        else {
            this.disableFilter = true;
            this.disableTxtSearch = true;
            this.disableSearchbtn = true;
            this.disableClearbtn = true;
            this.disableSavebtn = true;
            this.disableCancelbtn = true;
            this.ReportForm.value.MenuName = "";
            this.ReportForm.value.SubMenuName = "";
            this.ReportForm.value.FunctionName = "";
            this.ReportForm.value.SubFunctionName = ""; 
            this.BindProfileList();
            Swal.fire("Record Not Found");
            this.pagedItems.length = 0;
        }

    }

    createForm() {
        this.ReportForm = this.fb.group({
            LandingAppID: 0

        });
    }

    BindProfileList() {
        this.rp.GetReportPermssionView(this.ReportForm.value).subscribe(data => {
            this.allItems = data;
            this.setPage(1);
        });
    }
    //OnchangeFilterApp(filterid) {
    //    if (filterid = 1) {
    //        this.filtervalue = "MenuName";
    //    }
    //    else if (filterid = 2) {
    //        this.filtervalue = "Sub_Menu";
    //    }
    //    else if (filterid = 3) {
    //        this.filtervalue = "Function_Name";
    //    }
    //    else if (filterid = 4) {
    //        this.filtervalue = "Sub_Function_Name";
    //    }
    //}
    onSubmitSearch() {
        this.ReportForm.value.LandingAppID = $('#ddlLandingApp').val();
        /*if ($('#ddlFilterC').val() == 1) {*/
            this.ReportForm.value.MenuName = $('#txtSearch').val()
        //}
        //else if ($('#ddlFilterC').val() == 2) {
            this.ReportForm.value.SubMenuName = $('#txtSearch').val()
        //}
        //else if ($('#ddlFilterC').val() == 3) {
            this.ReportForm.value.FunctionName = $('#txtSearch').val()
        //}
        //else if ($('#ddlFilterC').val() == 4) {
            this.ReportForm.value.SubFunctionName = $('#txtSearch').val()
        //}
        this.BindProfileList();
    }

    clearSearch() {
        this.ReportForm.value.MenuName = "";
        this.ReportForm.value.SubMenuName = "";
        this.ReportForm.value.FunctionName = "";
        this.ReportForm.value.SubFunctionName = ""; 
        $('#ddlFilterC').val(0).trigger("change");
        $('#txtSearch').val('');
        this.BindProfileList();
    }
    OnSelectRow(dataItem: any) {
        console.log(dataItem);
        let value = encodeQueryParam(dataItem);
        this.router.navigate(['/views/reportpermission/reportpermission'], { queryParams: { value } });
        localStorage.setItem("screen", JSON.stringify(false));
    }
    public selectedName: any;
    public highlightRow(dataItem) {
        this.selectedName = dataItem.CountryCode;
    }
    setPage(page: number) {
        //if (page < 1 || page > this.pager.totalPages) {
        //    return;
        //}

        // get pager object from service
        this.pager = this.ps.getPager(this.allItems.length, page);

        // get current page of items
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
    isDesc: boolean = false;
    column: string = '';
    sort(property) {
        this.isDesc = !this.isDesc;
        this.column = property;
        let direction = this.isDesc ? 1 : -1;

        this.pagedItems.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    };
}
interface LandingAppValues {
    value: string;
    viewValue: string;
}
interface FilterTypes {
    value: string;
    viewValue: string;
}
export function encodeQueryParam(value: any): string {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(value));
    let binary = '';
    const len = data.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(data[i]);

    }
    console.log(btoa(binary));
    return btoa(binary);

}