import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { EncrDecrServiceService } from '../../../../services/encr-decr-service.service';
import { useraccess, Division, DivisionLable, DivisionTypes, Customer } from '../../../../model/useraccessdata';
import { UseraccessService } from '../../../../services/useraccess.service';
import { DynamicNotificationEmail, MyOffice, MySACommon } from '../../../../model/systemadmin';
import { SystemadminService } from '../../../../services/systemadmin.service';


declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {


    RoleForm: FormGroup;
    getValue: any = "";
    /* OfficeValues: MyOffice[];*/
    OfficeValues: MySACommon[];
    LandingValues: LandingAppType[] = [
        { value: '1', viewValue: 'MANAGEMENT DASHBOARD' },
        { value: '2', viewValue: 'LINER AGENCY' },
        { value: '3', viewValue: 'FREIGHT FORWARDING' },
        { value: '4', viewValue: 'FINANCE' },
        { value: '5', viewValue: 'SYSTEM ADMIN' },
    ];
    ActiveValues: UserActiveType[] = [
        { value: '1', viewValue: 'YES' },
        { value: '2', viewValue: 'NO' },
    ];
    RoleDropDownList = [];
    RoleDropDownListOnChange = [];
    ref_Role_Id = "";
    Rolename = "";
    Role_Description = "";
    Assignment_Remarks = "";
    SalesPerson = 0;
    Division = 0;
    Office = 0;
    LandingApp = 0;
    NID = null;
    HDArrayIndex = '';
    //UpdateID = 0;
    EdiNID = 0;
    chkindex = "0";
    RangeID = 1;
    ValidID = 0;
    updateID = 0;
    EditID = 0;
    edID = 0;
    val: any = {};
    DynamicRoleList = [];
    RoleExistingDtls = [];
    rolevalueid = "";
    //userId = "";
    //userName = "";
    //userType = "";
    //division_Id = "";
    //divisionName = "";
    //office_Id = "1";
    //office = "1";
    //locked = "1";
    //ref_Role_Id = "";
    //ref_Application = 0;
    //role_Active = 0;
    //is_Active = 0;
    //is_Delete = 0;
    //created_by = 0;
    //modified_by = 0;
    arrayval: any = "";
    rolerefid = "";
    FillDivisionMaster: DivisionLable[] = [];

    divisionMaster: DivisionTypes = new DivisionTypes();
    selectedDivisionValues: string[] = [];
    selectedValues: string[] = [];

    public showSingleDivision = true;
    public showMultiDivision = true;
    public showSingleOffice = true;
    public showMultiOffice = true;
    public showSalesRes = true;
    payload: any = "";
    roleidv = 0;
    payload1: any = "";
    deleteid = 0;
    EditIDv = 0;
    DynamicUserPerObject = [];
    public chkaction: string = "Yes";
    public unchkaction: string = "No";
    public disableaction: string = "Disabled";
    public Active: string = "Yes";
    public InActive: string = "No";
    SinglDivByFFD: DivisionLable[] = [];
    /* SinglDivByFFD = [];*/
    SinglDivByLA = [];
    multidivisionname = "";
    multidivisionid = "";
    multiofficeid = "";
    multiofficename = "";
    existingmultidivi = "";
    existingmultidiviName = "";
    existingmultiofficeid = ""
    existingmultioffName = "";
    OfficeMulti = "";
    landinappid = 0;
    rolListLength = 0;
    landingappidnew = 0;
    landinappidnew1 = 0;
    divselectedValue: any;
    public ControlDisplay: boolean = true;
    payloadRoles: any = "";
    constructor(private router: Router, private route: ActivatedRoute, private sa: SystemadminService, private us: UseraccessService, private fb: FormBuilder) {

        this.route.queryParams.subscribe(param => {
            if (param['value']) {

                this.getValue = decodeQueryParam(param['value']);

                if (this.getValue == "") {
                    Swal.fire({
                        title: 'Fill Profile Details',
                        showDenyButton: false,
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.router.navigate(['/views/systemadmin/user/profile/profile']);
                        } else {

                        }
                    });

                    return false;
                }
                console.log(this.getValue.role_Id);
            }
        })

    }
    ngOnInit(): void {

        this.createForm();

        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id

            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        });


        

        $('#ddlRoleID').on('select2:select', (e, args) => {

            this.OnChangeRoleId($('#ddlRoleID').val());


        });
        this.BindDropDown();

        $('#ddlLandingAppU').on('select2:select', (e, args) => {
            this.landinappid = $('#ddlLandingAppU').val();
            if (this.landinappid == 0) {
                this.BindExistingRecords();
                this.showMultiDivision = true;
                this.showSingleDivision = true;
                this.showSingleOffice = true;
                this.showMultiOffice = true;
                this.showSalesRes = true;
                this.ControlDisplay = false;
            }
            if (this.landinappid == 1) {
                this.BindDropDown();
                this.BindExistingRecords();
                this.showMultiDivision = false;
                this.showSingleDivision = true;
                this.showSingleOffice = true;
                this.showMultiOffice = false;
                this.showSalesRes = true;
                this.ControlDisplay = false;

            }
            if (this.landinappid == 2) {
                this.BindDropDown();
                this.BindExistingRecords();
                this.showMultiDivision = true;
                this.showSingleDivision = false;
                this.showSingleOffice = true;
                this.showMultiOffice = false;
                this.showSalesRes = false;


                let payloaddivla = {

                    "Id": 1,

                }
                this.us.GetDivisionByFFD(payloaddivla).subscribe(res => {
                    this.RoleForm.patchValue({
                        division: res?.data?.Division[0].DivisionCode

                    })


                });



            }

            if (this.landinappid == 3) {

                this.BindDropDown();
                this.showMultiDivision = true;
                this.showSingleDivision = false;
                this.showSingleOffice = true;
                this.showMultiOffice = false;
                this.showSalesRes = false;
                this.BindExistingRecords();
                let payloaddivffd = {

                    "Id": 2,

                }
                this.us.GetDivisionByFFD(payloaddivffd).subscribe(res => {
                    console.log(res?.data?.Division[0].DivisionName);
                    //this.SinglDivByFFD = res?.data?.Division;
                    this.RoleForm.patchValue({
                        division: res?.data?.Division[0].DivisionCode

                    })

                });



            }

            if (this.landinappid == 4) {

                this.BindDropDown();
                this.BindExistingRecords();
                this.showMultiDivision = false;
                this.showSingleDivision = true;
                this.showSingleOffice = true;
                this.showMultiOffice = false;
                this.showSalesRes = true;
                this.ControlDisplay = false;

            }
            if (this.landinappid == 5) {
                this.BindDropDown();
                this.BindExistingRecords();
                this.showMultiDivision = true;
                this.showSingleDivision = true;
                this.showSingleOffice = true;
                this.showMultiOffice = true;
                this.showSalesRes = true;
                this.ControlDisplay = false;
            }


            //if (this.landinappid == 2) {
            //    this.BindExistingRecords();
            //    this.showMultiDivision = true;
            //    this.showSingleDivision = false;
            //    this.showSingleOffice = true;
            //    this.showMultiOffice = false;
            //    this.showSalesRes = false;


            //    let payloaddivla = {

            //        "Id": 1,

            //    }
            //    this.us.GetDivisionByFFD(payloaddivla).subscribe(res => {
            //        this.SinglDivByFFD = res.data.Division;

            //    });
            //}
            //else {
            //    alert('list 0');
            //    this.DynamicRoleList.length = 0;
            //}


        });
       
    }
    //OnChangeLandingApp(appid) {
    //    this.landinappid = appid;

    //    if (this.landinappid == 0) {
    //        this.showMultiDivision = true;
    //        this.showSingleDivision = true;
    //        this.showSingleOffice = true;
    //        this.showMultiOffice = true;
    //        this.showSalesRes = true;
    //    }
    //    if (this.landinappid == 1) {


    //        this.showMultiDivision = false;
    //        this.showSingleDivision = true;
    //        this.showSingleOffice = true;
    //        this.showMultiOffice = false;
    //        this.showSalesRes = true;

    //    }
    //    if (this.landinappid == 2) {

    //        this.showMultiDivision = true;
    //        this.showSingleDivision = false;
    //        this.showSingleOffice = true;
    //        this.showMultiOffice = false;
    //        this.showSalesRes = false;


    //        let payloaddivla = {

    //            "Id": 1,

    //        }
    //        this.us.GetDivisionByFFD(payloaddivla).subscribe(res => {
    //            this.SinglDivByFFD = res.data.Division;

    //        });

    //    }
    //    if (this.landinappid == 3) {

    //        this.showMultiDivision = true;
    //        this.showSingleDivision = false;
    //        this.showSingleOffice = true;
    //        this.showMultiOffice = false;
    //        this.showSalesRes = false;
    //        let payloaddivffd = {

    //            "Id": 2,

    //        }
    //        this.us.GetDivisionByFFD(payloaddivffd).subscribe(res => {
    //            this.SinglDivByFFD = res.data.Division;

    //        });
    //    }
    //    if (this.landinappid == 4) {



    //        this.showMultiDivision = false;
    //        this.showSingleDivision = true;
    //        this.showSingleOffice = true;
    //        this.showMultiOffice = false;
    //        this.showSalesRes = true;

    //    }
    //    if (this.landinappid == 5) {

    //        this.showMultiDivision = true;
    //        this.showSingleDivision = true;
    //        this.showSingleOffice = true;
    //        this.showMultiOffice = true;
    //        this.showSalesRes = true;
    //    }
    //}
    OnChangeRoleId(roleid) {
        /*  this.DynamicRoleList.length = 0;*/
     

        this.payload = {

            "Id": roleid,
            "division_Id": 0,
            "landing_Application_Id": 0,
            "office_Id": 0,
            "is_Active": 1
        }


        this.us.GetRoleDropDownListOnChange(this.payload).subscribe(res => {
            this.RoleDropDownListOnChange = res.data.UserRoles;

            for (let i = 0; i < this.RoleDropDownListOnChange.length; i++) {
                console.log("RoleName", this.RoleDropDownListOnChange[i].Role_Name);
                /*this.rolevalueid = this.RoleDropDownListOnChange[i].Id;*/
                this.Rolename = this.RoleDropDownListOnChange[i].Role_Name;
                this.Role_Description = this.RoleDropDownListOnChange[i].Role_Description;
                this.Assignment_Remarks = this.RoleDropDownListOnChange[i].Assignment_Remarks;
            }

        });

        //this.BindDropDown();
        //this.payload = {

        //    "Id": 0,
        //    "division_Id": 0,
        //    "office_Id": 0,
        //    "is_Active": 1
        //}
        //alert(this.getValue.User_Id);
        //if (this.getValue.User_Id == undefined) {
        //    alert('test');
        //    Swal.fire({
        //        title: 'Fill Profile Details',
        //        showDenyButton: false,
        //        confirmButtonText: 'OK',
        //        allowOutsideClick: false,
        //    }).then((result) => {
        //        if (result.isConfirmed) {
        //            this.router.navigate(['/views/systemadmin/user/userview/userview']);
        //        } else {

        //        }
        //    });

        //    return false;
        //}

    }
    BindDropDown() {
      
        //this.us.GetOffice().subscribe(res => {
        //    this.OfficeValues = res.data.Office;
        //});

        this.us.GetOrgOffice(this.RoleForm.value).subscribe(res => {
            this.OfficeValues = res;
        });



        let payloaddivision = {

            "Id": 0

        }
        this.us.GetDivisionDropDown(payloaddivision).subscribe(res => {
            this.FillDivisionMaster = res.data.Division;
            console.log("UserRoles", this.FillDivisionMaster);
        });


        this.payload1 = {

            "Id": 0,
            "division_Id": 0,
            "office_Id": 0,
            "is_Active": 1,
            "Landing_Application_Id": this.landinappid
        }
        this.us.GetRoleDropDownList(this.payload1).subscribe(res => {
            this.RoleDropDownList = res.data.UserRoles;
            console.log("UserRoles", this.RoleDropDownList);
        });


    }





    AddRecord() {
        var validation = "";

        var ddlRoleID = $('#ddlRoleID').val();
        if (ddlRoleID == null || ddlRoleID == 0) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Role ID</span></br>"
        }
        for (var i = 0; i < this.DynamicRoleList.length; i++) {

            if (this.DynamicRoleList[i].Ref_RoleId == $("#ddlRoleID").val()) {
                validation += "<span style='color:red;'>*</span> <span>Role ID Already Exists</span></br>"
            }

        }

        if (validation != "") {
            this.edID = 1;
            Swal.fire(validation);
            return false;
        }
        var NIDValue;

        this.updateID = 1;
        this.edID = 0;
        var ref_Role_Id = "";
        var Ref_RoleId = "";
        var Role_Des = "";
        var Role_Name = "";
        var Ass_Remarks = "";
        NIDValue = (this.NID == null) ? 0 : this.NID;
        this.val = {
            Id: this.EdiNID,
            Ref_RoleId: $("#ddlRoleID").val(),
            ref_Role_Id: $("#ddlRoleID option:selected").text(),
            Role_Name: this.Rolename,
            Role_Des: this.Role_Description,
            Ass_Remarks: this.Assignment_Remarks,


        };

        if (this.HDArrayIndex != null && this.HDArrayIndex.length != 0) {
            this.DynamicRoleList[this.HDArrayIndex] = this.val;
        } else {
            this.DynamicRoleList.push(this.val);

        }
        console.log("dynamiclist", this.DynamicRoleList);
        //let payload = {

        //    "Id": 0,
        //    "division_Id": 0,
        //    "office_Id": 0,
        //    "is_Active": 1
        //}
        $("#ddlRoleID").select2().val(0).trigger("change");
        $("#txtRoleName").val('').trigger("change");
        $("#txtRoleDesc").val('').trigger("change");
        $("#txtAssRem").val('').trigger("change");
        this.BindDropDown();
        //this.OnChangeRoleId(0);
        //let payload = {

        //    "Id": 0,
        //    "division_Id": 0,
        //    "office_Id": 0,
        //    "is_Active": 1
        //}
        //console.log($("#ddlRoleID").val());
        this.NID = 0;
        this.HDArrayIndex = '';
    }


    OnClickRadio(index, NID) {

        this.EdiNID = NID;

        this.chkindex = index;
    }
    EditRecord() {



        var checked = $("#datatable input[type=radio]:checked").length;
        if (this.DynamicRoleList.length != 0) {
            if (checked == 0) {
                Swal.fire("Please Select Any one");
            }
            else {

                var NIDV = 0;
                NIDV = this.EdiNID;

                this.DynamicRoleList[this.chkindex].NID = NIDV;
                this.HDArrayIndex = this.chkindex;
                this.NID = this.DynamicRoleList[this.chkindex].NID;
                this.EdiNID = this.DynamicRoleList[this.chkindex].NID;


                $("#ddlRoleID").select2().val(this.DynamicRoleList[this.chkindex].Ref_RoleId).trigger("change");
                $("#txtRoleName").val(this.DynamicRoleList[this.chkindex].Role_Name).trigger("change");
                $("#txtRoleDesc").val(this.DynamicRoleList[this.chkindex].Role_Des).trigger("change");
                $("#txtAssRem").val(this.DynamicRoleList[this.chkindex].Ass_Remarks).trigger("change");
                this.OnChangeRoleId($('#ddlRoleID').val());
                //alert($('#ddlRoleID').find(":selected").text().trim());


            }

        }
        else {
            Swal.fire("No Records Found");
        }
    }

    DeleteRecord(chkind, chkdlt) {
        chkdlt = this.EdiNID;
        chkind = this.chkindex;

        var checked = $("#datatable input[type=radio]:checked").length;

        if (checked == 0) {
            Swal.fire("Please check atleast one ");
        }
        else {


            Swal.fire({
                title: 'Are you sure want to Delete ?',
                showDenyButton: true,
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {

                    this.deleteid = 1;
                    this.OnSubmit();
                    this.DynamicRoleList.splice(chkind, 1);


                    document.querySelectorAll('.checkbox').forEach(_radio => {
                        (<HTMLInputElement>_radio).checked = false;
                    });

                } else {

                }
            });



        }
    }
    createForm() {

        this.RoleForm = this.fb.group({

            id: 0,
            landing_Application: 0,
            //division_Id: [this.getValue ? this.getValue.division_Id : ""],
            //office_Id: [this.getValue ? this.getValue.Office_Id : ""],
            DiviName: '',
            OfficeMulti: '',
            division: '',
            office_Id: 0,
            sales_Person: 1,
            Ref_RoleId:0

        });

    }
    selectAllSingle() {
        let SingleDivisionTypes: any = []
        this.SinglDivByFFD.map((item: any) => {

            SingleDivisionTypes.push(item?.ID)
        })
        this.RoleForm.get("DiviName").setValue(SingleDivisionTypes);
        console.log("SelectAllDivision", this.RoleForm.value.division);
    }

    unselectAllSingle() {
        this.RoleForm.get("division").setValue([]);
    }
    selectAll() {
        let DivisionTypes: any = []
        this.FillDivisionMaster.map((item: any) => {

            DivisionTypes.push(item?.ID)
        })
        this.RoleForm.get("DiviName").setValue(DivisionTypes);
        console.log("SelectAllDivision", this.RoleForm.value.DiviName);
    }

    unselectAll() {
        this.RoleForm.get("DiviName").setValue([]);
    }
    selectAllOffice() {
        let MultiOfficeTypes: any = []
        this.OfficeValues.map((item: any) => {

            MultiOfficeTypes.push(item?.ID)
        })
        this.RoleForm.get("OfficeMulti").setValue(MultiOfficeTypes);
    }

    unselectAllOffice() {
        this.RoleForm.get("OfficeMulti").setValue([]);
    }

    OnSubmit() {


        console.log(this.FillDivisionMaster);
        let DiviTypes: any = [];
        let divisionId: any = [];
        let divisionNameValues: any = [];



        const DiviNames = this.FillDivisionMaster.filter((list: any) => this.RoleForm.value.DiviName.indexOf(list?.ID) >= 0);


        for (let i = 0; i < DiviNames.length; i++) {
            divisionId.push(DiviNames[i].ID);
            divisionNameValues.push(DiviNames[i].DivisionName);
        }
        this.multidivisionid = divisionId.toString();
        this.multidivisionname = divisionNameValues.toString();
        console.log("divinamesid", this.multidivisionid);
        console.log("divinamesvalue", this.multidivisionname);

        if (this.getValue.User_Id != undefined) {
            if (this.existingmultidivi.toString() != "" && this.existingmultidiviName.toString() != "") {
                this.multidivisionid = this.existingmultidivi.toString();
                this.multidivisionname = this.existingmultidiviName.toString();
            }


            else {
                this.multidivisionid = divisionId.toString();
                this.multidivisionname = divisionNameValues.toString();

            }
        }
        else {
            this.multidivisionid = divisionId.toString();
            this.multidivisionname = divisionNameValues.toString();
            console.log('divionId:', this.multidivisionid);
            console.log('divisionName:', this.multidivisionname);
        }



        let MultiOfficeListTypes: any = [];
        let OfficeId: any = [];
        let OfficeNameValues: any = [];
        const OfficeMultiV = this.OfficeValues.filter((list: any) => this.RoleForm.value.OfficeMulti.indexOf(list?.ID) >= 0);
        console.log("OfficeNames", OfficeMultiV);
        console.log("OfficeNamesV", this.RoleForm.value.OfficeMulti);
        for (let i = 0; i < OfficeMultiV.length; i++) {
            OfficeId.push(OfficeMultiV[i].ID);
            OfficeNameValues.push(OfficeMultiV[i].Office);
        }
        this.multiofficeid = OfficeId.toString();
        this.multiofficename = OfficeNameValues.toString();
        //if (this.getValue.User_Id != undefined) {
        //    if (this.existingmultiofficeid.toString() != "" && this.existingmultioffName.toString() != "") {
        //        for (let i = 0; i < OfficeMultiV.length; i++) {
        //            OfficeId.push(OfficeMultiV[i].ID);
        //            OfficeNameValues.push(OfficeMultiV[i].OfficeCode);
        //        }
        //        this.multiofficeid = this.existingmultiofficeid.toString();
        //        this.multiofficename = this.existingmultioffName.toString();
        //    }
        //    else {
        //        this.multiofficeid = OfficeId.toString();
        //        this.multiofficename = OfficeNameValues.toString();
        //    }

        //}
        //else {
        //    this.multiofficeid = OfficeId.toString();
        //    this.multiofficename = OfficeNameValues.toString();
        //    console.log('officeId:', this.multiofficeid);
        //    console.log('officeName:', this.multiofficename);
        //}


        var validation = "";

        var ddlLandingApp = $('#ddlLandingAppU').val();
        if (ddlLandingApp == null || ddlLandingApp == 0) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Landing Application</span></br>"
        }



        if (this.DynamicRoleList.length == 0) {
            validation += "<span style='color:red;'>*</span> <span>Please Add Roles</span></br>"
        }

        if ($('#ddlLandingAppU').val() == 1) {
            var ddlOfficeV = this.RoleForm.value.OfficeMulti;

            if (ddlOfficeV == null || ddlOfficeV == 0) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Office</span></br>"
            }

            var ddlDivision = this.RoleForm.value.DiviName;

            if (ddlDivision == null || ddlDivision == 0) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Division</span></br>"
            }
        }

        if ($('#ddlLandingAppU').val() == 2) {
            var ddlOfficeV = this.RoleForm.value.OfficeMulti;

            if (ddlOfficeV == null || ddlOfficeV == 0) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Office</span></br>"
            }

            var ddlSalesPerson = $('#ddlSalesPerson').val();
            if (ddlSalesPerson == null || ddlSalesPerson == 0) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Sales Person</span></br>"
            }
        }

        if ($('#ddlLandingAppU').val() == 3) {
            var ddlOfficeV = this.RoleForm.value.OfficeMulti;

            if (ddlOfficeV == null || ddlOfficeV == 0) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Office</span></br>"
            }

            var ddlSalesPerson = $('#ddlSalesPerson').val();
            if (ddlSalesPerson == null || ddlSalesPerson == 0) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Sales Person</span></br>"
            }
        }

        if ($('#ddlLandingAppU').val() == 4) {
            var ddlOfficeV = this.RoleForm.value.OfficeMulti;

            if (ddlOfficeV == null || ddlOfficeV == 0) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Office</span></br>"
            }

            var ddlDivision = this.RoleForm.value.DiviName;

            if (ddlDivision == null || ddlDivision == 0) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Division</span></br>"
            }
        }

        if ($('#ddlLandingAppU').val() == 5) {

        }





        //var ddlDivisionV = $('#ddlDivisionV').val();
        //if (ddlDivisionV == null || ddlDivisionV == 0) {
        //    validation += "<span style='color:red;'>*</span> <span>Please Select Division</span></br>"
        //}

        //var ddlOfficeV = $('#ddlOfficeV').val();
        //if (ddlOfficeV == null || ddlOfficeV == 0) {
        //    validation += "<span style='color:red;'>*</span> <span>Please Select Office</span></br>"
        //}

        //var ddlSalesPerson = $('#ddlSalesPerson').val();
        //if (ddlSalesPerson == null || ddlSalesPerson == 0) {
        //    validation += "<span style='color:red;'>*</span> <span>Please Select Sales Person</span></br>"
        //}

        if (validation != "") {
            Swal.fire(validation);
            return false;
        }


        var useId = this.getValue.UserId;
        var userName = this.getValue.UserName;
        var userType = this.getValue.UserType;
        var division_Id = "";
        var divisionName = "";
        var office_Id = "";
        var office = "";

        if ($("#txtDivision").val() != "") {

            division_Id = "";
            divisionName = $('#txtDivision').val();


            console.log('Selected value as string:', divisionName);
        }

        if (this.multidivisionid != "" && this.multidivisionname != "") {
            division_Id = this.multidivisionid;
            divisionName = this.multidivisionname;
        }

        //if ($("#ddlOfficeU").val() != null || $("#ddlOfficeU").val() != 0) {
        //    let stringvalue = String($("#ddlOfficeU").val());
        //    office_Id = stringvalue;
        //    office = $('#ddlOfficeU option:selected').text();

        //}
        if (this.multiofficeid != "" && this.multiofficename != "") {
            office_Id = this.multiofficeid;
            office = this.multiofficename;
        }

        //var office_Id = this.getValue.Office_Id;
        //var office = this.getValue.Office_Name;
        var locked = "1";

        var ref_Application = $("#ddlLandingAppU").val();
        var role_Active = this.getValue.User_Active;
        var is_Active = this.getValue.Is_Active;
        //var is_Delete = this.getValue.Is_Delete;
        var created_by = this.getValue.UserId;
        var modified_by = this.getValue.UserId;

        var sales_Person = "";
        if ($("#ddlSalesPerson").val() != null) {
            sales_Person = $("#ddlSalesPerson").val();
        }
        else {
            sales_Person = "1";
        }
        var ItemsDr1 = [];
        var ItemsDr = [];
        var ItemsRoles = [];

        //for (let item of this.DynamicRoleList) {
        //    ItemsRoles = ItemsRoles.filter((item: any) => item.Id
        //}

        for (let item of this.DynamicRoleList) {


            console.log("rolevalueid", this.rolevalueid);
            var roleid = "";


            //if (item?.Id != undefined) {
            //    roleid = item?.Id;
            //    var isdata = "U";
            //}
            //if (item?.Id == undefined) {
            //    roleid = 0;
            //    var isdata = "N";
            //}
            if (item?.Id != 0) {
                roleid = item?.Id;
                var isdata = "U";
            }
            if (item?.Id == 0) {
                roleid = "0";
                var isdata = "N";
            }


            if (this.deleteid == 1) {
                roleid = item?.rolevalueid;
                var isdata = "D";
            }

            let obj = {
                id: roleid,
                userId: useId,
                isdata: isdata,
                userName: userName,
                userType: userType,
                division_Id: division_Id,
                divisionName: divisionName,
                office_Id: office_Id,
                office: office,
                locked: locked,
                ref_Application: ref_Application,
                role_Active: role_Active,
                is_Active: is_Active,
                is_Delete: 0,
                created_by: created_by,
                modified_by: modified_by,
                ref_Role_Id: item?.ref_Role_Id,
                Ref_RoleId: item?.Ref_RoleId,
                sales_Person: sales_Person
            }
            if (obj.id != "") {
                ItemsDr1.push(obj);
            }

        }
        console.log("AllArray", ItemsDr1);

        let payload = ItemsDr1

        console.log("payload", payload);
        this.us.saveUserRoleList(payload).subscribe(Data => {

            let payload1 = {
                "isdata": "G",
                "ref_RoleId": "",
                "UserId": this.getValue.UserId.toString(),
                "Created_by": 0
            }
            console.log("GeneratePayload", payload1);
            this.us.GenerateUserPermissionObject(payload1).subscribe(res => {

                console.log("permissionobject", res.data);
            },

                (error: HttpErrorResponse) => {
                    Swal.fire(error.message)
                });
            console.log(payload);
            Swal.fire("Record Saved Successfully");


        },

            (error: HttpErrorResponse) => {
                Swal.fire(error.message)
            });

        //let payload2 = {

        //    "userId": this.getValue.User_Id,

        //}
        //this.us.GenerateUserPermissionObjectView(payload2).subscribe(res => {
        //    console.log("UserPermissionObject", res.data.MainMenuList);
        //    this.DynamicUserPerObject = res.data.MainMenuList;
        //},

        //    (error: HttpErrorResponse) => {
        //        Swal.fire(error.message)
        //    });
        //$('#PrintModal').modal('show');
    }

    BindExistingRecords() {
      
        if (this.getValue.UserId != undefined) {
            this.payloadRoles = {
                "userId": this.getValue.UserId,
                "ref_Application": this.landinappid,
                "division_Id": "",
                "office_Id": "",
                "is_Active": 1

            }
        }
        else {
            this.payloadRoles = {
                "userId": 0,
                "ref_Application": 0,
                "division_Id": "",
                "office_Id": "",
                "is_Active": 1

            }
        }
       
        console.log("payload", this.payloadRoles);
        this.us.GetRoleExistingDtls(this.payloadRoles).subscribe(res => {
            console.log("statuscode", res.statuscode);
            if (res.statuscode == 200) {
                this.RoleExistingDtls = res.data.UserRoles;
                this.rolListLength = this.RoleExistingDtls.length

                //Division Bind
                let divisonLIst = this.RoleExistingDtls[0].Division_Id
                let divisonLIstName = this.RoleExistingDtls[0].DivisionName
                let intCOnvert = divisonLIst.split(',').map(Number);
                let StrDiv = divisonLIstName.split(',').map(String);
                this.RoleForm.get("DiviName").setValue(intCOnvert);
                console.log("ExistingDivisino", intCOnvert);
                console.log("ExistingDivisionNameList", StrDiv);
                console.log("ExistionDivisionList", this.RoleForm.value.DivisionName);
                this.existingmultidivi = intCOnvert;
                this.existingmultidiviName = StrDiv;


                //Office Bind
                let officeLIst = this.RoleExistingDtls[0].Office_Id
                let officeLIstName = this.RoleExistingDtls[0].Office
                let intCOnvertOffice = officeLIst.split(',').map(Number);
                let StrOff = officeLIstName.split(',').map(String);
                this.RoleForm.get("OfficeMulti").setValue(intCOnvertOffice);
                console.log("ExistingofficeId", intCOnvertOffice);
                console.log("ExistingOfficeNameList", StrOff);

                this.existingmultiofficeid = intCOnvertOffice;
                this.existingmultioffName = StrOff;


                this.LandingApp = this.RoleExistingDtls[0].Ref_Application;
                this.RoleForm.patchValue({
                    division: this.RoleExistingDtls[0].DivisionName

                })
                /* this.Division = this.RoleExistingDtls[0].Division_Id;*/







                //if (this.landinappid == 3) {
                //    if (this.RoleExistingDtls[0].Division_Id != "") {
                //        this.Division = this.RoleExistingDtls[0].Division_Id;
                //    }
                //    else {
                //        let payloaddivffd = {

                //            "Id": 2,

                //        }
                //        this.us.GetDivisionByFFD(payloaddivffd).subscribe(res => {
                //            this.SinglDivByFFD = []
                //            this.SinglDivByFFD = res.data.Division;
                //            console.log("signlediv", res.data.Division);


                //        });
                //    }
                //}
                //if (this.landinappid == 2) {
                //    if (this.RoleExistingDtls[0].Division_Id != "") {
                //        this.Division = this.RoleExistingDtls[0].Division_Id;
                //    }
                //    else {
                //        let payloaddivffd = {

                //            "Id": 1,

                //        }
                //        this.us.GetDivisionByFFD(payloaddivffd).subscribe(res => {
                //            this.SinglDivByFFD = []
                //            this.SinglDivByFFD = res.data.Division;
                //            console.log("signlediv", res.data.Division);


                //        });
                //    }
                //}


                this.Office = this.RoleExistingDtls[0].Office_Id;
                this.SalesPerson = this.RoleExistingDtls[0].Sales_Person;



                $('#ddlDivisionVV').val(this.RoleExistingDtls[0].Division_Id);
                $('#ddlOfficeU').select2().val(this.Office).trigger("change");
                $('#ddlSalesPerson').select2().val(this.SalesPerson).trigger("change");
                //alert(this.RoleExistingDtls.length);


                this.DynamicRoleList.length = 0;
                if (this.RoleExistingDtls.length > 0) {
                    for (let item of this.RoleExistingDtls) {
                        //console.log("dynamicapp", item.Ref.Application);
                        //this.landingappidnew = item.Ref.Application;
                        this.DynamicRoleList.push({
                            Id: item.Id,
                            ref_Role_Id: item.Ref_Role_Id,
                            Ref_RoleId: item.Ref_RoleId,
                            Role_Name: item.Ref_RoleName,
                            Role_Des: item.Ref_RoleDescription,
                            Ass_Remarks: item.Ref_AssignmentRemakrs,

                        });
                        console.log("Dynamiclist", this.DynamicRoleList);
                    }
                    console.log("landappnew", this.landingappidnew);

                }

            }
            else {
                this.RoleExistingDtls.length = 0;
                this.DynamicRoleList.length = 0;
                $('#ddlDivisionVV').val(0);
                $('#ddlOfficeU').val(0);
                /*$('#ddlSalesPerson').val(0);*/
                this.RoleForm.get("DiviName").setValue([]);
                this.RoleForm.get("OfficeMulti").setValue([]);

            }
        });



    }

    btntabclick(tab) {


        let value = encodeQueryParam(this.getValue);

        if (tab == 1) {

            this.router.navigate(['/views/systemadmin/user/profile/profile'], { queryParams: { value } });
        }
        else if (tab == 2) {

            this.router.navigate(['/views/systemadmin/user/roles/roles'], { queryParams: { value } });

        }
        else if (tab == 3) {

            this.router.navigate(['/views/systemadmin/user/activities/activities'], { queryParams: { value } });

        }

    }
}
export function decodeQueryParam(encodedValue: string): any {
    const decodedBytes = Uint8Array.from(atob(encodedValue), c => c.charCodeAt(0));
    const decoder = new TextDecoder('utf-8');
    const decodedString = decoder.decode(decodedBytes);
    /*console.log("decode", decodedString);*/
    return JSON.parse(decodedString);
}
export function encodeQueryParam(value: any): string {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(value));
    let binary = '';
    const len = data.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(data[i]);

    }
    console.log(btoa(binary));
    return btoa(binary);

}
interface LandingAppType {
    value: string;
    viewValue: string;
}
interface UserActiveType {
    value: string;
    viewValue: string;
}
export class SelectMultipleExample {
    toppings = new FormControl('');
    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
}
