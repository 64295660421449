import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../../services/encr-decr-service.service';
import { useraccess, Division, DivisionLable, DivisionTypes, Customer } from '../../../../model/useraccessdata';
import { UseraccessService } from '../../../../services/useraccess.service';
import { DynamicNotificationEmail, MyOffice, MyEmpCodeList, EmployeeMiniMaster } from '../../../../model/systemadmin';
import { SystemadminService } from '../../../../services/systemadmin.service';
import { PasswordService } from '../../../../services/password.service';


declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    UserTypeDDLValues: UserType[] = [
        { value: '1', viewValue: 'EMPLOYEE MASTER' },
        { value: '2', viewValue: 'VENDOR' },
    ];
    ActiveValues: UserActiveType[] = [
        { value: '1', viewValue: 'YES' },
        { value: '2', viewValue: 'NO' },
    ];

    EmpValues: EmpActiveType[] = [
        { value: '1', viewValue: 'YES' },
        { value: '2', viewValue: 'NO' },
    ];
    GenderValues: GenderType[] = [
        { value: '1', viewValue: 'MALE' },
        { value: '2', viewValue: 'FEMALE' },
    ];
    LandingValues: LandingAppType[] = [
        { value: '1', viewValue: 'MANAGEMENT DASHBOARD' },
        { value: '2', viewValue: 'LINER AGENCY' },
        { value: '3', viewValue: 'FREIGHT FORWARDING' },
        { value: '4', viewValue: 'FINANCE' },
        { value: '5', viewValue: 'SYSTEM ADMIN' },
    ];
    
    originalDate: Date = new Date();
    desiredDateFormat: string = 'MM-DD-YYYY';
    formattedDate: string = this.originalDate.toISOString().split('T')[0];
    generatedPassword: string;

    constructor(private router: Router, private route: ActivatedRoute, private sa: SystemadminService, private us: UseraccessService, private fb: FormBuilder, private passwordService: PasswordService, private datePipe: DatePipe, private ES: EncrDecrServiceService) {

        this.route.queryParams.subscribe(param => {
            if (param['value']) {
                this.getValue = decodeQueryParam(param['value']);
                console.log("getvalue",this.getValue);
               
                console.log(this.getValue.TrasnportType_list);
            }
        })
   
    }
    private allItems: any = { Data: [] };
    ProfileForm: FormGroup;
    public ShowDiv: boolean = true;
    public ShowViv: boolean = true;
    public ShowEmpMaster: boolean = true;
    public ShowPassword: boolean = false;
    getValue: any = "";
    /*    UserTypeValues: useraccess[];*/
    UserTypeValues= [];
    OfficeValues: MyOffice[];
    EmpCodeValues: EmployeeMiniMaster[];
    saveUser: useraccess[] = [];
    VendorNames = [];
    VendorBranchs = [];
    UserID = 0;
    payload: any = "";
    FillDivisionMaster: DivisionLable[] = [];
    public showLocked: boolean = true;
    public showUnlocked: boolean = false;
    public showReset: boolean = false;
    lockID = 1;
    //EmpCodeValues: MyEmpCodeList[];
    DisableUserID = false;

    useridv = "";
    disableSave = false;
    disableCancel = false;
    disablegenerate = false;
    public showEdit: boolean = false;
    UserId = 0;
    UserType = 0;
    dob = "";
    doj = "";
    doc = "";
    dor = "";
    public ControlDisplay: boolean = true;
    PasswordReset: string = '';
    uservalue = "";
    disablelocked = false;
    disableunlocked = false;
    disableResetPassword = false;
    ngOnInit(): void {
       
        this.createForm();

        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id

            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        });

        
        $('#ddlEmployeeCodeVS').on('select2:select', (e, args) => {
           
            this.OnChangeEmployeeList($('#ddlEmployeeCodeVS').val());
        });
        this.BindDropDown();
        
        this.ExistingProfileList();
        console.log(this.getValue);
        
        this.ControlDisplay = true;
        
        if (this.getValue.UserId == undefined) {
           
            this.showEdit = false;
            this.showLocked = false;
            this.showUnlocked = true;
            this.showReset = true;
            this.disableSave = false;
            this.disableResetPassword = true;
        }
        else {
          
            this.disableunlocked = true;
            this.disablelocked = true;
            this.showEdit = true;
            if (this.getValue.Is_Active == 2) {
                this.showLocked = false;
                this.showUnlocked = true;
            }
            else {
                this.showLocked = true;
                this.showUnlocked = false;
            }
           
            this.showReset = false;
            this.disableSave = true;
           
            this.disableResetPassword = true;
        }
        this.OnChangeExistingUserType(this.getValue.UserType);
        this.ExistingEmployeeMasterList(this.getValue.UserId, this.getValue.UserType);


        $('#ddlUserTypeV').on('select2:select', (e, args) => {
            this.OnChangeExistingUserType($('#ddlUserTypeV').val());
        });
       
      
        this.getexitbind(this.getValue.userType);
     
        var date = this.dob;
        var dateJ = this.doj;
        var dateC = this.doc;
        var dateR = this.dor;
        var newdate = date.split("-").reverse().join("-");
        var newdateJ = dateJ.split("-").reverse().join("-");
        var newdateC = dateC.split("-").reverse().join("-");
        var newdateR = dateR.split("-").reverse().join("-");

        this.ProfileForm.get('DOB').patchValue(this.formatDate(new Date(Date.parse(newdate)).toISOString().slice(0, 10)));
        this.ProfileForm.get('DateOfJoining').patchValue(this.formatDate(new Date(Date.parse(newdateJ)).toISOString().slice(0, 10)));
        this.ProfileForm.get('DateOfConfirmation').patchValue(this.formatDate(new Date(Date.parse(newdateC)).toISOString().slice(0, 10)));
        this.ProfileForm.get('DateOfRelieving').patchValue(this.formatDate(new Date(Date.parse(newdateR)).toISOString().slice(0, 10)));
        
        
    }
    
    ExistingEmployeeMasterList(useridv,usertypev) {
       
        this.ProfileForm.value.UserId = useridv;
        this.ProfileForm.value.UserType = usertypev;
       
        this.us.GetEmployeeMasterDetails(this.ProfileForm.value).subscribe(data => {
            this.ProfileForm.patchValue(data[0]);
            
            this.OnChangeExistingUserType(usertypev);
         
            if (this.getValue.UserType == 132) {
               
                $("#ddlUserTypeV").val(data[0].UserType).trigger("change");
                $("#ddlActiveE").val(data[0].User_Active).trigger("change");
                $("#ddlLandingAppE").val(data[0].Landing_Application_Id).trigger("change");
                $("#ddlEmployeeCodeVS").val(data[0].Employee_Code_Id).trigger("change");
                
                this.Disableformcontrol();
            }
            if (this.getValue.UserType == 133) {
               
                $("#ddlUserTypeV").val(data[0].UserType).trigger("change");
                $("#ddlActive").val(data[0].User_Active).trigger("change");
                $("#ddlLandingApp").val(data[0].Landing_Application_Id).trigger("change");
                $("#ddlGender").val(data[0].GenderId).trigger("change");
                $("#ddlDivision").val(data[0].Divison_Id).trigger("change");
                $("#ddlOffice").val(data[0].Office_Id).trigger("change");
                $("#ddlEmpActiveEmp").val(data[0].Employee_Active_Status).trigger("change");
               
                this.dob = data[0].DateofBirth;
                this.doj = data[0].DateOfJoining;
                this.doc = data[0].DateOfConfirmation;
                this.dor = data[0].DateOfRelieving;
                this.Disableformcontrol();

            }
            if (this.getValue.UserType == 134) {
                $("#ddlUserTypeV").val(data[0].UserType).trigger("change");
                $("#ddlActiveV").val(data[0].User_Active).trigger("change");
                $("#ddlLandingAppV").val(data[0].Landing_Application_Id).trigger("change");
                $("#ddlGenderV").val(data[0].GenderId).trigger("change");
                $("#ddlDivisionV").val(data[0].Divison_Id).trigger("change");
                $("#ddlOfficeV").val(data[0].Office_Id).trigger("change");
                $("#ddlVendorName").val(data[0].VendorId).trigger("change");
                $("#ddlVendorBranch").val(data[0].VendorBranchId).trigger("change");
                this.Disableformcontrol();
            }
           
           
        });
    }
    Disableformcontrol() {
        this.ProfileForm.controls["Landing_Application_Id"].disable();
        this.ProfileForm.controls["userType"].disable();
        this.ProfileForm.controls["User_Idv"].disable();
        this.ProfileForm.controls["UserName"].disable();
        this.ProfileForm.controls["User_Active"].disable();
        this.ProfileForm.controls["Employee_Code"].disable();
        this.ProfileForm.controls["Employee_Code_Id"].disable();
        this.ProfileForm.controls["EmployeeName"].disable();
        this.ProfileForm.controls["ShortName"].disable();
        this.ProfileForm.controls["Employee_Active_Status"].disable();
        this.ProfileForm.controls["GenderId"].disable();
        this.ProfileForm.controls["DOB"].disable();
        this.ProfileForm.controls["Designation"].disable();
        this.ProfileForm.controls["Landline"].disable();
        this.ProfileForm.controls["Mobile"].disable();
        this.ProfileForm.controls["Email"].disable();
        this.ProfileForm.controls["DateOfJoining"].disable();
        this.ProfileForm.controls["DateOfConfirmation"].disable();
        this.ProfileForm.controls["DateOfRelieving"].disable();
        this.ProfileForm.controls["Divison_Id"].disable();
        this.ProfileForm.controls["Office_Id"].disable();
        this.ProfileForm.controls["Department"].disable();
        this.ProfileForm.controls["Reporting_to"].disable();
        this.ProfileForm.controls["HOD"].disable();
        this.ProfileForm.controls["SBU_Division_Head"].disable();
        this.ProfileForm.controls["VendorId"].disable();
        this.ProfileForm.controls["VendorBranchId"].disable();
        
    }
    Enableformcontrol() {
        
        this.ProfileForm.controls["User_Idv"].enable();
        this.ProfileForm.controls["User_Active"].enable();
        this.ProfileForm.controls["Employee_Active_Status"].enable();
     
    }
   

    OnChangeExistingUserType(usrtype) {
       
      
        if (usrtype == 132) {
            
            this.ShowEmpMaster = false;
            this.ShowDiv = true;
            this.ShowViv = true;
           

            $('#txtuserid').val("");
            $('#ddlActive').val(0);
            $('#txtEmployeeCode').val("");
            $('#ddlLandingAppE').val(0);
            $('#txtEmpName').val("");
            $('#txtShortName').val("");
            $('#ddlEmpActiveEmp').val("");
            $('#ddlGender').val("");
            $('#txtDOB').val("");
            $('#txtDesignation').val("");
            $('#txtLandline').val("");
            $('#txtMobile').val("");
            $('#txtEmail').val("");
            $('#txtDOJ').val("");
            $('#txtDOC').val("");
            $('#txtDOR').val("");
            $('#ddlDivision').val("");
            $('#ddlOffice').val("");
            $('#txtDepartment').val("");
            $('#txtReportingTo').val("");
            $('#txtHOD').val("");
            $('#txtSBUHead').val("");
        }
        if (usrtype == 133) {
           
            this.ShowDiv = false;
            this.ShowViv = true;
            this.ShowEmpMaster = true;

           


        }
        if (usrtype == 134) {
            this.ShowDiv = true;
            this.ShowViv = false;
            this.ShowEmpMaster = true;
           

        }
    }
    OnChangeEmployeeList(empcode) {
      
        this.ProfileForm.value.ID = empcode;
        this.us.GetEmployeeMaster(this.ProfileForm.value).subscribe(data => {
         

            $("#txtuseridE").val(data[0].Email);
            $("#txtEmailE").val(data[0].Email);
            $("#ddlGenderE").val(data[0].GenderV).trigger("change");
            $("#ddlEmpActiveE").val(data[0].EmpActive).trigger("change");
            $("#txtEmpNameE").val(data[0].EmployeeName);
            $("#txtShortNameE").val(data[0].ShortName);
            $("#txtDOBE").val(data[0].DateofBirth);
            $("#txtDesignationE").val(data[0].Designation);
            $("#txtLandlineE").val(data[0].Landline);
            $("#txtMobileE").val(data[0].Mobile);
            $("#txtDOJE").val(data[0].DateOfJoining);
            $("#txtDOCE").val(data[0].DateOfConfirmation);
            $("#txtDORE").val(data[0].DateOfRelieving);
            $("#ddlDivisionE").val(data[0].Division_Name);
            $("#ddlOfficeE").val(data[0].Office_Name);
            $("#txtDepartmentE").val(data[0].Department);
            $("#txtReportingToE").val(data[0].Reporting_to);
            $("#txtHODE").val(data[0].HOD);
            $("#txtSBUHeadE").val(data[0].SBU_Division_Head);

        });
    }

   

  
    createForm() {
                  

        
    this.ProfileForm = this.fb.group({
        userType: 0,
        //User_Password: '',
        Reset_Password:'',
        UserId:0,
        User_Idv: '',
        UserName: '',
        User_Active: 1,
        Employee_Code_Id: 0,
        Employee_Code: '',
        Landing_Application_Id: 0,
        Landing_Application:'',
        EmployeeName: '',
        ShortName: '',
        Employee_Active_Status: 1,
        GenderId: 0,
        DOB: '',
        Designation: '',
        Landline: '',
        Mobile: '',
        Email: '',
        DateOfJoining: '',
        DateOfConfirmation: '',
        DateOfRelieving: '',
        Divison_Id: 0,
        Division_Name: '',
        Office_Id: 0,
        Office_Name: '',
        Department: '',
        Reporting_to: '',
        HOD: '',
        SBU_Division_Head: '',
        is_Active: 1,
        is_Delete: 0,
        created_by: 0,
        modified_by: 1,
        VendorId:0,
        VendorBranchId:0,
    });



       
//        if (this.getValue.UserType == 132) {
           
//            if (this.getValue.Id != undefined) {
//                alert(this.getValue.Employee_Code_Id);
//                this.ProfileForm = this.fb.group({

//                    //id: [this.getValue ? this.getValue.Id : ""],
//                    userType: [this.getValue ? this.getValue.UserType : 0],
//                    User_Password: [this.getValue ? this.getValue.User_Password : ""],
//                    userid: [this.getValue ? this.getValue.User_Idv : ""],
//                    userName: [this.getValue ? this.getValue.UserName : ""],
//                    user_Active: [this.getValue ? this.getValue.User_Active : 1],
//                    Employee_Code_Id: [this.getValue ? this.getValue.Employee_Code_Id : 0],
//                    Employee_Code: [this.getValue ? this.getValue.Employee_Code : ""],
//                    landing_Application: [this.getValue ? this.getValue.Landing_Application_Id : ""],
//                    employeeName: [this.getValue ? this.getValue.EmployeeName : ""],
//                    shortName: [this.getValue ? this.getValue.ShortName : ""],
//                    emp_Actvie_Status: [this.getValue ? this.getValue.Emp_Actvie_Status : 0],
//                    gender: [this.getValue ? this.getValue.Gender : 0],
//                    //dob: [this.getValue ? this.getValue.DOB : ""],
//                    dob: '',
//                    VendorName: [this.getValue ? this.getValue.VendorId : 0],
//                    VendorBranch: [this.getValue ? this.getValue.VendorBranchId : 0],
//                    designation: [this.getValue ? this.getValue.Designation : ""],
//                    landline: [this.getValue ? this.getValue.Landline : ""],
//                    mobile: [this.getValue ? this.getValue.Mobile : "0"],
//                    email: [this.getValue ? this.getValue.Email : ""],
//                    doj: [this.getValue ? this.getValue.DOJ : ""],
//                    doc: [this.getValue ? this.getValue.DOC : ""],
//                    dor: [this.getValue ? this.getValue.DOR : ""],
//                    division_Id: [this.getValue ? this.getValue.Division_Id : 0],
//                    office_Id: [this.getValue ? this.getValue.Office_Id : 0],
//                    division_Name: [this.getValue ? this.getValue.Division_Name : ""],
//                    office_Name: [this.getValue ? this.getValue.Office_Name : ""],
//                    department: [this.getValue ? this.getValue.Department : ""],
//                    reporting_to: [this.getValue ? this.getValue.Reporting_to : ""],
//                    hod: [this.getValue ? this.getValue.HOD : ""],
//                    sbU_Division_Head: [this.getValue ? this.getValue.SBU_Division_Head : ""],
//                    is_Active: 1,
//                    is_Delete: 0,
//                    created_by: 0,
//                    modified_by: 1,


//                    //VendorName: [this.getValue ? this.getValue.VendorId : ""],
//                    ///* "VendorName": [this.getValue ? this.getValue.VendorName : ""],*/
//                    //VendorBranch: [this.getValue ? this.getValue.VendorBranchId : ""],
//                    ///* "vendorBranch": [this.getValue ? this.getValue.VendorBranch : ""],*/




//                });
//                $("#ddlEmployeeCode").select2().val(this.getValue.Employee_Code_Id).trigger("change");
//            }
          
        
       
           
           
//        //if (this.getValue != null) {
//        $("#ddlEmployeeCode").select2().val(this.getValue.Employee_Code).trigger("change");
       
//        $("#ddlVendorName").val(this.getValue.VendorId).trigger("change");    
//    }
//       else if (this.getValue.UserType == 133) { 
//    if (this.getValue.Id != undefined) {

//    this.ProfileForm = this.fb.group({

//        //id: [this.getValue ? this.getValue.Id : ""],
//        userType: [this.getValue ? this.getValue.UserType : 0],
//        User_Password: [this.getValue ? this.getValue.User_Password : ""],
//        userid: [this.getValue ? this.getValue.User_Idv : ""],
//        userName: [this.getValue ? this.getValue.UserName : ""],
//        user_Active: [this.getValue ? this.getValue.User_Active : 1],
//        //Employee_Code_Id: [this.getValue ? this.getValue.Employee_Code_Id : 0],
//        Employee_Code: [this.getValue ? this.getValue.Employee_Code : ""],
//        landing_Application: [this.getValue ? this.getValue.Landing_Application_Id : ""],
//        employeeName: [this.getValue ? this.getValue.EmployeeName : ""],
//        shortName: [this.getValue ? this.getValue.ShortName : ""],
//        emp_Actvie_Status: [this.getValue ? this.getValue.Emp_Actvie_Status : 0],
//        gender: [this.getValue ? this.getValue.Gender : 0],
//        //dob: [this.getValue ? this.getValue.DOB : ""],
//        dob: '',
//        VendorName: [this.getValue ? this.getValue.VendorId : 0],
//        VendorBranch: [this.getValue ? this.getValue.VendorBranchId : 0],
//        designation: [this.getValue ? this.getValue.Designation : ""],
//        landline: [this.getValue ? this.getValue.Landline : ""],
//        mobile: [this.getValue ? this.getValue.Mobile : "0"],
//        email: [this.getValue ? this.getValue.Email : ""],
//        doj: [this.getValue ? this.getValue.DOJ : ""],
//        doc: [this.getValue ? this.getValue.DOC : ""],
//        dor: [this.getValue ? this.getValue.DOR : ""],
//        division_Id: [this.getValue ? this.getValue.Division_Id : ""],
//        office_Id: [this.getValue ? this.getValue.Office_Id : ""],
//        department: [this.getValue ? this.getValue.Department : ""],
//        reporting_to: [this.getValue ? this.getValue.Reporting_to : ""],
//        hod: [this.getValue ? this.getValue.HOD : ""],
//        sbU_Division_Head: [this.getValue ? this.getValue.SBU_Division_Head : ""],
//        is_Active: 1,
//        is_Delete: 0,
//        created_by: 0,
//        modified_by: 1,


//        //VendorName: [this.getValue ? this.getValue.VendorId : ""],
//        ///* "VendorName": [this.getValue ? this.getValue.VendorName : ""],*/
//        //VendorBranch: [this.getValue ? this.getValue.VendorBranchId : ""],
//        ///* "vendorBranch": [this.getValue ? this.getValue.VendorBranch : ""],*/




//    });

//}
//else {
//    this.ProfileForm = this.fb.group({
//        userType: 0,
//        User_Password: '',
//        userid: '',
//        userName: '',
//        user_Active: 1,

//        Employee_Code: '',
//        landing_Application: '',
//        employeeName: '',
//        shortName: '',
//        emp_Actvie_Status: 1,
//        gender: 0,

//        dob: '',
//        VendorName: 0,
//        VendorBranch: '',
//        designation: '',
//        landline: '',
//        mobile: '',
//        email: '',
//        doj: '',
//        doc: '',
//        dor: '',
//        division_Id: '',
//        office_Id: '',
//        department: '',
//        reporting_to: '',
//        hod: '',
//        sbU_Division_Head: '',
//        is_Active: 1,
//        is_Delete: 0,
//        created_by: 0,
//        modified_by: 1,
//    });
//}




//$("#ddlEmployeeCode").select2().val(this.getValue.Employee_Code).trigger("change");
////if (this.getValue != null) {
////    $("#txtDOB").val(this.getValue.DOB);
////}
////$("#txtDOBV").val(this.getValue.DOB);
//$("#ddlVendorName").val(this.getValue.VendorId).trigger("change");    
//        }

//        else {
//            this.ProfileForm = this.fb.group({
//                userType: 0,
//                User_Password: '',
//                userid: '',
//                userName: '',
//                user_Active: 1,

//                Employee_Code: '',
//                landing_Application: '',
//                employeeName: '',
//                shortName: '',
//                emp_Actvie_Status: 1,
//                gender: 0,

//                dob: '',
//                VendorName: 0,
//                VendorBranch: '',
//                designation: '',
//                landline: '',
//                mobile: '',
//                email: '',
//                doj: '',
//                doc: '',
//                dor: '',
//                division_Id: '',
//                office_Id: '',
//                department: '',
//                reporting_to: '',
//                hod: '',
//                sbU_Division_Head: '',
//                is_Active: 1,
//                is_Delete: 0,
//                created_by: 0,
//                modified_by: 1,
//            });
//        }
       
    }

    OnClick(data) {
   
        this.lockID = data;
        
        if (this.lockID == 2) {
            this.showUnlocked = true;
            this.showLocked = false;
        }
        else {
            this.showLocked = true;
            this.showUnlocked = false;
        }
    }

    OnResetPassword() {
        
        $('#PasswordRestModal').modal('show');
        this.disablegenerate = false;
    }
    GeneratePassword() {
        this.generatedPassword = this.passwordService.generatePassword(12);
        console.log("Regeneratepassword", this.generatedPassword);
        this.ProfileForm.value.Reset_Password = this.generatedPassword;
        this.ProfileForm.value.UserId = this.getValue.UserId;
     
        this.ProfileForm.value.UserType = this.getValue.UserType;
        this.us.ResetPassword(this.ProfileForm.value).subscribe(data => {
            $('#txtResetPassword').val(this.generatedPassword);
            this.PasswordReset = "Password Reset Done";
            this.disablegenerate = true;
        });

    }
    private formatDate(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [ year, month, day].join('-');
    }



    hidshow(idv) {
         $('.my-select').select2();
        if (idv != "") {
            if (idv == 132) {
                this.ShowDiv = false;
                this.ShowViv = true;
            }
            if (idv == 134) {
                this.ShowDiv = true;
                this.ShowViv = false;
            }
        }
      
    }
    
    getexitbind(iduser) {
        $('.my-select').select2();
        this.hidshow(iduser);
      
    }

    private formatDateV(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [month, day, year].join('-');
    }
    

    BindDropDown() {
     
        this.us.GetUserType().subscribe(res => {
            this.UserTypeValues = res.data.UserType;
        });
       
        this.us.GetOffice().subscribe(res => {
            console.log("OfficeList", res.data.Office);
            this.OfficeValues = res.data.Office;
        });

        this.us.GetVendorName().subscribe(res => {
            this.VendorNames = res;
           
        });

        this.us.GetVendorBranch().subscribe(res => {
            this.VendorBranchs = res;
         
        });
        let payloaddivision = {

            "Id": 0

        }
        this.us.GetDivisionDropDown(payloaddivision).subscribe(res => {
            this.FillDivisionMaster = res.data.Division;
            console.log("UserRoles", this.FillDivisionMaster);
        });

       

        this.us.GetEmployeeCode(this.ProfileForm.value).subscribe(data => {
            this.EmpCodeValues = data;
        });

       
    }

   
    Onsubmit() {
       
        
        var validation = "";
       
        if ($("#ddlUserTypeV").val() == 134) {
          

            if (this.getValue.ID == undefined) {
                let payloadv = {
                    "user_Idv": $("#txtUserIdV").val().toUpperCase()
                }
                this.us.GetValidateUserId(payloadv).subscribe((res: any) => {
                    console.log("userid", res.data.UserProfil[0].User_Idv);
                    console.log($("#txtUserIdV").val());


                    if ($("#txtUserIdV").val().toUpperCase() === res.data.UserProfil[0].User_Idv) {
                        Swal.fire("User Id Already Exists");

                    }




                },

                    (error: HttpErrorResponse) => {
                        Swal.fire(error.message)
                    });
            }
          
            if ($('#txtUserIdV').val() == "" || $('#txtUserIdV').val() == null) {
               
                validation += "<span style='color:red;'>*</span> <span>Please Enter User ID </span></br>"
            }
           
            if ($('#ddlVendorName').val() == "" || $('#ddlVendorName').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Vendor Name </span></br>"
            }

            if ($('#ddlLandingAppV').val() == "" || $('#ddlLandingAppV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Landing Application </span></br>"
            }

            if ($('#txtShortNameV').val() == "" || $('#txtShortNameV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Short Name </span></br>"
            }

            if ($('#ddlVendorBranch').val() == "" || $('#ddlVendorBranch').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Vendor Branch </span></br>"
            }

            if ($('#ddlGenderV').val() == "" || $('#ddlGenderV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Gender </span></br>"
            }

            if ($('#txtDOBV').val() == "" || $('#txtDOBV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter DOB </span></br>"
            }

            if ($('#txtDesignationV').val() == "" || $('#txtDesignationV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Designation </span></br>"
            }

            var txtEmailV = $('#txtEmailV').val().toLowerCase();
            if (txtEmailV == "") {
                validation += "<span style='color:red;'>*</span> <span>Please Enter EmailID</span></br>"
            }
            if (txtEmailV != "") {
                this.isValidEmail(txtEmailV) ? validation == "" : validation += "<span style='color:red;'>*</span> <span>Please Enter a Valid Email</span></br>";
            }

            //if ($('#txtEmailV').val() == "") {
            //    validation += "<span style='color:red;'>*</span> <span>Please Enter Email ID</span></br>"
            //}
            //else if ($('#txtEmailV').val() != "") {
            //    this.isValidEmail($('#txtEmailV').val()) ? validation == "" : validation += "<span style='color:red;'>*</span> <span>Please Enter a Valid Email</span></br>";
            //}



            if ($('#ddlDivisionV').val() == "" || $('#ddlDivisionV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Division </span></br>"
            }
            if ($('#ddlOfficeV').val() == "" || $('#ddlOfficeV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Office </span></br>"
            }

            if ($('#txtDepartmentV').val() == "" || $('#txtDepartmentV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Department </span></br>"
            }

            if ($('#txtReportingToV').val() == "" || $('#txtReportingToV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Reporting to </span></br>"
            }

            if ($('#txtHODV').val() == "" || $('#txtHODV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter HOD </span></br>"
            }

            if ($('#txtSBUHeadV').val() == "" || $('#txtSBUHeadV').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter SBU/Division Head </span></br>"
            }
        }
       
        
        else if ($("#ddlUserTypeV").val() == 133) {
          
        

            if (this.getValue.ID == undefined) {
               
                let payloadv = {
                    "user_Idv": $("#txtuserid").val().toUpperCase()
                }
                this.us.GetValidateUserId(payloadv).subscribe((res: any) => {
                    console.log("userid", res.data.UserProfil[0].User_Idv);
                    console.log($("#txtuserid").val());

                   
                    if ($("#txtuserid").val().toUpperCase() === res.data.UserProfil[0].User_Idv.toUpperCase()) {
                       
                        Swal.fire("UserID Already Exist!");
                     
                      
                    }




                },

                    (error: HttpErrorResponse) => {
                        Swal.fire(error.message)
                    });
            }

            if ($('#txtuserid').val() == "" || $('#txtuserid').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter User ID </span></br>"
            }
          
            //if ($('#ddlEmployeeCode').val() == "" || $('#ddlEmployeeCode').val() == null) {
            //    validation += "<span style='color:red;'>*</span> <span>Please Select Employee Code </span></br>"
            //}
            if ($('#txtEmployeeCode').val() == "" || $('#txtEmployeeCode').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Employee Code </span></br>"
            }

            if ($('#ddlLandingApp').val() == "" || $('#ddlLandingApp').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Landing Application </span></br>"
            }

            if ($('#txtEmpName').val() == "" || $('#txtEmpName').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Employee Name </span></br>"
            }

            if ($('#txtShortName').val() == "" || $('#txtShortName').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Short Name </span></br>"
            }

            if ($('#ddlGender').val() == "" || $('#ddlGender').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Gender </span></br>"
            }

            if ($('#txtDOB').val() == "" || $('#txtDOB').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter DOB </span></br>"
            }

            if ($('#txtDesignation').val() == "" || $('#txtDesignation').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Designation </span></br>"
            }

            //if ($('#txtLandline').val() == "" || $('#txtLandline').val() == null) {
            //    validation += "<span style='color:red;'>*</span> <span>Please Enter Landline No </span></br>"
            //}

            //if ($('#txtMobile').val() == "" || $('#txtMobile').val() == null) {
            //    validation += "<span style='color:red;'>*</span> <span>Please Enter Mobile No </span></br>"
            //}
            var txtEmailID = $('#txtEmail').val().toLowerCase();
            if (txtEmailID == "") {
                validation += "<span style='color:red;'>*</span> <span>Please Enter EmailID</span></br>"
            }
            if (txtEmailID != "") {
                this.isValidEmail(txtEmailID) ? validation == "" : validation += "<span style='color:red;'>*</span> <span>Please Enter a Valid Email</span></br>";
            }
            //if ($('#txtEmail').val() == "") {
            //    validation += "<span style='color:red;'>*</span> <span>Please Enter Email ID</span></br>"
            //}
            //else if ($('#txtEmail').val() != "") {
            //    this.isValidEmail($('#txtEmail').val()) ? validation == "" : validation += "<span style='color:red;'>*</span> <span>Please Enter a Valid Email</span></br>";
            //}

            if ($('#txtDOJ').val() == "" || $('#txtDOJ').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Date of Joining </span></br>"
            }

            //if ($('#txtDOC').val() == "" || $('#txtDOC').val() == null) {
            //    validation += "<span style='color:red;'>*</span> <span>Please Enter Date of Confirmation </span></br>"
            //}

            //if ($('#txtDOR').val() == "" || $('#txtDOR').val() == null) {
            //    validation += "<span style='color:red;'>*</span> <span>Please Enter Date of Relieving </span></br>"
            //}
           
            if ($('#ddlDivision').val() == "" || $('#ddlDivision').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Division </span></br>"
            }

            if ($('#ddlOffice').val() == "" || $('#ddlOffice').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Office </span></br>"
            }

            if ($('#txtDepartment').val() == "" || $('#txtDepartment').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Department </span></br>"
            }

            if ($('#txtReportingTo').val() == "" || $('#txtReportingTo').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Reporting to </span></br>"
            }

            if ($('#txtHOD').val() == "" || $('#txtHOD').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter HOD </span></br>"
            }

            if ($('#txtSBUHead').val() == "" || $('#txtSBUHead').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter SBU/Division Head </span></br>"
            }
        }

        else if ($("#ddlUserTypeV").val() == 132) {

         
            if (this.getValue.ID == undefined) { 
            let payloadv = {
                "user_Idv": $("#txtuseridE").val().toUpperCase()
            }
            this.us.GetValidateUserId(payloadv).subscribe((res: any) => {
                console.log("userid", res.data.UserProfil[0].User_Idv);
                console.log($("#txtuseridE").val());


                if ($("#txtuseridE").val().toUpperCase() === res.data.UserProfil[0].User_Idv) {
                    Swal.fire("User Id Already Exists");
                    //validation += "<span style='color:red;'>*</span> <span>Please Enter Employee Code </span></br>"
                }




            },

                (error: HttpErrorResponse) => {
                    Swal.fire(error.message)
                });
            }


           


                

            if ($('#ddlEmployeeCodeVS').val() == "" || $('#ddlEmployeeCodeVS').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Enter Employee Code </span></br>"
            }

            if ($('#ddlLandingAppE').val() == "" || $('#ddlLandingAppE').val() == null) {
                validation += "<span style='color:red;'>*</span> <span>Please Select Landing Application </span></br>"
            }

           
        }

        else {
            validation += "<span style='color:red;'>*</span> <span>Please Select User Type </span></br>"
        }



        if (validation != "") {

            Swal.fire(validation)
            return false;
        }
        
        

        this.generatedPassword = this.passwordService.generatePassword(12);
        console.log("generatepassword", this.generatedPassword);
     
       
        if ($("#ddlUserTypeV").val() == 132) {



            var useractive = 0;
            var password = "";
            var date = $('#txtDOBE').val();
            var datej = $('#txtDOJE').val();
            var datec = $('#txtDOCE').val();
            var dater = $('#txtDORE').val();
            var datenew = this.formatDateV(new Date(Date.parse(date)).toISOString().slice(0, 10));
            //var datenewj = this.formatDateV(new Date(Date.parse(datej)).toISOString().slice(0, 10));
            //var datenewc = this.formatDateV(new Date(Date.parse(datec)).toISOString().slice(0, 10));


            var datenewj;
            if (datej == "" || datej == null) {

                datenewj = "";
            }
            else {

                datenewj = this.formatDateV(new Date(Date.parse(datej)).toISOString().slice(0, 10));
            }

            var datenewc;
            if (datec == "" || datec == null) {

                datenewc = "";
            }
            else {

                datenewc = this.formatDateV(new Date(Date.parse(datec)).toISOString().slice(0, 10));
            }

            var datenewr;
            if (dater == "" || dater == null) {

                datenewr = "";
            }
            else {

                datenewr = this.formatDateV(new Date(Date.parse(dater)).toISOString().slice(0, 10));
            }
          
            if (this.getValue.ID != undefined) {
               
                if (this.getValue.Reset_Password != "") {
                 
                    password = this.getValue.Reset_Password;
                }
                else {
                 
                    password = this.generatedPassword;
                }
                this.payload = {
                    "id": this.getValue.ID,
                    "user_Id": this.getValue.UserId.toString(),
                    "user_Idv": $("#txtuseridE").val(),
                    "userType": $("#ddlUserTypeV").val(),
                    "userName": $("#txtEmpNameE").val(),
                    "user_Password": password,
                    "user_Active": $('#ddlActiveE').val(),
                    "landing_Application_Id": $('#ddlLandingAppE').val(),
                    "landing_Application": $('#ddlLandingAppE option:selected').text(),
                    "employee_Code_Id": $('#ddlEmployeeCodeVS').val(),
                    "employee_Code": $('#ddlEmployeeCodeVS option:selected').text(),
                    "employeeName": $('#txtEmpNameE').val(),
                    "shortName": $('#txtShortNameE').val(),
                    "emp_Actvie_Status": $('#ddlEmpActiveE').val(),
                    "gender": $('#ddlGenderE').val(),

                    "dob": datenew,
                    "designation": $('#txtDesignationE').val(),
                    "landline": $('#txtLandlineE').val(),
                    "mobile": $('#txtMobileE').val(),
                    "email": $('#txtEmailE').val(),
                    "doj": datenewj,
                    "doc": datenewc,
                    "dor": datenewr,

                    "division_Id": "0",
                    "division_Name": $('#ddlDivisionE').val(),
                    "office_Id": "0",
                    "office_Name": $('#ddlOfficeE ').val(),
                    "department": $('#txtDepartmentE').val(),
                    "reporting_to": $('#txtReportingToE').val(),
                    "hod": $('#txtHODE').val(),
                    "sbU_Division_Head": $('#txtSBUHeadE').val(),
                    "is_Active": this.lockID,
                    "is_Delete": 0,
                    "created_by": 0,
                    "modified_by": 1,

                    "vendorId": 0,
                    "vendorName": "",
                    "vendorBranchId": 0,
                    "vendorBranch": "",


                }
            }
            else {

                this.payload = {
                   
                    "user_Idv": $("#txtuseridE").val(),
                    "userType": $("#ddlUserTypeV").val(),
                    "userName": $("#txtEmpNameE").val(),
                    "user_Password": this.generatedPassword,
                    "user_Active": $('#ddlActiveE').val(),
                    "landing_Application_Id": $('#ddlLandingAppE').val(),
                    "landing_Application": $('#ddlLandingAppE option:selected').text(),
                    "employee_Code_Id": $('#ddlEmployeeCodeVS').val(),
                    "employee_Code": $('#ddlEmployeeCodeVS option:selected').text(),
                    "employeeName": $('#txtEmpNameE').val(),
                    "shortName": $('#txtShortNameE').val(),
                    "emp_Actvie_Status": $('#ddlEmpActiveE').val(),
                    "gender": $('#ddlGenderE').val(),
                    "dob": datenew,
                    "designation": $('#txtDesignationE').val(),
                    "landline": $('#txtLandlineE').val(),
                    "mobile": $('#txtMobileE').val(),
                    "email": $('#txtEmailE').val(),
                    "doj": datenewj,
                    "doc": datenewc,
                    "dor": datenewr,
                    "division_Id": "0",
                    "division_Name": $('#ddlDivisionE').val(),
                    "office_Id": "0",
                    "office_Name": $('#ddlOfficeE ').val(),
                    "department": $('#txtDepartmentE').val(),
                    "reporting_to": $('#txtReportingToE').val(),
                    "hod": $('#txtHODE').val(),
                    "sbU_Division_Head": $('#txtSBUHeadE').val(),
                    "is_Active": this.lockID,
                    "is_Delete": 0,
                    "created_by": 0,
                    "modified_by": 1,

                    "vendorId": 0,
                    "vendorName": "",
                    "vendorBranchId": 0,
                    "vendorBranch": "",


                }
            }
        }


        if ($("#ddlUserTypeV").val() == 133) {
            let payloadv = {
                "user_Idv": $("#txtuserid").val()
            }
            this.us.GetValidateUserId(payloadv).subscribe((res: any) => {
                console.log("userid", res.data.UserProfil[0].User_Idv);
                this.useridv = res.data.UserProfil[0].User_Idv;
                console.log("useridv", this.useridv);
                if (this.getValue.ID == undefined) {
                    if (this.useridv == this.ProfileForm.value.userid) {
                        Swal.fire("UserId Already Exist!");

                    }
                }

            },

                (error: HttpErrorResponse) => {
                    Swal.fire(error.message)
                });
          
            var date = $('#txtDOB').val();
            var datej = $('#txtDOJ').val();
            var datec = $('#txtDOC').val();
            var dater = $('#txtDOR').val();
            var datenew = this.formatDateV(new Date(Date.parse(date)).toISOString().slice(0, 10));
            //var datenewj = this.formatDateV(new Date(Date.parse(datej)).toISOString().slice(0, 10));
            //var datenewc = this.formatDateV(new Date(Date.parse(datec)).toISOString().slice(0, 10));


            var datenewj;
            if (datej == "" || datej == null) {

                datenewj = "";
            }
            else {

                datenewj = this.formatDateV(new Date(Date.parse(datej)).toISOString().slice(0, 10));
            }

            var datenewc;
            if (datec == "" || datec == null) {

                datenewc = "";
            }
            else {

               datenewc = this.formatDateV(new Date(Date.parse(datec)).toISOString().slice(0, 10));
            }

            var datenewr;
            if (dater == "" || dater == null) {

                datenewr = "";
            }
            else {

                datenewr = this.formatDateV(new Date(Date.parse(dater)).toISOString().slice(0, 10));
            }
            var password = "";
            if (this.getValue.ID != undefined) {
                if (this.getValue.Reset_Password != "") {
                    password = this.getValue.Reset_Password;
                }
                else {
                    password = this.generatedPassword;
                }
                this.payload = {
                    "id": this.getValue.ID,
                    "user_Id": this.getValue.UserId.toString(),
                    "user_Idv": $("#txtuserid").val(),
                    "userType": $("#ddlUserTypeV").val(),
                    "userName": $("#txtEmpName").val(),
                    "user_Password": password,
                    "user_Active": $('#ddlActive').val(),
                    "landing_Application_Id": $('#ddlLandingApp').val(),
                    "landing_Application": $('#ddlLandingApp option:selected').text(),
                    "employee_Code": $("#txtEmployeeCode").val(),
                    "employee_Code_Id":0,
                    "employeeName": $('#txtEmpName').val(),
                    "shortName": $('#txtShortName').val(),
                    "emp_Actvie_Status": $('#ddlEmpActiveEmp').val(),
                    "gender": $('#ddlGender').val(),
                    "dob": datenew,
                    "designation": $('#txtDesignation').val(),
                    "landline": $('#txtLandline').val(),
                    "mobile": $('#txtMobile').val(),
                    "email": $('#txtEmail').val(),
                    "doj": datenewj,
                    "doc": datenewc,

                    "dor": datenewr,
                    "division_Id": $('#ddlDivision').val(),
                    "division_Name": $('#ddlDivision option:selected').text(),
                    "office_Id": $('#ddlOffice').val(),
                    "office_Name": $('#ddlOffice option:selected').text(),
                    "department": $('#txtDepartment').val(),
                    "reporting_to": $('#txtReportingTo').val(),
                    "hod": $('#txtHOD').val(),
                    "sbU_Division_Head": $('#txtSBUHead').val(),
                    "is_Active": this.lockID,
                    "is_Delete": 0,
                    "created_by": 0,
                    "modified_by": 1,

                    "vendorId": 0,
                    "vendorName": "",
                    "vendorBranchId": 0,
                    "vendorBranch": "",


                }
            }
            else {

                this.payload = {
                    "user_Idv": $("#txtuserid").val(),
                    "userType": $("#ddlUserTypeV").val(),
                    "userName": $("#txtEmpName").val(),
                    "user_Password": this.generatedPassword,
                    "user_Active": $('#ddlActive').val(),
                    "employee_Code": $("#txtEmployeeCode").val(),
                    "employee_Code_Id": 0,
                    "landing_Application_Id": $('#ddlLandingApp').val(),
                    "landing_Application": $('#ddlLandingApp option:selected').text(),
                    "employeeName": $('#txtEmpName').val(),
                    "shortName": $('#txtShortName').val(),
                    "emp_Actvie_Status": $('#ddlEmpActiveEmp').val(),
                    "gender": $('#ddlGender').val(),
                    "dob": datenew,
                    "designation": $('#txtDesignation').val(),
                    "landline": $('#txtLandline').val(),
                    "mobile": $('#txtMobile').val(),
                    "email": $('#txtEmail').val(),
                    "doj": datenewj,
                    "doc": datenewc,

                    "dor": datenewr,
                    "division_Id": $('#ddlDivision').val(),
                    "division_Name": $('#ddlDivision option:selected').text(),
                    "office_Id": $('#ddlOffice').val(),
                    "office_Name": $('#ddlOffice option:selected').text(),
                    "department": $('#txtDepartment').val(),
                    "reporting_to": $('#txtReportingTo').val(),
                    "hod": $('#txtHOD').val(),
                    "sbU_Division_Head": $('#txtSBUHead').val(),
                    "is_Active": this.lockID,
                    "is_Delete": 0,
                    "created_by": 0,
                    "modified_by": 1,

                    "vendorId": 0,
                    "vendorName": "",
                    "vendorBranchId": 0,
                    "vendorBranch": "",


                }
            }
        }
        if ($("#ddlUserTypeV").val() == 134) {

            let payloadv = {
                "user_Idv": $("#txtUserIdV").val()
            }
            this.us.GetValidateUserId(payloadv).subscribe((res: any) => {
                console.log("userid", res.data.UserProfil[0].User_Idv);
                this.useridv = res.data.UserProfil[0].User_Idv;
                console.log("useridv", this.useridv);
                if (this.getValue.ID == undefined) {
                    if (this.useridv == this.ProfileForm.value.userid) {
                        Swal.fire("UserId Already Exist!");

                    }
                }

            },

                (error: HttpErrorResponse) => {
                    Swal.fire(error.message)
                });


            var password = "";
            var datev = $('#txtDOBV').val();
            //var datenewv = this.formatDateV(new Date(Date.parse(datev)).toISOString().slice(0, 10));

            var datenewv;
            if (datev == "" || datev == null) {

                datenewv = "";
            }
            else {

                datenewv = this.formatDateV(new Date(Date.parse(datev)).toISOString().slice(0, 10));
            }

            if (this.getValue.ID != undefined) {
                if (this.getValue.Reset_Password != "") {
                    password = this.getValue.Reset_Password;
                }
                else {
                    password = this.generatedPassword;
                }
               
              


                if (this.getValue.ID != undefined) {

                    this.payload = {
                        "id": this.getValue.ID,
                        "user_Idv": $("#txtUserIdV").val(),
                        "user_Id": this.getValue.UserId.toString(),
                        "userType": $("#ddlUserTypeV").val(),
                        "userName": $("#txtUserNameV").val(),
                        "user_Password": password,
                        "user_Active": $('#ddlActiveV').val(),
                        "employee_Code_Id": 0,
                        "employee_Code": "",
                        "landing_Application_Id": $('#ddlLandingAppV').val(),
                        "landing_Application": $('#ddlLandingAppV option:selected').text(),
                        "employeeName": "",
                        "shortName": $('#txtShortNameV').val(),
                        "emp_Actvie_Status": "",
                        "gender": $('#ddlGenderV').val(),
                        "dob": datenewv,
                        "designation": $('#txtDesignationV').val(),
                        "landline": $('#txtLandlineV').val(),
                        "mobile": $('#txtMobileV').val(),
                        "email": $('#txtEmailV').val(),
                        "doj": datenewv,
                        "doc": datenewv,
                        "dor": datenewv,
                        "division_Id": $('#ddlDivisionV').val(),
                        "division_Name": $('#ddlDivisionV option:selected').text(),
                        "office_Id": $('#ddlOfficeV').val(),
                        "office_Name": $('#ddlOfficeV option:selected').text(),
                        "department": $('#txtDepartmentV').val(),
                        "reporting_to": $('#txtReportingToV').val(),
                        "hod": $('#txtHODV').val(),
                        "sbU_Division_Head": $('#txtSBUHeadV').val(),
                        "is_Active": this.lockID,
                        "is_Delete": 0,
                        "created_by": 0,
                        "modified_by": 1,

                        "vendorId": $('#ddlVendorName').val(),
                        "vendorName": $('#ddlVendorName option:selected').text(),
                        "vendorBranchId": $('#ddlVendorBranch').val(),
                        "vendorBranch": $('#ddlVendorBranch option:selected').text(),


                    }
                }
                
            }
            else {

                this.payload = {
                    "user_Idv": $("#txtUserIdV").val(),
                    "userType": $("#ddlUserTypeV").val(),
                    "userName": $("#txtUserNameV").val(),
                    "user_Password": this.generatedPassword,
                    "user_Active": $('#ddlActiveV').val(),
                    "employee_Code_Id": 0,
                    "employee_Code": "",
                    "landing_Application_Id": $('#ddlLandingAppV').val(),
                    "landing_Application": $('#ddlLandingAppV option:selected').text(),
                    "employeeName": "",
                    "shortName": $('#txtShortNameV').val(),
                    "emp_Actvie_Status": "",
                    "gender": $('#ddlGenderV').val(),
                    "dob": datenewv,
                    "designation": $('#txtDesignationV').val(),
                    "landline": $('#txtLandlineV').val(),
                    "mobile": $('#txtMobileV').val(),
                    "email": $('#txtEmailV').val(),
                    "doj": datenewv,
                    "doc": datenewv,
                    "dor": datenewv,
                    "division_Id": $('#ddlDivisionV').val(),
                    "division_Name": $('#ddlDivisionV option:selected').text(),
                    "office_Id": $('#ddlOfficeV').val(),
                    "office_Name": $('#ddlOfficeV option:selected').text(),
                    "department": $('#txtDepartmentV').val(),
                    "reporting_to": $('#txtReportingToV').val(),
                    "hod": $('#txtHODV').val(),
                    "sbU_Division_Head": $('#txtSBUHeadV').val(),
                    "is_Active": this.lockID,
                    "is_Delete": 0,
                    "created_by": 0,
                    "modified_by": 1,

                    "vendorId": $('#ddlVendorName').val(),
                    "vendorName": $('#ddlVendorName option:selected').text(),
                    "vendorBranchId": $('#ddlVendorBranch').val(),
                    "vendorBranch": $('#ddlVendorBranch option:selected').text(),


                }
            }
        }
     
            console.log("payloadvalues", this.payload);
            if (this.getValue.ID != undefined) {

                this.us.updateProfile(this.payload).subscribe(Data => {

                    console.log(this.payload);
                    Swal.fire("Record Updated Successfully");
                    setTimeout(() => {
                        this.router.navigate(['/views/systemadmin/user/userview/userview']);
                    }, 2000);  
                },

                    (error: HttpErrorResponse) => {
                        Swal.fire(error.message)
                    });

            }
            else {

                this.us.saveAddProfile(this.payload).subscribe(Data => {

                    console.log(this.payload);
                    Swal.fire("Record Saved Successfully");
                    setTimeout(() => {
                        this.router.navigate(['/views/systemadmin/user/userview/userview']);
                    }, 2000);  
                },

                    (error: HttpErrorResponse) => {
                        Swal.fire(error.message)
                    });
            }


        


    }
    OnclickeEdit() {
        
            this.Enableformcontrol();

        this.disableSave = false;
        this.disableunlocked = false;
        this.disablelocked = false;
        this.disableResetPassword = false;
    }
    
    public isValidEmail(emailString): boolean {

        try {
            let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
            let valid = pattern.test(emailString.trim());
            return valid;
        } catch (TypeError) {
            return false;
        }

    }
    //onChange() {
    //    alert($('#txtusertype').val());
    //    $('#txtusertype').on('select2:select', (e, args) => {
    //        if ($('#txtusertype').val() == 1) {
    //            this.ShowDiv = true;
    //            this.ShowViv = false;
    //        }
    //        if ($('#txtusertype').val() == 2) {
    //            this.ShowDiv = false;
    //            this.ShowViv = true;
    //        }
    //    });
    //}

    ExistingProfileList() {
        this.ProfileForm.value.ID = this.UserID;

    }

    btntabclick(tab) {


        let value = encodeQueryParam(this.getValue);

        if (tab == 1) {

            this.router.navigate(['/views/systemadmin/user/profile/profile'], { queryParams: { value } });
        }
        else if (tab == 2) {

            this.router.navigate(['/views/systemadmin/user/roles/roles'], { queryParams: { value } });

        }
        else if (tab == 3) {

            this.router.navigate(['/views/systemadmin/user/activities/activities'], { queryParams: { value } });

        }

    }

}

interface UserActiveType {
    value: string;
    viewValue: string;
}
interface EmpActiveType {
    value: string;
    viewValue: string;
}

interface GenderType {
    value: string;
    viewValue: string;
}
interface LandingAppType {
    value: string;
    viewValue: string;
}
interface UserType {
    value: string;
    viewValue: string;
}
export function decodeQueryParam(encodedValue: string): any {
    const decodedBytes = Uint8Array.from(atob(encodedValue), c => c.charCodeAt(0));
    const decoder = new TextDecoder('utf-8');
    const decodedString = decoder.decode(decodedBytes);
    /*console.log("decode", decodedString);*/
    return JSON.parse(decodedString);
}
export function encodeQueryParam(value: any): string {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(value));
    let binary = '';
    const len = data.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(data[i]);

    }
    console.log(btoa(binary));
    return btoa(binary);

}