import { Component, Inject, OnInit,OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import Swal from 'sweetalert2';
import { MyCompany } from '../../../model/systemadmin';
import { PaginationService } from '../../../pagination.service';
// import { SystemadminService } from '../../../services/systemadmin.service';
// import { EncrDecrServiceService } from '../../../services/encr-decr-service.service'
import { WorkflowService } from 'src/app/services/workflow.service';
import { Country, DynamicPDF1 } from 'src/app/model/common';
import { Globals } from 'src/app/globals';
import { Title } from '@angular/platform-browser';
// import { PaginationService } from 'src/app/pagination.service';
import { EncrDecrServiceService } from 'src/app/services/encr-decr-service.service';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { Subject } from 'rxjs';
// import { Person } from '../person';
// import 'rxjs/add/operator/map';


declare let $: any;
// declare var jQuery:any;
@Component({
  selector: 'app-defineworkflow',
  templateUrl: './defineworkflow.component.html',
  styleUrls: ['./defineworkflow.component.css']
})


export class DefineworkflowComponent  implements  OnInit {
    // dtTrigger: Subject<any> = new Subject<any>();
    // dtOptions: DataTables.Settings = {};
   
    
    title = 'Workflow Events ';
    encryptKey:any;
   
    statusvalues: Status[] = [
        { value: 'Yes', viewValue: 'Yes' },
        { value: 'No', viewValue: 'No' },
    ];
    aclFilename: DynamicPDF1[];
    isAsc: boolean;
   
    constructor(private http: HttpClient,private httpClient: HttpClient, private globals: Globals,
       private ms: MastersService, private router: Router,
        private route: ActivatedRoute, private fb: FormBuilder,
         public ps: PaginationService, public wf: WorkflowService, private titleService: Title,
         private ES: EncrDecrServiceService, ) {

            this.encryptKey=localStorage.getItem("EncKey");
          }
  
    // array of all items to be paged
    private allItems:  any [];

  
    // pager object
    pager: any = {};
  
    // paged items
    pagedItems: any[];
  
    DepartmentList: any = [];
    // dataSource: Country[];
    searchForm: FormGroup;
    ActivateAddEditDepComp: boolean = false;
    dep: any;
    ApplicationItem: any = { Data: [] };
    DepartmentIdFilter: string = "";
    DepartmentNameFilter: string = "";
    DepartmentListWithoutFilter: any = [];
    currentPage=1;
    // ngOnDestroy(): void {
    //     // Do not forget to unsubscribe the event
    //     this.dtTrigger.unsubscribe();
    //   }
  
    ngOnInit(): void {

        this.isAsc= false;
        this.createForm();
       
        this.titleService.setTitle(this.title);
        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id
            $('#ddlApplication').on('select2:select', (e, args) => {
              this.searchForm.patchValue({application:($("#ddlApplication").val())});
            });     
            $('#ddlStatusv').on('select2:select', (e, args) => {
              this.searchForm.patchValue({active:($("#ddlStatusv").val())});
            });    
            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        }) 
        // $('#ddlApplication').on('select2:select', (e, args) => {
        //     this.ApplicationItem($("#ddlApplication").val());
        //   });  
       
       
          $('#ddlStatusv').val(0).trigger("change");
          $('#ddlApplication').val(0).trigger("change");
        this.RuleList();
        this.clearSearch();
        this.applicationBinding();
        // this.dtOptions = {
        //     pagingType: 'full_numbers',
        //   };
       
      
          
    }
    public jsonToStr(data){
      console.log("jsonToStr",data)
      let queryObj= {id:data.id,eventnumber:data.eventnumber}
      return JSON.stringify(queryObj || {})
    }
    public sortOrderForMulityObj(arrObj: Array<any>, field: any, isAsc:any) {

        console.log('field',arrObj, field,isAsc)
        this.isAsc=!isAsc
        arrObj=this.allItems;
        arrObj.sort((a, b) => {
          let first: any;
          let sec: any;
          let fieldArr: any = field.split('.');
          for (let i = 0; i < fieldArr.length; i++) {
            a = a[fieldArr[i]];
            b = b[fieldArr[i]]
          }
          if(a){
          first=a;
          sec=b;
          }
          //console.log('first',first)
          if (typeof first === 'string' || first instanceof String) {
            first = first.toLocaleLowerCase(); sec = sec.toLocaleLowerCase();
          }
          if (isAsc)
            return first < sec ? -1 : first > sec ? 1 : 0;
          else
            return first > sec ? -1 : first < sec ? 1 : 0;
    
          //return  this.isAsc?res:!res
        })
        this.setPage(this.currentPage)
        //this.sortOrderForMulityObj(this.pagedItems,'eventname',this.isAsc)
        return this.pagedItems;
      }

    // sortOrder(field:any,isAsc){
    //     this.isAsc=!isAsc;
    //     console.log('field',field)
    //     this.allItems.sort((a, b) => {
    //       let first:any=a[field]; let sec:any=b[field];
    //       if (typeof first === 'string' || first instanceof String){
    //       first=first.toLocaleLowerCase(); sec=sec.toLocaleLowerCase();
    //       }
    //       if( this.isAsc)
    //      return first < sec ? -1 : first > sec ? 1 : 0;
    //      else
    //      return first >sec ? -1 :first< sec ? 1 : 0;
    
    //      //return  this.isAsc?res:!res
    //     })
    //   }
    
    RuleList() {
        this.wf.getDefineList().subscribe((data: any) => {
            // set items to json response
            this.allItems = data.Data;
            this.sortOrderForMulityObj(this.allItems,'eventnumber',this.isAsc)
            // this.dtTrigger.next();
            console.log("3233",data);
            // initialize to page 1
            this.setPage(1);

        },error => {
          this.allItems = []
          this.setPage(1)

    });

    }

    applicationBinding() {
        console.log("application", this.searchForm.value);
        this.wf.getApplicationList({ "type": "application" }).subscribe(data => {
          this.ApplicationItem = data;
          $('#ddlApplication').val(0).trigger("change");
        });
    
      }
  
    createForm() {
        this.searchForm = this.fb.group({
            ID: 0,
            RuleName: '',
            CountryCode: '',
            // Status: 0,
            pdfFilename:'',
            eventnumber:'',
            eventname:'',
            application:'',
            module:'',
            active:'',
            description:'',

        });
    }
    clearSearch() {     
        this.searchForm.value.Status = $('#ddlStatusv').val();
        this.createForm()
        this.RuleList();
        $('#ddlStatusv').val(0).trigger("change");
        $('#ddlApplication').val(0).trigger("change");
    }
  
    onSubmit() {
        this.searchForm.value.Status = $('#ddlStatusv').val();
        this.wf.getSearchList(this.searchForm.value).subscribe((data:any) => {
            // set items to json response
            // this.allItems = country;
            this.allItems = data.Data;
          
            console.log("222",data);
            
  
            // initialize to page 1
            this.setPage(1);
  
        },error => {
          this.allItems = []
          this.setPage(1)
        });
       
    }
   
    public selectedName: any;
    public highlightRow(dataItem) {
        this.selectedName = dataItem.CountryCode;
    }
    setPage(page: number) {
        this.pager = this.ps.getPager(this.allItems.length, page);
        this.currentPage=page;
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
        //this.pagedItems=this.sortOrderForMulityObj(this.pagedItems,'eventnumber',this.isAsc)
        // this.pagedItems=this.sortOrderForMulityObj(this.pagedItems,'eventname',this.isAsc)

    }
   
    pdflink() {
  
        this.ms.getPDF(this.searchForm.value).subscribe(data => {
            console.log(data[0].aclFilename);
            window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
        });
        
        //alert('hi');
     //this.http.get(this.globals.APIURL + '/home/BLPrintPDF/').subscribe(res => {
  
     //   });
       
      
        /*window.open(RouterLink("BLPrintPDF", "BLPrint")');*/
  
        /*window.open("assets/pdfFiles/test.pdf");*/
    }
  
    //openpdf() {
    //    $('#pdfview').modal('show');
    //}
  
    isDesc: boolean = false;
    column: string = '';
    sort(property) {
        this.isDesc = !this.isDesc; 
        this.column = property;
        let direction = this.isDesc ? 1 : -1;
  
        this.allItems.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        });
    };
  
  }
  interface Status {
    value: string;
    viewValue: string;
  }
  
  
 
  


