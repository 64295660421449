export class useraccess {
    ID = 0;
    UserType= "";
    User_Id = "";
    UserName = "";
    User_Password = "";
    User_Active = 0;
    Employee_Code = "";
    EmployeeName = "";
    ShortName = "";
    Emp_Actvie_Status = "";
    Landing_Application = "";
    Gender = "";
    DOB = "";
    Designation = "";
    Landline = "";
    Mobile = "";
    Email = "";
    DOJ = "";
    DOC = "";
    DOR = "";
    Division_Name = "";
    Office_Id = 0;
    Department = "";
    Reporting_to = "";
    HOD = "";
    SBU_Division_Head = "";
    Is_Active: 0
}
export class Customer {
    CustomerDivisionID: Number = 0;
    DivisionID: Number = 0;
    CustomerID: Number = 0;
}
export class DivisionTypes {
    Id = 0;
    DivisionName = '';
    //IsTrue: boolean = false;
}
export class DivisionLable {
    ID = 0;
    OrgId: 0;
    DivisionCode: "";
    DivisionName: "";
    ShortName: "";
    Application: "";
    Active: 0;
 
}
export class Division {
    CustomerDivisionID: Number = 0;
    CustomerID = 0;
    DivisionID: Number = 0;
}
