import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../../services/encr-decr-service.service';
import { useraccess } from '../../../../model/useraccessdata';
import { UseraccessService } from '../../../../services/useraccess.service';
import { DynamicNotificationEmail, MyOffice } from '../../../../model/systemadmin';
import { SystemadminService } from '../../../../services/systemadmin.service';
declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { on } from 'process';


@Component({
  selector: 'app-userrolesview',
  templateUrl: './userrolesview.component.html',
  styleUrls: ['./userrolesview.component.css']
})
export class UserrolesviewComponent implements OnInit {
    RoleForm: FormGroup;
    RoleValues = [];
    RoleValuesList = [];

    LandingValues: LandingAppType[] = [
        { value: '1', viewValue: 'MANAGEMENT DASHBOARD' },
        { value: '2', viewValue: 'LINER AGENCY' },
        { value: '3', viewValue: 'FREIGHT FORWARDING' },
        { value: '4', viewValue: 'FINANCE' },
        { value: '5', viewValue: 'SYSTEM ADMIN' },
    ];
    ActiveValues: UserActiveType[] = [
        { value: '1', viewValue: 'YES' },
        { value: '0', viewValue: 'NO' },
    ];
    public Active: string = "YES";
    public InActive: string = "NO";
    constructor(private router: Router, private ES: EncrDecrServiceService, private route: ActivatedRoute, private sa: SystemadminService, private us: UseraccessService, private fb: FormBuilder) { }

    ngOnInit(): void {
        $('.my-select').select2();
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id

            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ) {
                value.focus();
            })
        });
        this.createForm();
        this.BindDefineRoleList();
        this.clearSearch();
    }

    createForm() {
        this.RoleForm = this.fb.group({
            landing_Application: 0,
        });
    }

    BindDefineRoleList() {
        var roleId = "";
        var roleName = "";
        var appId = 0;
        var useractive = 0;
        let numberlandingapp = Number($('#ddlLandingAppv').val());
       
        if ($('#ddlroleId').val() != "" || $('#ddlroleId').val() != null) {
            if ($('#ddlroleId').val() == "") {
                roleId = "";
            }
            else {
                roleId = $('#ddlroleId').val();
            }

        }

        if ($('#ddlroleName').val() != "" || $('#ddlroleName').val() != null) {
            if ($('#ddlroleName').val() == "") {
                roleName = "";
            }
            else {
                roleName = $('#ddlroleName').val();
            }

        }
        if ($('#ddlroleName').val() != "" || $('#ddlroleName').val() != null) {
            if ($('#ddlroleName').val() == "") {
                roleName = "";
            }
            else {
                roleName = $('#ddlroleName').val();
            }

        }
        if ($('#ddlLandingAppv').val() != "" || $('#ddlLandingAppv').val() != null) {
            
            appId = numberlandingapp;
        }
            else {

            appId = 0;
            }
        if ($('#ddlActive').val() == 0) {
            
            useractive = 1;
            
        }
            
            else {
                useractive = 0;
            }

       
       
        let payload = {
            "role_Id": roleId,
            "division_Id": 0,
            "division_Name": "",
            "office_Id": 0,
            "ref_Role_Id_sch": "",
            "Role_Name": roleName,
            "landing_Application_Id": appId,
            "is_Active": useractive,
            "menu_Type": 0

        }
        this.us.GetRoleDefineList(payload).subscribe(res => {
            console.log("alldata", res.data);
            
            if (res.statuscode == 200) {
                this.RoleValues = res.data.UserRoles;
            }
            else {
                this.RoleValues.length = 0;
            }
          
           

        });
    }

    onSubmit() {
        this.BindDefineRoleList();
    }

    clearSearch() {
        $('#ddlroleId').val("").trigger("change");
        $('#ddlroleName').val("").trigger("change");
        $('#ddlLandingAppv').val(0).trigger("change");
        $('#ddlActive').val(0).trigger("change");
        this.BindDefineRoleList();
    }

    OnSelectRow(dataItem: any) {
        let payload = {
            "role_Id": dataItem.Role_Id,
            "division_Id": 0,
            "division_Name": "",
            "office_Id": 0,
            "ref_Role_Id_sch": "",
            "is_Active": 1
        }
        
        console.log("definelist", dataItem.role_define_list);
        let value = encodeQueryParam(dataItem);
      
        this.router.navigate(['/views/systemadmin/userroles/userroles'], { queryParams: { value } });
        localStorage.setItem("screen", JSON.stringify(false));
    }

}
export function encodeQueryParam(value: any): string {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(value));
    let binary = '';
    const len = data.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(data[i]);

    }
    console.log(btoa(binary));
    return btoa(binary);

}
interface LandingAppType {
    value: string;
    viewValue: string;
}
interface UserActiveType {
    value: string;
    viewValue: string;
}