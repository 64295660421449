import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { DynamicNotificationEmail, DynamicNotificationSystem, NotificationBasic, NotificationSystem } from 'src/app/model/Notification';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient, private globals: Globals) { }
    public authToken = "";

    loadToken() {
        const token = localStorage.getItem('Token');
        this.authToken = token;
    }
    createAuthHeader() {
        this.loadToken();
        const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${this.authToken}`
        );
        return { headers };
    }


    saveNotification(OD: NotificationBasic) {

        return this.http.post(this.globals.APIURL + '/Notification/SaveNotificationBasic/', OD);
    }
    getNotificaionBasicView(OD: NotificationBasic): Observable<NotificationBasic[]> {
        return this.http.post<NotificationBasic[]>(this.globals.APIURL + '/Notification/NotificationBasicList', OD);
    }
    getExistingNotificaionBasic(OD: NotificationBasic): Observable<NotificationBasic[]> {
        return this.http.post<NotificationBasic[]>(this.globals.APIURL + '/Notification/ExistingNotificationBasic', OD);
    }
    saveNotificationEmail(OD: DynamicNotificationEmail) {

        return this.http.post(this.globals.APIURL + '/Notification/SaveNotificationEmail/', OD);
    }
    GetNotificationEmailDtls(OL: DynamicNotificationEmail): Observable<DynamicNotificationEmail[]> {
        return this.http.post<DynamicNotificationEmail[]>(this.globals.APIURL + '/Notification/BindNotificationEmail/', OL, this.createAuthHeader());
    }
    DeleteNotificationEmailDtls(OL: DynamicNotificationEmail): Observable<DynamicNotificationEmail[]> {
        alert('notifymail');
        return this.http.post<DynamicNotificationEmail[]>(this.globals.APIURL + '/Notification/DeleteNotificationEmailDtls', OL, this.createAuthHeader());
    }
    saveNotificationSystem(OD: DynamicNotificationSystem) {

        return this.http.post(this.globals.APIURL + '/Notification/SaveNotificationSystem/', OD);
    }
    GetExistingNotificationSystemDtls(OL: NotificationSystem): Observable<NotificationSystem[]> {
        return this.http.post<NotificationSystem[]>(this.globals.APIURL + '/Notification/BindExistingNotificationSystem/', OL, this.createAuthHeader());
    }
    GetNotificationSystemDtls(OL: DynamicNotificationSystem): Observable<DynamicNotificationSystem[]> {
        return this.http.post<DynamicNotificationSystem[]>(this.globals.APIURL + '/Notification/BindNotificationSystem/', OL, this.createAuthHeader());
    }
    DeleteNotificationSystemDtls(OL: DynamicNotificationSystem): Observable<DynamicNotificationSystem[]> {
        return this.http.post<DynamicNotificationSystem[]>(this.globals.APIURL + '/Notification/DeleteNotificationSystemDtls', OL, this.createAuthHeader());
    }
}
