

import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import Swal from 'sweetalert2';
import { WorkflowService } from 'src/app/services/workflow.service';
import { PaginationService } from 'src/app/pagination.service';
import { EncrDecrServiceService } from 'src/app/services/encr-decr-service.service';
import { MyCompany, MySACommon } from 'src/app/model/systemadmin';
import { Country, DynamicPDF1, MyAgency } from 'src/app/model/common';
import { Globals } from 'src/app/globals';
import { Title } from '@angular/platform-browser';
import { CustomerContract } from 'src/app/model/CustomerContract';
import { group } from 'console';
import { ChangeDetectorRef } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-workbench',
  templateUrl: './workbench.component.html',
  styleUrls: ['./workbench.component.css']
})


export class WorkbenchComponent  implements OnInit {

  myControl = new FormControl('');
  title = 'Work Bench ';
  currentTab: any;
  service: any;
  deleteId: any;
  private id: number;
  eventnumber: any;
  complianceRowId: number;
  pageSection: number;
  i: number = -1;
  addbenchlist: any = [];
  addprogress: any = [];
  tabsForm: FormGroup;
  encryptedParam: any;
  isEdit: boolean = false;
  isAsc: boolean;

  statusvalues: Status[] = [
    { value: '1', viewValue: 'Yes' },
    { value: '2', viewValue: 'No' },
  ];
  aclFilename: DynamicPDF1[];
  dscountryItem: any = { Data: [] };
  ApplicationItem: any = { Data: [] };

  addItem: any = { Data: [] };
  number: any;
  name: any;
  eventname: any;
  forward: any;
  objectno: any;
  showpopup: boolean = false;
  dataItems: any;
  selectedItem: any;
  eventlist:any = []
  // show: boolean;
// dataItems: any;
 

  constructor(private http: HttpClient, private globals: Globals,private cdr: ChangeDetectorRef,
    private ms: MastersService, private router: Router,
    private route: ActivatedRoute, private fb: FormBuilder,
    public ps: PaginationService, public wf: WorkflowService, private titleService: Title, private ES: EncrDecrServiceService,
  ) {
    this.forwardForm = this.fb.group(
      {
        workflowno:"",
        newuser:"",
        olduser:"",

      });
    this.progressForm = this.fb.group({
      // isFinalapproval: false,
      // step: '',
      // user:'',
      // date:'',
      workflowno:'',

    })

    // this.newList();

    // this.route.queryParams.subscribe(params => {
    //   let eventNumber = this.ES.get(localStorage.getItem("EncKey"), params.encrypted)
    //   this.encryptedParam = params.encrypted;
    //   console.log('params', params.encrypted, eventNumber)
    //   this.benchForm.patchValue({
    //     eventnumber: eventNumber
    //   })


    //   this.tabsForm = this.fb.group({
    //     ID: eventNumber,

    //   });
    // });


  }
  triggerChangeDetection() {
    this.cdr.detectChanges();
  }
  // array of all items to be paged
  private allItems: any = { Data: [] };

  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  private allItemsRules: any = { Data: [] };
  // pager object
  pagerRules: any = {};
  // paged items
  pagedItemsRules: any[];
  private allItemsParams: any = { Data: [] };
  // pager object
  pagerParams: any = {};
  // paged items
  pagedItemsParams: any[];
  DepartmentList: any = [];
  dataSource: Country[];
  benchForm: FormGroup;
  progressForm:FormGroup;
  forwardForm:FormGroup;
  currentPage=1;

  // conditionForm: FormGroup;
  ActivateAddEditDepComp: boolean = false;
  dep: any;

  DepartmentIdFilter: string = "";
  DepartmentNameFilter: string = "";
  DepartmentListWithoutFilter: any = [];

  ngOnInit(): void {
    // this.currentTab = 'basics'


    this.isAsc=false
this.createForm()
    this.titleService.setTitle(this.title);
    $('.my-select').select2();
    $(document).on('select2:open', (e) => {
      const selectId = e.target.id
      //payload send code
      $('#ddlApplication').on('select2:select', (e, args) => {
        this.benchForm.patchValue({application:($("#ddlApplication").val())});
      });     
      $('#wfstatus').on('select2:select', (e, args) => {
        this.benchForm.patchValue({wfstatus:($("#wfstatus").val())});
      });
      $('#eventnumber').on('select2:select', (e, args) => {
        this.benchForm.patchValue({eventnumber:($("#eventnumber").val())});
      }); 
      $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
        key,
        value,
      ) {
        value.focus();
      })
    })
  

    this.getEventNumber();
    this.getSearchBench();
    this.applicationBinding();

      
 
    // this.getProgressList();
  
    // this.route.queryParams.subscribe(
    //   params => {

    //     let eventNumber = this.ES.get(localStorage.getItem("EncKey"), params.encrypted)
    //     console.log('params', params.encrypted, eventNumber)
    //     // this.getconditionByNumber(eventNumber)

    //     this.eventnumber = eventNumber

    //   }
    // )

  }
  createForm(){
    this.benchForm = this.fb.group(
      {
        // id: 0,
        
       workflowno:"",
       eventnumber:"",
       eventname:"",
       application:"",
       module:"",
       initiator:"",
       objectno:"",
       pendingapproval:"",
       wfstatus: ['pending', Validators.required],   
        
      //  r:'',

      });
      $("#wfstatus").val(this.benchForm.value.wfstatus).change();

  }
 
  applicationBinding() {
    console.log("application", this.benchForm.value);
    this.wf.getApplicationList({ "type": "application" }).subscribe(data => {
      this.ApplicationItem = data;
    });

  }

  displayStyle = "none";
  
openPopup(dataItems) {
  if(!this.selectedItem || this.i == -1  )return
  // window.location.reload();
  
  
  if(Object.keys(this.selectedItem).length>0){
    this.progressForm.patchValue({
      workflowno:this.selectedItem?.workflowno
    })
    this.forwardForm.patchValue({
      workflowno:this.selectedItem?.workflowno, 
    })
  this.getProgressList()
  this.displayStyle = "block"; 
  console.log("11111",this.selectedItem);
  this.name=this.selectedItem.workflowno;
  this.eventname=this.selectedItem.eventname;
  this.objectno=this.selectedItem.objectno;
  }
  // this.progressForm.patchValue({
  //   workflowno:this.name
  // })
}
closePopup() {
  this.i= -1
  this.selectedItem=null
  this.displayStyle = "none";
}

dispStyle = "none";
  
openPop(dataItems) {
  this.getProgressList()
  this.dispStyle = "block"; 
  console.log("11111",this.eventname);
  this.name=dataItems.workflowno;
  this.eventname=dataItems.eventname;
  this.objectno=dataItems.objectno;
  // this.progressForm.patchValue({
  //   workflowno:this.name
  // })

}
closePop() {
  this.dispStyle = "none";
}

  
public sortOrderForMulityObj(arrObj: Array<any>, field: any, isAsc:any) {

  console.log('field',arrObj, field,isAsc)
  this.isAsc=!isAsc
  arrObj=this.addbenchlist;
  arrObj.sort((a, b) => {
    let first: any;
    let sec: any;
    let fieldArr: any = field.split('.');
    for (let i = 0; i < fieldArr.length; i++) {
      a = a[fieldArr[i]];
      b = b[fieldArr[i]]
    }
    if(a){
    first=a;
    sec=b;
    }
    //console.log('first',first)
    if (typeof first === 'string' || first instanceof String) {
      first = first.toLocaleLowerCase(); sec = sec.toLocaleLowerCase();
    }
    if (isAsc)
      return first < sec ? -1 : first > sec ? 1 : 0;
    else
      return first > sec ? -1 : first < sec ? 1 : 0;

    //return  this.isAsc?res:!res
  })
  this.setPage(this.currentPage)
  //this.sortOrderForMulityObj(this.pagedItems,'eventname',this.isAsc)
  return this.pagedItems;
}

  onChange(data, i: number) {
    // this.id = data;
    this.selectedItem=data;
    this.i = i;
   
  }


  clearSearch() {
    // this.selectedItem=null;
    // this.i = -1; 
    // this.conditionForm.value.conditionoperator = $('#conditionoperator').val();
    this.createForm()
    $("#eventnumber").val("0").change();
    $("#ddlApplication").val("0").change();
    $("#initiator").val("0").change();
    $("#pendingapproval").val("0").change();
    $("#wfstatus").val("pending").change();

    this.getSearchBench();
    // $('#conditionoperator').val(0).trigger("change");
  }
  getSearchBench() {
    this.selectedItem=null;
    this.i = -1; 
       this.wf.getSearchBench(this.benchForm.value).subscribe((data:any) => {
      // set items to json response
      if(!data?.Data || data?.Data?.length == 0){
        this.addbenchlist =[]
        this.setPage(1);
        return Swal.fire("No Record Found")
      }
      // else if(!this.id && this.i>-1){
      //   this.addbenchlist.splice(this.i,1) 
      //     this.setPage(1)
      //     console.log("deleteContainer", this.id , this.i,this.addbenchlist);

  
      // }
      this.addbenchlist = data?.Data;
      
      console.log("getSearchBench", data);
      // initialize to page 1
      this.setPage(1);
    
    },error => {
      this.addbenchlist = []
      this.setPage(1);
    });
  }
 
  getEventNumber(){
    let payload = {
      "workflowno": "",
      "eventnumber": "",
      "eventname": "",
      "application": "",
      "module": "",
      "initiator": "",
      "objectno": "",
      "pendingapproval": "",
      "wfstatus": ""
  }
 // Assuming eventlist is an array of objects with eventnumber property

this.wf.getSearchBench(payload).subscribe(
  (data: any) => {

    if (!data?.Data || data?.Data?.length === 0) {
      this.eventlist = [];
      return;
    }

   
    const uniqueEventNumbers = new Set(data.Data.map(item => item.eventnumber));

    
    this.eventlist = Array.from(uniqueEventNumbers).map(eventnumber => {
    
      return data.Data.find(item => item.eventnumber === eventnumber);
    });

    console.log("getSearchBench", data);
   
  },
  error => {
    this.eventlist = [];
  }
);

  }
  getProgressList() {
    let root=this;
    this.wf.getProgressList(this.progressForm.value).subscribe((data:any) => {
      // set items to json response
      let mapUserPool=data?.Data.reduce((acc: any, item: any) => {
        const existingItem = acc.find((group: any) => group.step === item.step);

        if (existingItem) {
          existingItem.user = existingItem?.user + ', ' + item?.user;
        } else {
          acc.push(item);
        }
        return acc;
         }, []);
      root.addprogress = mapUserPool;
   let  firstPendingUser = root.addprogress.find(x=>x.status=='pending');
   if(firstPendingUser){
      this.forwardForm.patchValue({
        olduser:firstPendingUser?.user
        
      })
    }
      // this.sortOrderForMulityObj(this.addprogress,'workflowno',this.isAsc)
      console.log("getProgressList", data, root.forwardForm.value);
      // initialize to page 1
      this.setPage(1);
      
    });
  }
  forwardUser() {
     let root=this;
    this.wf.forwardUser(this.forwardForm.value).subscribe((data:any) => {
      // set items to json response
      this.forward = data?.Data;
      // this.sortOrderForMulityObj(this.addprogress,'workflowno',this.isAsc)
      console.log("forwardUser", data);
      // Swal.fire('Saved successfully');
      var validation = "";
      if (this.forwardForm.value.newuser == "") {
        validation += "<span style='color:red;'>*</span> <span>Please Enter Forward to</span></br>"
      }
      if (validation != "") {

        Swal.fire(validation)
        return false;
      }
      Swal.fire({
        title: "",
        text: 'Saved successfully',       
    }).then(function() {
      console.log('root.basicForm',root.forwardForm.value)
      if(root.forwardForm.value)
       window.location.reload();
      //  this.forwardUser(root.forwardForm?.value?.eventnumber)
    });

      // initialize to page 1
      // this.setPage(1);
    });
  }
  
  
  
  
  // getcondition() {

  //   this.wf.getcondition().subscribe((data: any) => {

  //     if (Array.isArray(data.Data)) {
  //       let condition = data.Data.filter(val => val.eventnumber == this.eventnumber)
  //       this.addholelist = condition;
  //     }
  //     console.log("getcondition", data);
  //   });
  // }



  onSubmit(type:any) {
   if(type == 'search')
   this.getSearchBench();
   if(type == 'forward')
   this.forwardUser();

    // var validation = "";
    // if (this.conditionForm.value.conditionnumber == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Condition Number</span></br>"
    // }
    // if (this.conditionForm.value.conditionname == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Condition Name</span></br>"
    // }
    // if (this.conditionForm.value.rulenumber == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Rule Number</span></br>"
    // }
    // if (this.conditionForm.value.rulename == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Rule Name </span></br>"
    // }
    // if (this.conditionForm.value.validation_type == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Validation Type </span></br>"
    // }
    // if (this.conditionForm.value.conditiontype == "") {
    //   validation += "<span style='color:red;'>*</span> <span>Please Enter Condition Type </span></br>"
    // }

    // if (validation != "") {

    //   Swal.fire(validation)
    //   return false;
    // }
      
  }

  public selectedName: any;
  public highlightRow(dataItems) {
    this.selectedName = dataItems.CountryCode;
    
    this.number=dataItems.workflowno;
    console.log("hdsfghdaf",this.number)
    this.progressForm.patchValue({
      workflowno:this.number
    })
   

  }

  setPage(page: number) {
    //if (page < 1 || page > this.pager.totalPages) {
    //    return;
    //}

    // get pager object from service
    this.pager = this.ps.getPager(this.addbenchlist.length, page);
    this.currentPage=page;
    // get current page of items
    this.pagedItems = this.addbenchlist.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // this.pagedItems=this.sortOrderForMulityObj(this.pagedItems,'workflowno',this.isAsc)

  }

  pdflink() {

    this.ms.getPDF(this.benchForm.value).subscribe(data => {
      console.log(data[0].aclFilename);
      window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
    });

    //alert('hi');
    //this.http.get(this.globals.APIURL + '/home/BLPrintPDF/').subscribe(res => {

    //   });


    /*window.open(RouterLink("BLPrintPDF", "BLPrint")');*/

    /*window.open("assets/pdfFiles/test.pdf");*/
  }

  //openpdf() {
  //    $('#pdfview').modal('show');
  //}

  isDesc: boolean = false;
  column: string = '';
  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.pagedItems.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

 

}
interface Status {
  value: string;
  viewValue: string;
}


