import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { useraccess } from 'src/app/model/useraccessdata';
//import { GeneralMaster } from 'src/app/model/common';
import { Globals } from '../globals';
import { EmployeeMiniMaster, MySACommon, MyUsersRoles, UserRoles } from '../model/systemadmin';


@Injectable({
  providedIn: 'root'
})
export class UseraccessService {
    payload: any = "";
    constructor(private http: HttpClient, private globals: Globals) { }

    GetUserType(): Observable<any> {
        
        return this.http.get<any>(this.globals.APIURLFF + '/Login/GetUserType');
    }

    saveAddProfile(payload:any) {
        
        return this.http.post<any>(this.globals.APIURLFF + '/Login/AddProfile', payload);
    }

    updateProfile(payload: any) {
        
        return this.http.post<any>(this.globals.APIURLFF + '/Login/UpdateProfile', payload);
    }
    GetProfileList(payload: any) {
       
        return this.http.post<any>(this.globals.APIURLFF + '/Login/GetLoginProfile',payload);
    }
    GetVendorName(): Observable<any> {

        return this.http.get<any>(this.globals.APIURL_LA + '/LACommon/BindVendorName');
    }
    GetVendorBranch(): Observable<any> {

        return this.http.get<any>(this.globals.APIURL_LA + '/LACommon/BindVendorBranch');
    }

    GetProfileEdit(payload: any) {
      
        return this.http.post<any>(this.globals.APIURLFF + '/Login/GetLoginProfile', payload);
    }
    GetUserRoles(payload: any): Observable<any> {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/GetAllMenulist', payload);
    }
    saveUserRole(payload: any) {
       
        return this.http.post<any>(this.globals.APIURLFF + '/Login/AddDefineRoles/', payload);
    }

    GetRoleDefineList(payload: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/GetRoleDefineList', payload);
    }
     GetRoleDropDownListOnChange(payload: any) {

         return this.http.post<any>(this.globals.APIURLFF + '/Login/GetRoleFillList', payload);
     }
    GetRoleDropDownList(payload1: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/GetRoleFill', payload1);
    }
    saveUserRoleList(payload: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/AddUserRoles/', payload);
    }
    GetRoleExistingDtls(payload: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/GetUserRole', payload);
    }

    GetDivisionDropDown(payloaddivision: any) {

        return this.http.post<any>(this.globals.APIURLFN + '/Division/GetOrganizationDivisionsList', payloaddivision);
    }
    GenerateUserPermissionObject(payload1: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/GeneratePermissionObject/', payload1);
    }
    GenerateUserPermissionObjectView(payload2: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/GetUserPermissionObject', payload2);
    }

    GetDivisionByFFD(payloaddivffd: any) {

        return this.http.post<any>(this.globals.APIURLFN + '/Division/GetOrganizationDivisionById', payloaddivffd);
    }
    GetDivisionByLA(payloaddivla: any) {

        return this.http.post<any>(this.globals.APIURLFN + '/Division/GetOrganizationDivisionById', payloaddivla);
    }
    GenerateUpdateUserPermissionObject(payload: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/GeneratePermissionObject_Update/', payload);
    }
    GenerateUserPermissionObjectViewAll(payload2: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/GetUserPermissionObjectCombinedRoles/', payload2);
    }
    GetOffice(): Observable<any>{
        return this.http.post<any>(this.globals.APIURLFN + '/Office/GetOrganizationOfficeList', null);
    }
    GetOrgOffice(OL: MySACommon): Observable<MySACommon[]> {
        return this.http.post<any>(this.globals.APIURL + '/CommonAccessApi/OrganizationOfficeList', OL);
    }
    GetEmployeeList() {
        return this.http.get<any>(this.globals.APIURLFN + '/EmployeeMiniMaster/GetEmployeeList');
    }
    GetUserIDByEmpCodeFFD(payload:any) {
      
        return this.http.get<any>(this.globals.APIURLFN + '/EmployeeMiniMaster/GetEmployeeList?EmployeeCode=' + payload);
    }

    GetValidateUserId(payload: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/ValidateUserId/', payload);
    }
    GetGeneratorBind(payload: any) {

        return this.http.post<any>(this.globals.APIURLFF + '/Login/BindGenerator', payload);
    }
    GetAutoRolId(OL: MyUsersRoles): Observable<MyUsersRoles[]> {
        return this.http.post<any>(this.globals.APIURL + '/SystemAdminApi/UsersRols', OL);
    }
    GetEmployeeCode(OL: EmployeeMiniMaster): Observable<EmployeeMiniMaster[]> {
        return this.http.post<any>(this.globals.APIURL + '/UserApi/EmployeeCode', OL);
    }
    GetEmployeeMaster(OL: EmployeeMiniMaster): Observable<EmployeeMiniMaster[]> {
      
        return this.http.post<any>(this.globals.APIURL + '/UserApi/EmployeeMaster', OL);
    }
    GetEmployeeMasterDetails(OL: EmployeeMiniMaster): Observable<EmployeeMiniMaster[]> {
       
        return this.http.post<any>(this.globals.APIURL + '/UserApi/EmployeeMasterDetailsBind', OL);
    }
    GetEmployeeMasterView(OL: EmployeeMiniMaster): Observable<EmployeeMiniMaster[]> {

        return this.http.post<any>(this.globals.APIURL + '/UserApi/EmployeeMasterBind', OL);
    }
    ResetPassword(OL: EmployeeMiniMaster): Observable<EmployeeMiniMaster[]> {

        return this.http.post<any>(this.globals.APIURL + '/UserApi/PasswordRest', OL);
    }

    GetRoles(OL: UserRoles): Observable<UserRoles[]> {
        alert('getroles');
        return this.http.post<any>(this.globals.APIURL + '/UserApi/GetRoles', OL);
    }

    SaveUserRoleListNew(payload: any) {

        return this.http.post<any>(this.globals.APIURL + '/UserApi/SaveUserRoles/', payload);
    }

    GetUserRolesList(OL: UserRoles): Observable<UserRoles[]> {       
        return this.http.post<any>(this.globals.APIURL + '/UserApi/GetUserRoles', OL);
    }

    GetRolesChange(OL: UserRoles): Observable<UserRoles[]> {
        return this.http.post<any>(this.globals.APIURL + '/UserApi/GetRoleChange', OL);
    }
    GetValidateUser(OL: EmployeeMiniMaster): Observable<EmployeeMiniMaster[]> {
        return this.http.post<any>(this.globals.APIURL + '/UserApi/GetUserValidateUser', OL);
    }
    
}
