import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CountryComponent } from './views/masters/commonmaster/country/country.component';
import { Countryview1Component } from './views/masters/commonmaster/country/countryview1/countryview1.component';
import { CityComponent } from './views/masters/commonmaster/city/city.component';
import { CityviewComponent } from './views/masters/commonmaster/city/cityview/cityview.component';
import { CommodityComponent } from './views/masters/commonmaster/commodity/commodity.component';
import { CommodityviewComponent } from './views/masters/commonmaster/commodity/commodityview/commodityview.component';
import { CargopackageComponent } from './views/masters/commonmaster/cargopackage/cargopackage.component';
import { CargopackageviewComponent } from './views/masters/commonmaster/cargopackage/cargopackageview/cargopackageview.component';
import { PortComponent } from './views/masters/commonmaster/port/port.component';
import { PortviewComponent } from './views/masters/commonmaster/port/portview/portview.component';
import { TerminalComponent } from './views/masters/commonmaster/terminal/terminal.component';
import { TerminalviewComponent } from './views/masters/commonmaster/terminal/terminalview/terminalview.component';
import { UommasterComponent } from './views/masters/commonmaster/uommaster/uommaster.component';
import { UommasterviewComponent } from './views/masters/commonmaster/uommaster/uommasterview/uommasterview.component';
import { StatemasterComponent } from './views/masters/commonmaster/statemaster/statemaster.component';
import { StatemasterviewComponent } from './views/masters/commonmaster/statemaster/statemasterview/statemasterview.component';
import { ContypemasterComponent } from './views/masters/commonmaster/contypemaster/contypemaster.component';
import { ContypemasterviewComponent } from './views/masters/commonmaster/contypemaster/contypemasterview/contypemasterview.component';

import { LoginComponent } from './login/login.component';
import { AuthenticationGuard } from './authentication.guard';

import { ViewsComponent } from './views/views.component';
import { MastersComponent } from './views/masters/masters.component';

import { CommonmasterComponent } from './views/masters/commonmaster/commonmaster.component';



import { PartymasterComponent } from './views/masters/commonmaster/partymaster/partymaster.component';
import { PartymasterviewComponent } from './views/masters/commonmaster/partymaster/partymasterview/partymasterview.component';




import { StoragelocationtypeComponent } from './views/masters/commonmaster/storagelocationtype/storagelocationtype.component';
import { StoragelocationtypeviewComponent } from './views/masters/commonmaster/storagelocationtype/storagelocationtypeview/storagelocationtypeview.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SystemadminComponent } from './views/systemadmin/systemadmin.component';

import { ShipmentlocationsComponent } from './views/masters/commonmaster/shipmentlocations/shipmentlocations.component';
import { ShipmentlocationsviewComponent } from './views/masters/commonmaster/shipmentlocations/shipmentlocationsview/shipmentlocationsview.component';
import { HazardousclassComponent } from './views/masters/commonmaster/hazardousclass/hazardousclass.component';
import { HazardousclassviewComponent } from './views/masters/commonmaster/hazardousclass/hazardousclassview/hazardousclassview.component';
import { UomconversionsComponent } from './views/masters/commonmaster/uomconversions/uomconversions.component';
import { UomconversionsviewComponent } from './views/masters/commonmaster/uomconversions/uomconversionsview/uomconversionsview.component';

import { InstanceprofileComponent } from './views/instanceprofile/instanceprofile.component';
import { CompanydetailsComponent } from './views/instanceprofile/companydetails/companydetails.component';
import { ClientmanagementComponent } from './views/instanceprofile/clientmanagement/clientmanagement.component';
import { ConfigurationComponent } from './views/instanceprofile/configuration/configuration.component';

import { CurrencyComponent } from './views/masters/commonmaster/currency/currency.component';
import { CurrencyviewComponent } from './views/masters/commonmaster/currency/currencyview/currencyview.component';
import { DocumentmanagerComponent } from './views/systemadmin/documentmanager/documentmanager.component';

import { DifineworkflowComponent } from './views/difineworkflow/difineworkflow.component';
import { BasicdetailsComponent } from './views/difineworkflow/basicdetails/basicdetails.component';
import { DefineuserpoolsComponent } from './views/difineworkflow/defineuserpools/defineuserpools.component';
import { DefineuserpoolsaddComponent } from './views/difineworkflow/defineuserpoolsadd/defineuserpoolsadd.component';
import { DefineorgrolesComponent } from './views/difineworkflow/defineorgroles/defineorgroles.component';
import { DefineworkflowComponent } from './views/difineworkflow/defineworkflow/defineworkflow.component';
import { WorkbenchComponent } from './views/workbench/workbench.component';
import { WorkflowrulesComponent } from './views/difineworkflow/workflowrules/workflowrules.component';
import { WorkflowparametersComponent } from './views/difineworkflow/workflowparameters/workflowparameters.component';
import { WorkflowconditionsComponent } from './views/difineworkflow/workflowconditions/workflowconditions.component';
import { WorkflowtabsComponent } from './views/difineworkflow/workflowtabs/workflowtabs.component';	
import { UserComponent } from './views/systemadmin/user/user.component';
import { UserviewComponent } from './views/systemadmin/user/userview/userview.component';
import { ProfileComponent } from './views/systemadmin/user/profile/profile.component';
import { RolesComponent } from './views/systemadmin/user/roles/roles.component';
import { ActivitiesComponent } from './views/systemadmin/user/activities/activities.component';
import { SecurityComponent } from './views/instanceprofile/security/security.component';
import { NotificationconfigComponent } from './views/systemadmin/notificationconfig/notificationconfig.component';
import { NotificationconfigviewComponent } from './views/systemadmin/notificationconfig/notificationconfigview/notificationconfigview.component';
import { NotifymailComponent } from './views/systemadmin/notificationconfig/notifymail/notifymail.component';
import { NotifysystemComponent } from './views/systemadmin/notificationconfig/notifysystem/notifysystem.component';
import { UserrolesComponent } from './views/systemadmin/userroles/userroles.component';
import { UserrolesviewComponent } from './views/systemadmin/userroles/userrolesview/userrolesview.component';
import { ReportpermissionComponent } from './views/reportpermission/reportpermission.component';
import { ReportpermissionviewComponent } from './views/reportpermission/reportpermissionview/reportpermissionview.component';

const routes: Routes = [

    { path: '', redirectTo: "/login", pathMatch: 'full' },
    { path: 'login', component: LoginComponent },


    {
        path: 'views', component: ViewsComponent, canActivate: [AuthenticationGuard],

        children: [
            { path: 'dashboard', component: DashboardComponent },
            { path: 'systemadmin', component: SystemadminComponent },
            { path: 'masters/commonmaster', component: CommonmasterComponent },
         
            { path: 'masters/commonmaster/country/country', component: CountryComponent },
            { path: 'masters/commonmaster/country/countryview1', component: Countryview1Component },
            { path: 'masters/commonmaster/country/country', component: CountryComponent },
            { path: 'masters/masters', component: MastersComponent },

            { path: 'masters/commonmaster/city/city', component: CityComponent },
            { path: 'masters/commonmaster/city/cityview', component: CityviewComponent },
            { path: 'masters/commonmaster/commodity/commodity', component: CommodityComponent },
            { path: 'masters/commonmaster/commodity/commodityview', component: CommodityviewComponent },
            { path: 'masters/commonmaster/cargopackage/cargopackage', component: CargopackageComponent },
            { path: 'masters/commonmaster/cargopackage/cargopackageview', component: CargopackageviewComponent },
            { path: 'masters/commonmaster/port/port', component: PortComponent },
            { path: 'masters/commonmaster/port/portview', component: PortviewComponent },
            { path: 'masters/commonmaster/terminal/terminal', component: TerminalComponent },
            { path: 'masters/commonmaster/terminal/terminalview', component: TerminalviewComponent },
            { path: 'masters/commonmaster/uommaster/uommaster', component: UommasterComponent },
            { path: 'masters/commonmaster/uommaster/uommasterview', component: UommasterviewComponent },
            { path: 'masters/commonmaster/statemaster/statemaster', component: StatemasterComponent },
            { path: 'masters/commonmaster/statemaster/statemasterview', component: StatemasterviewComponent },
            { path: 'masters/commonmaster/contypemaster/contypemaster', component: ContypemasterComponent },
            { path: 'masters/commonmaster/contypemaster/contypemasterview', component: ContypemasterviewComponent },           
            { path: 'masters/commonmaster/partymaster/partymaster', component: PartymasterComponent },
            { path: 'masters/commonmaster/partymaster/partymasterview', component: PartymasterviewComponent },    
           
            { path: 'masters/commonmaster/storagelocationtype/storagelocationtype', component: StoragelocationtypeComponent },
            { path: 'masters/commonmaster/storagelocationtype/storagelocationtypeview/storagelocationtypeview', component: StoragelocationtypeviewComponent },      


            { path: 'masters/commonmaster/shipmentlocations/shipmentlocations', component: ShipmentlocationsComponent },
            { path: 'masters/commonmaster/shipmentlocations/shipmentlocationsview/shipmentlocationsview', component: ShipmentlocationsviewComponent },
            { path: 'masters/commonmaster/hazardousclass/hazardousclass', component: HazardousclassComponent },
            { path: 'masters/commonmaster/hazardousclass/hazardousclassview/hazardousclassview', component: HazardousclassviewComponent },
            { path: 'masters/commonmaster/uomconversions/uomconversions', component: UomconversionsComponent },
            { path: 'masters/commonmaster/uomconversions/uomconversionsview/uomconversionsview', component: UomconversionsviewComponent },

            { path: 'masters/instanceprofile/instanceprofile', component: InstanceprofileComponent },
            { path: 'masters/instanceprofile/companydetails/companydetails', component: CompanydetailsComponent },
            { path: 'masters/instanceprofile/clientmanagement/clientmanagement', component: ClientmanagementComponent },
            { path: 'masters/instanceprofile/configuration/configuration', component: ConfigurationComponent }, 
            
            { path: 'masters/commonmaster/currency/currency', component: CurrencyComponent },   
            { path: 'masters/commonmaster/currency/currencyview/currencyview', component: CurrencyviewComponent },   
            { path: 'systemadmin/documentmanager/documentmanager', component: DocumentmanagerComponent },


            { path: 'views/workflow', component: DifineworkflowComponent },
            { path: 'difineworkflow/basicdetails', component: BasicdetailsComponent },
            { path: 'difineworkflow/workflowrules', component: WorkflowrulesComponent },
            { path: 'difineworkflow/workflowparameters', component: WorkflowparametersComponent },

            { path: 'difineworkflow/workflowconditions', component: WorkflowconditionsComponent },
            { path: 'difineworkflow/workflowtabs', component: WorkflowtabsComponent },



            { path: 'views/difineworkflow/defineuserpools', component: DefineuserpoolsComponent },
            { path: 'difineworkflow/defineuserpoolsadd', component: DefineuserpoolsaddComponent },
            { path: 'views/difineworkflow/defineorgroles', component: DefineorgrolesComponent },
            { path: 'views/difineworkflow/defineworkflow', component: DefineworkflowComponent },
            { path: 'views/workbench', component: WorkbenchComponent },									

            { path: 'systemadmin/user/user', component: UserComponent },
            { path: 'systemadmin/user/userview/userview', component: UserviewComponent },
            { path: 'systemadmin/user/profile/profile', component: ProfileComponent },
            { path: 'systemadmin/user/roles/roles', component: RolesComponent },
            { path: 'systemadmin/user/activities/activities', component: ActivitiesComponent },

            { path: 'instanceprofile/security/security', component: SecurityComponent },

            { path: 'systemadmin/notificationconfig/notificationconfig', component: NotificationconfigComponent },
            { path: 'systemadmin/notificationconfig/notificationconfigview/notificationconfigview', component: NotificationconfigviewComponent },
            { path: 'systemadmin/notificationconfig/notifymail/notifymail', component: NotifymailComponent },
            { path: 'systemadmin/notificationconfig/notifysystem/notifysystem', component: NotifysystemComponent },

            { path: 'systemadmin/userroles/userroles', component: UserrolesComponent },
            { path: 'systemadmin/userroles/userrolesview/userrolesview', component: UserrolesviewComponent },
            { path: 'reportpermission/reportpermission', component: ReportpermissionComponent },
            { path: 'reportpermission/reportpermissionview/reportpermissionview', component: ReportpermissionviewComponent },


        ]

    },

];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
