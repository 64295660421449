import { Operator } from 'rxjs';
import { element } from 'protractor';
import { Component, Inject, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { MastersService } from "src/app/services/masters.service";
import Swal from "sweetalert2";
import { WorkflowService } from "src/app/services/workflow.service";
import { PaginationService } from "src/app/pagination.service";
import { EncrDecrServiceService } from "src/app/services/encr-decr-service.service";
import { MyCompany, MySACommon } from "src/app/model/systemadmin";
import { Country, DynamicPDF1, MyAgency } from "src/app/model/common";
import { Globals } from "src/app/globals";
import { Title } from "@angular/platform-browser";
import { CustomerContract } from "src/app/model/CustomerContract";
import { SystemadminService } from "src/app/services/systemadmin.service";

declare let $: any;
@Component({
  selector: "app-workflowparameters",
  templateUrl: "./workflowparameters.component.html",
  // styleUrls: ['./workflowparameters.component.css']
})
export class WorkflowparametersComponent implements OnInit {
  myControl = new FormControl("");
  title = "Work flow parameter ";
  currentTab: any;
  deleteId: any;
  private id: number;
  eventnumber: any;
  pageSection: number;
  i: number = -1;
  containerList: any = [];

  addparameterlist: any = [];
  addapprovallist: any = [];
  tabsForm: FormGroup;
  encryptedParam: any;
  isEdit: boolean = false;
  // changeTab(tabName) {
  //   this.currentTab = tabName;
  // }
  addnewlist: any = [];

  statusvalues: Status[] = [
    { value: "1", viewValue: "Yes" },
    { value: "2", viewValue: "No" },
  ];
  aclFilename: DynamicPDF1[];

  datatypeItem: any = { Data: [] };
  mandatoryItem: any = { Data: [] };
  validationItem: any = { Data: [] };
  param1Item: any = { Data: [] };
  operatorItem: any = { Data: [] };
  approvalItem: any = { Data: [] };
  approvaltypeItem: any = { Data: [] };
  validationtypeItem: any = { Data: [] };

  deleteItem: any = { Data: [] };
  parameterItems: any = { Data: [] };
  deleteparameterlist: any;
  name: any;
  createdon: any;
  modifiedby: any;
  modifiedon: any;
  createdby: any;
  addholelist: any;
  paramname: any;
  data: any;
  param1: any;
  eventname:string;
  appName:string;

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private ms: MastersService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public ps: PaginationService,
    public wf: WorkflowService,
    private titleService: Title,
    private ES: EncrDecrServiceService,
    private service: MastersService,
    private sa: SystemadminService
  ) {
    this.paramsForm = this.fb.group({
      id: 0,
      eventnumber: "",
      paramnumber: "",
      paramname: "",
      datatype: "",
      mandatory: 0,
      validationrule: "",
    });
    this.route.queryParams.subscribe((params) => {
      let eventNumber = this.ES.get(
        localStorage.getItem("EncKey"),
        params.encrypted
      );
      this.encryptedParam = params.encrypted;
      console.log("params", params.encrypted, eventNumber);
      let eventparse = JSON.parse(eventNumber);
      console.log("this.eventnumber?.eventnumber", eventparse?.eventnumber);

      this.paramsForm.patchValue({
        eventnumber: eventparse?.eventnumber,
      });
      this.tabsForm = this.fb.group({
        ID: eventNumber,
      });
    });
  }

  // array of all items to be paged
  private allItems: any = { Data: [] };
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  private allItemsRules: any = { Data: [] };
  // pager object
  pagerRules: any = {};
  // paged items
  pagedItemsRules: any[];
  private allItemsParams: any = { Data: [] };
  // pager object
  pagerParams: any = {};
  // paged items
  pagedItemsParams: any[];
  DepartmentList: any = [];
  dataSource: Country[];
  searchForm: FormGroup;
  basicForm: FormGroup;
  paramsForm: FormGroup;
  ActivateAddEditDepComp: boolean = false;
  dep: any;
  DepartmentIdFilter: string = "";
  DepartmentNameFilter: string = "";
  DepartmentListWithoutFilter: any = [];
  eventNumber: any;
  bindData: boolean = true;
  ngOnInit(): void {
    // this.currentTab = 'basics'
    this.titleService.setTitle(this.title);
    $(".my-select").select2();
    $(document).on("select2:open", (e) => {
      const selectId = e.target.id;

      $(
        ".select2-search__field[aria-controls='select2-" +
          selectId +
          "-results']"
      ).each(function (key, value) {
        value.focus();
      });
    });
    // $('#ddlApplication').on('select2:select', (e, args) => {
    //   this.ApplicationItem($("#ddlApplication").val());
    // });
    $("#mandatory").on("select2:select", (e: any) => {
      let mandatory = $("#mandatory").find(":selected").val();
      console.log("mandatory", mandatory);
      this.paramsForm.patchValue({
        mandatory: mandatory,
      });
    });
    $("#datatype").on("select2:select", (e: any) => {
      let datatype = $("#datatype").find(":selected").val();
      console.log("datatype", datatype);
      this.paramsForm.patchValue({
        datatype: datatype,
      });
    });
    $("#validationrule").on("select2:select", (e: any) => {
      let validationrule = $("#validationrule").find(":selected").val();
      console.log("validationrule", validationrule);
      this.paramsForm.patchValue({
        validationrule: validationrule,
      });
    });

    this.getParameterList();
    this.clearSearch();
    if (this.bindData) {
      this.dataTypeBinding();
      this.mandatoryBinding();

      this.validationBinding();
    }
    this.bindData = true;
    // this.getEventByNumber(this.eventNumber)

    // this.addeventList();
    //  this.addRuleList();
    this.route.queryParams.subscribe((params) => {
      let eventNumber = this.ES.get(
        localStorage.getItem("EncKey"),
        params.encrypted
      );

      console.log("params", params.encrypted, eventNumber);
      this.eventnumber = JSON.parse(eventNumber);
      this.getParamByNumber(this.eventnumber?.eventnumber);
      this.getEventByNumber(this.eventnumber?.eventnumber);
      this.getcondition();
    });
  }

  // get(dataItems){
  //   this.name=dataItems.application;
  // }

  moveUpCompliance() {
    console.log("iiiiiii", this.i);
    if(this.i<0 || this.isEdit){
      return true
    }
    if (this.i > 0 ) {
      const element = this.addparameterlist[this.i];

      this.addparameterlist.splice(this.i, 1);
      this.addparameterlist.splice(this.i - 1, 0, element);
      this.i = +this.i - 1;
      this.pageSection = Math.floor((+this.i - 1) / 10) + 1;
      this.setPage(this.pageSection);
      // this.isSaveComplianceBtn = false;
    }
  }

  // moveDownCompliance(i: any) {
  //   console.log("iiiiiii",i);
  //   if (i != this.allItems.length - 1) {
  //     const element = this.allItems[i];
  //     // this.pagedItems.splice(id, 1);
  //     // this.pagedItems.splice(id + 1, 0, element);
  //     this.allItems.splice(i, 1)
  //     this.allItems.splice(i + 1, 0, element);
  //     this.complianceRowId = +i + 1;
  //     this.pageSection = Math.floor((+i + 1) / 10) + 1;
  //     this.setPage(this.pageSection);
  //     // this.isSaveComplianceBtn = false
  //   }
  // }
  moveDownCompliance() {
    console.log("iiiiiii", this.i);
    if(this.i<0 || this.isEdit){
      return true
    }

    if (this.i != this.addparameterlist.length - 1 && this.i > -1) {
      const element = this.addparameterlist[this.i];

      this.addparameterlist.splice(this.i, 1);
      this.addparameterlist.splice(this.i + 1, 0, element);
      this.i = +this.i + 1;
      this.pageSection = Math.floor(+this.i / 10) + 1;
      this.setPage(this.pageSection);
      // this.isEditBtn = false
    }
  }
  getEventByNumber(eventNumber: any) {
    this.wf.getDefineListByNumber(eventNumber).subscribe((data: any) => {
      // set items to json response
      console.log("getDefineListByNumber", data?.Data[0]);

      if (Array.isArray(data?.Data) && data?.Data.length > 0) {
        // this.basicForm.patchValue({
        //   ...data?.Data[0],
        // });
        this.eventname =  data?.Data[0].eventname
        this.appName =  data?.Data[0].application
        // $("#ddlApplication").val(data?.Data[0].application).change();
        //popover dynamicdata
        // this.dynamicContent = ` createdby: ${data?.Data[0].createdby}
        // createdon: ${data?.Data[0].createdon}
        // modifiedby: ${data?.Data[0].modifiedby}
        // modifiedon: ${data?.Data[0].modifiedon}`;
        this.createdon = data?.Data[0].createdon;
        this.modifiedon = data?.Data[0].modifiedon;
        this.modifiedby = data?.Data[0].modifiedby;
        this.createdby = data?.Data[0].createdby;
      }
    });
  }
  getParamByNumber(eventnumber: any) {
    this.wf.getParamByNumber(eventnumber).subscribe((data: any) => {
      console.log("getParamByNumber", data);
      //this.addparameterlist.push(this.paramsForm.value)
      // this.addnewlist.push(this.paramsForm.value)
      this.addparameterlist = data?.Data;
      //UPDOWN CODE
      this.addparameterlist.sort(function (a, b) {
        return a.paramnumber < b.paramnumber ? -1 : 1;
      });

      // if(Array.isArray(data?.Data) && data?.Data.length>0){
      //   this.paramsForm.patchValue({
      //     ...data?.Data[0]
      //   })
      // }
    });
  }

  dataTypeBinding() {
    console.log("datatype", this.paramsForm.value);
    this.wf.getDataTypeList({ type: "datatype" }).subscribe((data) => {
      this.datatypeItem = data;
    });
  }
  mandatoryBinding() {
    console.log("mandatory", this.paramsForm.value);
    this.wf.getMandatoryList({ type: "mandatory" }).subscribe((data) => {
      this.mandatoryItem = data;
    });
  }
  validationBinding() {
    console.log("validationrule", this.paramsForm.value);
    this.wf.getValidationList({ type: "validationrule" }).subscribe((data) => {
      this.validationItem = data;
    });
  }

  addParameterLists() {
    if (this.isEdit) return;
    console.log("addParameterLists", this.paramsForm.value);

    if (this.addparameterlist.length >= 5) {
      Swal.fire("Up to 5 Parameters only allowed");
      return;
    }
    var validation = "";
    if (this.paramsForm.value.paramname?.replace(/\s+/g,'') == "" || !this.paramsForm.value.paramname ) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Param Name</span></br>";
    }
    if (this.paramsForm.value.datatype == "" || !this.paramsForm.value.datatype) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Data Type</span></br>";
    }
    if (this.paramsForm.value.mandatory == "" || !this.paramsForm.value.mandatory) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Mandatory</span></br>";
    }
    if (this.paramsForm.value.validationrule == "" || !this.paramsForm.value.validationrule) {
      validation +=
        "<span style='color:red;'>*</span> <span>Please Enter Validation Rule </span></br>";
    }
console.log(validation,'valid')
    if (validation != "") {
      Swal.fire(validation);
      return false;
    }

   //DUPLICATE CHECKING CODE

      let finddata = this.addparameterlist.find((item) =>   item?.paramname?.toLowerCase()?.replace(/\s+/g,'') == this.paramsForm.value?.paramname?.toLowerCase()?.replace(/\s+/g,'') )
      if(finddata){
      Swal.fire("Param Name Already Exits")
     return
      }

      
    


    this.addparameterlist.push(this.paramsForm.value);
    // this.addnewlist.push(this.paramsForm.value)
    this.paramsForm.reset();
    //MULTIPLE ADD + ADD VALIDATION
    console.log("this.eventnumber?.eventnumber", this.eventnumber?.eventnumber);

    this.paramsForm.patchValue({
      id: 0,
      eventnumber: this.eventnumber?.eventnumber,
      paramnumber: "",
      paramname: "",
      datatype: "",
      mandatory: "",
      validationrule: "",
    });
    $("#mandatory").val("").change();
    $("#datatype").val("").change();
    $("#validationrule").val("").change();
    // if (this.i > -1 && this.paramsForm.invalid && this.isEdit) {
    //   this.paramsForm.markAllAsTouched();
    //   return
    // }
    this.id = null;
    this.i = -1;

   

    //   this.wf.addParameter(this.paramsForm.value).subscribe(Data => {
    //     Swal.fire(Data[0].AlertMessage);
    // },
    //     (error: HttpErrorResponse) => {
    //         Swal.fire(error.message)
    //     });
  }

  onChange(data, i: number) {
    this.id = data;
    this.i = i;
  }

  deleteParams() {
    console.log("deleteContainer", this.id , this.i,this.addparameterlist);
    if(this.isEdit)return
    if(!this.isEdit && this.id && this.i>-1){

    if (this.i < 0 ) {
      return true;
    }
    if (this.conditionBlock(this.addparameterlist[this.i])) {
      return Swal.fire("This Param is used in condition param");
    }

    console.log(
      "this.id",
      this.deleteItem,
      this.conditionBlock(this.addparameterlist[this.i])
    );
    this.wf.paramsDelete({ id: this.id }).subscribe((data) => {
      this.deleteItem = data;
      this.bindData = false;
      this.getParameterList();
      this.id = null;
      this.i = -1;

      $("#mandatory").val("").change();
      $("#datatype").val("").change();
      $("#validationrule").val("").change();
      this.paramsForm.reset();
    });
    // return
    }    
    else if(!this.id && this.i>-1){
      this.addparameterlist.splice(this.i,1) 
        this.setPage(1)
        console.log("deleteContainer", this.id , this.i,this.addparameterlist);
        return

    }
    
  }

  conditionBlock(paramvalue) {
    let conditionblock = this.addholelist?.find( (val) =>  val.param1 == paramvalue?.paramname?.toLowerCase()?.replace(/\s+/g,'') ||  val.param2 == paramvalue?.paramname.toLowerCase()?.replace(/\s+/g,''));
    // this.addholelist = conditionblock;
   
    return conditionblock ? true : false;
    console.log("conditionblock", this.addholelist, paramvalue);
  }
  checkEditCondition(listobject,formvalue){
    return listobject.paramname != formvalue.paramname || listobject.datatype != formvalue.datatype
  }

  getcondition() {
    this.wf
      .getconditionByNumber(this.eventnumber?.eventnumber)
      .subscribe((data: any) => {
        if (Array.isArray(data?.Data) && data?.Data.length > 0) {
          this.addapprovallist = data?.Data[this.i]?.conditionapprover || [];
          let condition = data.Data.filter(
            (val) => val.eventnumber == this.eventnumber?.eventnumber
          );
          this.addholelist = condition;
          console.log("addholelist", this.addholelist);
          // <ASENDING,>DESENDING CODE
          // this.addapprovallist.sort(function (a, b) {
          //   return a.step < b.step ? -1 : 1;
          // })
          // this.setPage(1);
        }
      });
  }

  getParameterList() {
    this.wf.getParameterList().subscribe((data: any) => {
      // set items to json response
      // this.parameterItems = data;
      if (Array.isArray(data.Data)) {
        let parameter = data.Data.filter(
          (val) => val.eventnumber == this.eventnumber?.eventnumber
        );
        this.addparameterlist = parameter;
        // this.addnewlist = parameter;
      }
      console.log("getParameterList", data);
      // initialize to page 1
      this.setPage(1);
    },error => {
      this.addparameterlist = []
      this.setPage(1);
    });
    // this.name=dataItems.eventname;
    // console.log("hdsfghdaf",this.name)
    // this.progressForm.patchValue({
    //   eventname:this.name
    // })
  }

  editParameterList() {
    if (this.i < 0) {
      return true;
    }
    this.isEdit = true;
    let data = this.addparameterlist[this.i];
    this.paramsForm.patchValue(data);
    //DROPDOWN edit BINDING
    $("#mandatory")
      .val(data?.mandatory ? data?.mandatory  : "No" || "0")
      .change();
    $("#datatype").val(this.addparameterlist[this.i].datatype).change();
    $("#validationrule").val(this.addparameterlist[this.i].validationrule).change();
  }

  clearSearch() {
    this.paramsForm.value.application = $("#ddlApplication").val();
    // this.createForm()
    // this.RuleList();
    $("#ddlApplication").val(0).trigger("change");
  }

  onSubmit(type: any) {

    
    //DUBLICATE CODE
if(this.addparameterlist.length == 0){
return 
}
    
    let data: any = this.paramsForm.value;

    // if (type == 'edit')
    if (this.isEdit) {
    let findIndex :number = -1;
    let findItem:any;

// console.log("checkEdit",checkEdit)
//DUPLICATE CHECKING CODE
if(this.addparameterlist[this.i].paramname?.toLowerCase()?.replace(/\s+/g,'') != this.paramsForm.value?.paramname?.toLowerCase()?.replace(/\s+/g,'') ){
   findItem = this.addparameterlist.find((item,index:number) => {
   if( item?.paramname?.toLowerCase()?.replace(/\s+/g,'') == this.paramsForm.value?.paramname?.toLowerCase()?.replace(/\s+/g,'')){
    findIndex = index;
    return item;
   }
  })
if(findItem){
  Swal.fire("Param Name Already Exits")
  return
}
}
      if (this.checkEditCondition(this.addparameterlist[this.i],this.paramsForm.value)) {
        if(this.conditionBlock(this.addparameterlist[this.i])){
          return Swal.fire("This Param is used in condition param");
        }

      }
      if(this.paramsForm.invalid){
        return
      }
      this.wf.addParameter([this.paramsForm.value]).subscribe((data) => {
        // set items to json response
        this.parameterItems = data;
        console.log("editParameter", data);
        // initialize to page 1
        // this.setPage(1);
        //RESET DROPDOWN

        $("#mandatory").val("").change();
        $("#datatype").val("").change();
        $("#validationrule").val("").change();
        this.paramsForm.reset();
        Swal.fire("Update successfully");
        // this.ngOnInit();
        this.getParameterList();
        
        this.id = null;
        this.i = -1;
      });
      this.isEdit = false;
      return;
    }
    // else if (type == 'add')
    data = [];
    //UPDOWN CODE
    this.addparameterlist.map((val, i) => {
      data.push({ ...val, paramnumber: i + 1 });
    });
    // data = this.addparameterlist;
    // data = this.addnewlist;
    this.wf.addParameter(data).subscribe((data) => {
      console.log("save", data);
      // this.addnewlist=[]
      this.addparameterlist = [];
      Swal.fire("Saved successfully");
      this.getParamByNumber(this.eventnumber?.eventnumber);
      this.paramsForm.reset();
      console.log(
        "this.eventnumber?.eventnumber",
        this.eventnumber?.eventnumber
      );

      this.paramsForm.patchValue({
        id: 0,
        eventnumber: this.eventnumber?.eventnumber,
        paramnumber: "",
      });

      this.id = null;
      this.i = -1;
    });
    // if (type == 'event')
    //   this.addeventList();
    // else if (type == 'rule')
    //   this.addRuleList();
    // else if (type == 'param')
    //   this.editParameterList();
    // this.addeventList();
  }

  public selectedName: any;
  public highlightRow(dataItem) {
    this.selectedName = dataItem.CountryCode;
  }
  setPage(page: number) {
    //if (page < 1 || page > this.pager.totalPages) {
    //    return;
    //}

    // get pager object from service
    this.pager = this.ps.getPager(this.allItems.length, page);

    // get current page of items
    //this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  pdflink() {
    this.ms.getPDF(this.searchForm.value).subscribe((data) => {
      console.log(data[0].aclFilename);
      window.open("assets/pdfFiles/" + data[0].aclFilename + ".pdf");
    });

    //alert('hi');
    //this.http.get(this.globals.APIURL + '/home/BLPrintPDF/').subscribe(res => {

    //   });

    /*window.open(RouterLink("BLPrintPDF", "BLPrint")');*/

    /*window.open("assets/pdfFiles/test.pdf");*/
  }

  //openpdf() {
  //    $('#pdfview').modal('show');
  //}

  isDesc: boolean = false;
  column: string = "";
  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.pagedItems.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  changeTab(tab) {
    var values = "ID: " + this.tabsForm.value.ID;
    //var values = "ID: 8";
    var encrypted = this.encryptedParam; //this.ES.set(localStorage.getItem("EncKey"), values);
    if (tab == 1) {
      this.router.navigate(["/views/difineworkflow/basicdetails"], {
        queryParams: { encrypted },
      });
    } else if (tab == 2) {
      this.router.navigate(["/views/difineworkflow/workflowrules"], {
        queryParams: { encrypted },
      });
    } else if (tab == 3) {
      this.router.navigate(["/views/difineworkflow/workflowparameters"], {
        queryParams: { encrypted },
      });
    } else if (tab == 4) {
      this.router.navigate(["/views/difineworkflow/workflowconditions"], {
        queryParams: { encrypted },
      });
    }
  }
}
interface Status {
  value: string;
  viewValue: string;
}
