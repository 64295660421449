import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
   /* @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();*/
    constructor(private router: Router) { }

  ngOnInit(): void {
  }
    btntabclick(tab) {


        
        if (tab == 2) {
            this.router.navigate(['/views/systemadmin'],);
        }
        else if (tab == 3) {
            this.router.navigate(['/views/dashboard'],);
        }
        else if (tab == 4) {
            this.router.navigate(['/views/ladashboards/lareports/lareports'],);
        }
        else if (tab == 5) {
            this.router.navigate(['/views/masters/commonmaster'],);
        }
    }
}
