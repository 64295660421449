import { Injectable } from '@angular/core';

@Injectable()

export class Globals {
    /* QA */

     //APIURL: string = 'https://odaksaqa.odaksolutions.in/api';
     //APIURLFN: string = 'https://odakfnqa.odaksolutions.in/api';
     //APIURLFF: string = 'https://odaksaqa.odaksolutions.in/ffddev/api';
     //APIURL_LA: string = 'https://odaklaqa.odaksolutions.in/api';
     //APIURLUSER: string = 'https://odaksaqa.odaksolutions.in/ffddev/api';




     /* UAT */
    //APIURL: string = 'https://odaksauat.odaksolutions.in/api';
    //APIURLFN: string = 'https://odakfnuat.odaksolutions.in/api';
    //APIURLFF: string = 'https://odaksauat.odaksolutions.in/ffddev/api';
    //APIURL_LA: string = 'https://odaklaqa.odaksolutions.in/api';
    //APIURLUSER: string = 'https://odaksauat.odaksolutions.in/ffddev/api';


    /** Freighteiz **/

    APIURL: string = 'https://sa-navioindia.freighteiz.com/api';
    APIURLFN: string = 'https://fn-navioindia.freighteiz.com/api';
    APIURLFF: string = 'https://sa-navioindia.freighteiz.com/ffddev/api';
    APIURL_LA: string = 'https://la-navioindia.freighteiz.com/api';
    APIURLUSER: string = 'https://sa-navioindia.freighteiz.com/ffddev/api';

 

    //APIURL: string = 'https://localhost:44308/api';
    
}

